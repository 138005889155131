import React, { useState } from 'react';
import styles from './Buttons.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import image from '../../assets/images/prefill.png';
import Langs from '../../app/lang/langs';
import { ReactSVG } from 'react-svg'


export default function SaveButton(props) {

    const [focused, setFocused] = useState(false)
    const tooltip = props.tooltip
    const renderTooltip = (props) => tooltip ? (
        <div className={styles.tooltip} id={props.name} {...props}>
            <Langs str={tooltip} />
        </div>
    ) : (<div />);

    return <OverlayTrigger
        placement="left"
        delay={{ show: 500, hide: 100 }}
        overlay={renderTooltip}>
        <button className={styles.floatingButton + (props.active ? "" : " opacity-50 disabled not-allowed")}
            style={props.style}
            onMouseEnter={props.active ? () => setFocused(true) : () => { }}
            onMouseLeave={props.active ? () => setFocused(false) : () => { }}
            onClick={props.active ? props.onClick : () => { }}>

            <ReactSVG src={(props.image) ? props.image : image} alt={"save-button"} style={{ borderRadius: 25 }} />

        </button>
    </OverlayTrigger>
}