import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import styles from './Login.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from "react-redux";
import {
    loginUser,
    setLoginError,
    checkMSToken,
    setFormView,
    sendActivationLink,
    submitNewPassword
} from './loginSlice'
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../app/utils/helpers"
import Langs from "../../app/lang/langs";
import logo from "../../assets/images/Logo-Groupe-Vital-Blanc-Baseline.png";
import { SignInButton } from '../../components/buttons/SignInButton';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../app/utils/authConfig";
import { ThemeButton } from '../../components/buttons/ThemeButton';

export function LoginPage(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isAuthenticated = useIsAuthenticated()
    const isLoading = useSelector((state) => state.common.isLoading)
    const loginError = useSelector((state) => state.login.loginError)
    const formView = useSelector((state) => state.login.formView)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [formError, setFormError] = useState('')

    const { instance, accounts, inProgress } = useMsal()

    let { activationToken } = useParams()

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                dispatch(checkMSToken(response))
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    dispatch(checkMSToken(response))
                })
            })
        }
    }, [isAuthenticated])

    const handleEmailChange = event => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value)
    }

    const handleConfirmPasswordChange = event => {
        setConfirmPassword(event.target.value)
    }

    function showForgotPassView() {
        dispatch(setFormView("FORGOT_PASS"))
    }

    function showLoginView() {
        navigate("/")
        dispatch(setFormView("LOGIN"))
    }

    function showActivateAccountView() {
        dispatch(setFormView("ACTIVATE_ACCOUNT"))
    }

    function onSendEmail() {
        setFormError("")
        dispatch(setLoginError(null))
        if (!validateEmail(email)) {
            setFormError("INCORRECT_EMAIL")
            return
        }
        dispatch(sendActivationLink({ email: email }))
    }

    function onSubmitPasswords() {
        setFormError("")
        dispatch(setLoginError(null))
        if (password === '') {
            setFormError("PASSWORD_EMPTY")
            return
        }
        if (password.length < 6) {
            setFormError("PASSWORD_TOO_SHORT")
            return
        }
        if (confirmPassword !== password) {
            setFormError("NOT_EQUAL_PASSWORDS")
            return
        }
        dispatch(submitNewPassword({ password: password, token: activationToken })).then(() => {
            navigate('/');
        })
    }

    const handleKeypress = e => {
        if (e.charCode === 13) {
            onLoginUserSubmit();
        }
    }

    function onLoginUserSubmit() {
        setFormError("")
        dispatch(setLoginError(null))
        if (!validateEmail(email)) {
            setFormError("INCORRECT_EMAIL")
            return
        }
        if (password === '') {
            setFormError("PASSWORD_EMPTY")
            return
        }
        dispatch(loginUser({ email: email, mdp: password }))
    }

    function getLoginformView() {
        if (activationToken) {
            return <Row>
                <Row className="m-auto text-center pt-4 pb-1">
                    <span className={styles.mainLabel}>
                        <Langs str='ENTER_PASSWORD' />
                    </span>
                </Row>
                <Row className="m-auto text-center pt-3 pb-3">
                    <span className={styles.submainLabel}>
                        <Langs str='ENTER_PASSWORD_MSG' />
                    </span>
                </Row>
                <Row className="m-auto pt-2 pb-3">
                    <Col md={10} className="m-auto pt-2">
                        <label className={styles.label}>
                            <Langs str='PASSWORD' />
                        </label>
                        <input id="password"
                            type="password"
                            className={styles.inputText}
                            name="password"
                            placeholder=""
                            onChange={handlePasswordChange}
                            value={password} />
                        {formError.includes("PASSWORD_") ?

                            <span className={styles.errorInput}>
                                <Langs str={formError} />
                            </span>
                            : ""}
                    </Col>
                    <Col md={10} className="m-auto pt-2">
                        <label className={styles.label}>
                            <Langs str='REENTER_PASSWORD' />
                        </label>
                        <input id="reenter_password"
                            type="password"
                            className={styles.inputText}
                            name="reenter_password"
                            placeholder=""
                            onChange={handleConfirmPasswordChange}
                            value={confirmPassword} />
                        {formError.includes("NOT_EQUAL_PASSWORDS") || loginError ?

                            <span className={styles.errorInput}>
                                {formError === "NOT_EQUAL_PASSWORDS" ?
                                    <Langs str='NOT_EQUAL_PASSWORDS' />
                                    : loginError ? loginError : ""}
                            </span>
                            : ""}
                    </Col>
                </Row>
                <Row className="m-auto pt-4 pb-3">
                    <Col md={10} className="m-auto text-center">
                        <ThemeButton
                            active={true}
                            title='SUBMIT'
                            onClick={() => onSubmitPasswords()}
                            className={styles.loginButton} />
                    </Col>
                </Row>
                <Row className="m-auto">
                    <Col md={10} className="m-auto pt-2 pb-3 text-center">
                        <button onClick={() => {
                            showLoginView()
                        }}
                            className={styles.textBtn}>
                            <Langs str="LOGIN" />
                        </button>
                    </Col>
                </Row>
            </Row>
        } else if (formView === "LOGIN") {
            return <Row>
                <Row className="m-auto p-1">
                    <Col md={10} className="m-auto pt-2">
                        <label className={styles.label}>
                            <Langs str='EMAIL' />
                        </label>
                        <input id="email"
                            type="text"
                            className={styles.inputText}
                            name="email"
                            placeholder=""
                            onChange={handleEmailChange}
                            onKeyPress={handleKeypress}
                            value={email} />
                        {formError === "INCORRECT_EMAIL" ?

                            <span className={styles.errorInput}>
                                <Langs str='INCORRECT_EMAIL' />
                            </span>
                            : ""}
                    </Col>
                </Row>
                <Row className="m-auto p-1 pt-2">
                    <Col md={10} className="m-auto pt-2">
                        <label className={styles.label}>
                            <Langs str='PASSWORD' />
                        </label>
                        <input id="password"
                            type="password"
                            className={styles.inputText}
                            name="password"
                            placeholder=""
                            onChange={handlePasswordChange}
                            onKeyPress={handleKeypress}
                            value={password} />
                        {formError.includes("PASSWORD") || loginError ?

                            <span className={styles.errorInput}>
                                {formError.includes("PASSWORD_EMPTY") ?
                                    <Langs str='EMPTY_PASSWORD' />
                                    : loginError ? loginError : ""}
                            </span>
                            : ""}
                    </Col>
                </Row>
                <Row className="m-auto">
                    <Col md={10} className="m-auto p-3 text-center">
                        <button onClick={() => {
                            showForgotPassView()
                        }}
                            className={styles.textBtn}>
                            <Langs str='FORGOT_PASSWORD' />
                        </button>
                    </Col>
                </Row>
                <Row className="m-auto pb-1">
                    <Col md={10} className="m-auto p-2 text-center">
                        <ThemeButton
                            active={true}
                            title='LOGIN'
                            onClick={() => onLoginUserSubmit()}
                            className={styles.loginButton} />
                    </Col>
                </Row>
                <Row className="m-auto">
                    <Col md={10} className="m-auto p-2 text-center">
                        <SignInButton />
                    </Col>
                </Row>
                <Row className="m-auto">
                    <Col md={10} className="m-auto p-2 pb-3 text-center">
                        <button onClick={() => {
                            showActivateAccountView()
                        }}
                            className={styles.textBtn}>
                            <Langs str='ACTIVATE_ACCOUNT' />
                        </button>
                    </Col>
                </Row>
            </Row>
        } else if (formView === "FORGOT_PASS") {
            return <Row>
                <Row className="m-auto text-center pt-4">
                    <span className={styles.mainLabel}>
                        <Langs str='FORGOT_PASSWORD?' />
                    </span>
                </Row>
                <Row className="m-auto text-center p-3">
                    <span className={styles.submainLabel}>
                        <Langs str='RESET_PASSWORD_MSG' />
                    </span>
                </Row>
                <Row className="m-auto">
                    <Col md={10} className="m-auto p-4 text-center">
                        <button onClick={() => {
                            showLoginView()
                        }}
                            className={styles.textBtn}>
                            <Langs str="LOGIN" />
                        </button>
                    </Col>
                </Row>
            </Row>
        } else if (formView === "ACTIVATE_ACCOUNT") {
            return <Row>
                <Row className="m-auto text-center pt-4">
                    <span className={styles.mainLabel}>
                        <Langs str='ACTIVATE_ACCOUNT' />
                    </span>
                </Row>
                <Row className="m-auto text-center p-3 pb-4">
                    <span className={styles.submainLabel}>
                        <Langs str='ACTIVATE_ACCOUNT_MSG' />
                    </span>
                </Row>
                <Row className="m-auto p-1 pb-3">
                    <Col md={10} className="m-auto pt-2">
                        <input id="email"
                            type="text"
                            className={styles.inputText}
                            name="email"
                            placeholder="email"
                            onChange={handleEmailChange}
                            value={email} />
                        {formError === "INCORRECT_EMAIL" ?

                            <span className={styles.errorInput}>
                                <Langs str='INCORRECT_EMAIL' />
                            </span>
                            : ""}
                    </Col>
                </Row>
                <Row className="m-auto pb-3">
                    <Col md={10} className="m-auto p-2 text-center">
                        <ThemeButton
                            active={true}
                            title='SEND'
                            onClick={() => onSendEmail()}
                            className={styles.loginButton} />
                    </Col>
                </Row>
                <Row className="m-auto">
                    <Col md={10} className="m-auto p-2 pb-3 text-center">
                        <button onClick={() => {
                            showLoginView()
                        }}
                            className={styles.textBtn}>
                            <Langs str="LOGIN" />
                        </button>
                    </Col>
                </Row>
            </Row>
        }
    }

    return <div>
        <Container fluid className={styles.fullHeight + " " + styles.background}>
            <Row className="d-flex justify-content-center align-items-top min-vh-100 text-center">
                <Col lg={4} className="p-4 text-left">
                    <img className={styles.logo} src={logo} alt={"logo"} />
                </Col>
                <Col lg={4} className={styles.loginContainer + " align-self-center"}>
                    <Row>
                        <Col md={12} className="m-auto p-4 text-center">
                            <span className={styles.welcomeText}>
                                <Langs str='WELCOME_TEXT' />
                            </span>
                        </Col>
                    </Row>
                    {getLoginformView()}
                </Col>
                <Col lg={4}>

                </Col>
            </Row>
        </Container>
    </div>
}