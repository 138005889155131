import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NetworkManager from '../../app/managers/networkManager';
import { store } from "../../app/store/store";
import { config } from "../../app/utils/config";
import { setIsLoading, showPopup } from "../common/commonSlice";

const initialState = {
    userEmail: localStorage.getItem("USER_EMAIL"),
    userId: localStorage.getItem("USER_ID"),
    userName: localStorage.getItem("USER_NAME"),
    userToken: localStorage.getItem("AUTHORIZATION"),
    loginType: localStorage.getItem("LOGIN_TYPE"),
    auto: localStorage.getItem("AUTO") == 'false' ? false : true,
    loginError: null,
    userType: localStorage.getItem("USER_TYPE"),
    userRoleId: localStorage.getItem("USER_ROLE_ID"),
    formView: "LOGIN",
    permissions: localStorage.getItem("USER_PERMISSIONS") || []
}

export const loginUser = createAsyncThunk(
    'user/login',
    async (body) => {
        try {
            store.dispatch(setIsLoading(true))
            let response = await NetworkManager.getInstance().postRequest(config.apiPaths.user.login, body)
            store.dispatch(setIsLoading(false))

            if (response.responseError) {
                if (response.responseError.data.error) {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: response.responseError.data.error }))
                } else {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
                }
            }
            return response
        } catch (error) {
            store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
            return error
        }
    }
)

export const getUserSession = createAsyncThunk(
    'user/session',
    async () => {
        try {
            store.dispatch(setIsLoading(true))
            let response = await NetworkManager.getInstance().getRequest(config.apiPaths.user.session)
            store.dispatch(setIsLoading(false))

            if (response.responseError) {
                if (response.responseError.data.error) {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: response.responseError.data.error }))
                } else {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
                }
            }
            return response
        } catch (error) {
            store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
            return error
        }
    }
)

export const sendActivationLink = createAsyncThunk(
    'user/sendActivationLink',
    async (body) => {
        try {
            store.dispatch(setIsLoading(true))
            let response = await NetworkManager.getInstance().postRequest(config.apiPaths.user.sendActivationLink, body)
            store.dispatch(setIsLoading(false))

            if (response.responseError) {
                if (response.responseError.data.error) {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: response.responseError.data.error }))
                } else {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
                }
            } else {
                store.dispatch(setFormView('LOGIN'))
                store.dispatch(showPopup({ show: true, title: "LINK_SENT", message: "LINK_SENT_MSG" }))
            }
            return response
        } catch (error) {
            store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
            return error
        }
    }
)

export const submitNewPassword = createAsyncThunk(
    'user/setPassword',
    async (body) => {
        try {
            store.dispatch(setIsLoading(true))
            let response = await NetworkManager.getInstance().postRequest(config.apiPaths.user.setPassword, body)
            store.dispatch(setIsLoading(false))

            if (response.responseError) {
                if (response.responseError.data.error) {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: response.responseError.data.error }))
                } else {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
                }
            } else {
                store.dispatch(setFormView('LOGIN'))
                store.dispatch(showPopup({ show: true, title: "PASSWORD_SET", message: "PASSWORD_SET_MSG" }))
            }
            return response
        } catch (error) {
            store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
            return error
        }
    }
)

export const checkMSToken = createAsyncThunk(
    'user/ms-auth',
    async (msResponse) => {

        try {
            store.dispatch(setIsLoading(true))
            let response = await NetworkManager.getInstance().getRequest(
                config.apiPaths.user.msAuth,
                { username: msResponse.account["username"] },
                `Bearer ${msResponse.accessToken}`
            )
            store.dispatch(setIsLoading(false))

            if (response.responseError) {
                if (response.responseError.data.error) {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: response.responseError.data.error }))
                } else {
                    store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
                }
            }
            return response
        } catch (error) {
            store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
            return error
        }
    }
)

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFormView: (state, action) => {
            state.formView = action.payload
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload
        },
        setUserToken: (state, action) => {
            localStorage.setItem("AUTHORIZATION", action.payload)
            state.userToken = action.payload
        },
        setLoginError: (state, action) => {
            state.loginError = action.payload
        },
        logoutUser: (state, action) => {
            if (action.payload.doReload && state.userEmail) {
                window.location.replace(window.location.origin)
            }
            let userPreferences = localStorage.getItem("USER_PREFERENCES")
            localStorage.clear()
            sessionStorage.clear()
            state = {
                userEmail: null,
                userId: null,
                userName: null,
                userToken: null,
                loginType: null,
                loginError: null,
                userType: null,
                permissions: []
            }
            // Keep only user preferences
            localStorage.setItem("USER_PREFERENCES", userPreferences)
        },
        setLoginError: (state, action) => {
            state.loginError = action.payload
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                if (action.payload.responseError) {
                    state = initialState
                    localStorage.clear()
                } else if (action.payload.responseData &&
                    action.payload.responseData.email &&
                    action.payload.responseData.token) {
                    state.userId = action.payload.responseData.id_collab
                    state.userName = action.payload.responseData.name
                    state.userEmail = action.payload.responseData.email
                    state.userToken = action.payload.responseData.token
                    state.auto = action.payload.responseData.auto == 0 ? false : true
                    state.loginType = 'DEFAULT'
                    state.userRoleId = action.payload.responseData.roleId
                    state.permissions = action.payload.responseData.permissions || []
                    saveLoginDataFromState(state)
                }
            })
            .addCase(checkMSToken.fulfilled, (state, action) => {

                if (action.payload.responseError) {
                    state = initialState
                    localStorage.clear()
                } else if (action.payload.responseData &&
                    action.payload.responseData.email &&
                    action.payload.responseData.token) {
                    state.userName = action.payload.responseData.name
                    state.userId = action.payload.responseData.id_collab
                    state.userEmail = action.payload.responseData.email
                    state.userToken = action.payload.responseData.token
                    state.userRoleId = action.payload.responseData.roleId
                    state.auto = action.payload.responseData.auto == 0 ? false : true
                    state.loginType = 'MS'
                    state.permissions = action.payload.responseData.permissions || []
                    saveLoginDataFromState(state)
                }
            })
            .addCase(getUserSession.fulfilled, (state, action) => {
                if (action.payload.responseError) {
                    state = initialState
                    localStorage.clear()
                } else if (action.payload.responseData &&
                    action.payload.responseData.email) {
                    state.userName = action.payload.responseData.name
                    state.userEmail = action.payload.responseData.email
                    state.userRoleId = action.payload.responseData.roleId
                    state.auto = action.payload.responseData.auto == 0 ? false : true
                    state.loginType = action.payload.responseData.type_login == 'O365' ? 'MS' : 'DEFAULT'
                    state.permissions = action.payload.responseData.permissions || []
                    saveLoginDataFromState(state)
                }
            })
    },
})

function saveLoginDataFromState(state) {
    localStorage.setItem("USER_EMAIL", state.userEmail)
    localStorage.setItem("AUTHORIZATION", state.userToken)
    localStorage.setItem("LOGIN_TYPE", state.loginType)
    localStorage.setItem("USER_NAME", state.userName)
    localStorage.setItem("USER_ROLE_ID", state.userRoleId)
    localStorage.setItem("USER_ID", state.userId)
    localStorage.setItem("AUTO", state.auto)
    localStorage.setItem("USER_PERMISSIONS", state.permissions)
}

export const {
    setFormView,
    logoutUser,
    setLoginError,
    setUserEmail,
    setUserToken,
} = loginSlice.actions;
export default loginSlice.reducer;
