import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import addIcon from "../../assets/images/add.png";
import addIconFocus from "../../assets/images/addFocus.png";
import saveIcon from '../../assets/images/save-outline.svg'
import exportIcon from "../../assets/images/export.png";
import exportIconFocus from "../../assets/images/exportFocus.png";
import emptyPageImg from "../../assets/images/empty_page.png";
import ImageButton from "../../components/buttons/ImageButton";
import { GroupeCard } from "../../components/groupes/cards/GroupeCard";
import { setIsLoading, showGroupeModal } from "../common/commonSlice";
import styles from "./Groupes.module.css";
import { hideFormPopup } from "../planning/planningSlice";
import { ShiftFormModal } from "../../components/scheduler/forms/ShiftFormModal";
import { TeamSelector } from "../../components/teams/TeamSelector";
import { ViewSelectButton } from "../../components/buttons/ViewSelectButton";
import { deleteGroupes, getGroupes, setSelectedView } from "./groupesSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { checkPermission, getFormattedDate, showErrorToast } from "../../app/utils/helpers";
import {
  getCollabs,
  getMembers,
  setCurrentCollabs,
  setUpdatedCurrentCollabs,
  updateCollabs,
  setCollabModalData,
  setUpdateTeamPermissionsRes
} from "../../components/collabs/collabsSlice"
import { updateCollabsOrder } from "../../components/teams/teamsSlice";
import SaveButton from "../../components/buttons/SaveButton";
import { ColorPickerButton } from "../../components/buttons/ColorPickerButton";
import ExcelManager from "../../app/managers/excelManager";
import { ConfirmDeleteModal } from '../../components/popups/ConfirmDeleteModal';
import userInfo from "../../assets/images/userInfo.png";
import userInfoFocus from "../../assets/images/userInfoFocus.png";
import moment from "moment";
import CollabModalDetails from "../collabs/CollabModalDetails";
import PermissionsModal from "../collabs/PermissionsModal";
import { showSuccessToast } from "../../app/utils/helpers";
import { config } from "../../app/utils/config";
import AddEvaluationModal from "../evaluations/modals/AddEvaluationModal";
import { setAddResponse, setEditResponse } from "../../components/evaluations/evaluationsSlice";

export function GroupesView() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const urlParams = useParams()

  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const groupesList = useSelector((state) => state.groupes.list)
  const selectedView = useSelector((state) => state.groupes.selectedView)
  const showFormPopup = useSelector((state) => state.planning.showFormPopup)
  const collabs = useSelector((state) => state.collabs.currentList)
  const managers = useSelector((state) => state.collabs.managers)
  const myTeam = useSelector((state) => state.teams.myTeam)
  const updatedCollabs = useSelector((state) => state.collabs.updateList)
  const teams = useSelector((state) => state.teams.list)
  const addEvaluationRes = useSelector((state) => state.evaluations.addResponse);
  const editEvaluationRes = useSelector((state) => (state.evaluations.editResponse));
  const isMobile = useSelector((state) => state.settings.isMobile)
  const selectedDate = useSelector((state) => state.planning.selectedDate)

  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(false)
  const [expandedRowsCollabs, setExpandedRowsCollabs] = useState([])
  const [expandedRowsManagers, setExpandedRowsManagers] = useState([])

  const mounted = useRef(null)
  const dtCollabs = useRef(null)
  const dtManagers = useRef(null)

  const teamPermissionsMessage = useSelector((state) => state.collabs.updateTeamPermissionsRes);
  const viewsOptions = [
    { label: 'Groupes', value: 0 },
    { label: 'Collabs', value: 1 },
    { label: 'Managers', value: 2 }
  ]

  let collabsList = listCollabs()
  let managersList = listManagers()

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }
    if (updatedCollabs.length) {
      window.addEventListener('beforeunload', alertUser)
    } else {
      window.removeEventListener('beforeunload', alertUser)
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [updatedCollabs])

  useEffect(() => {
    if (addEvaluationRes != null) {
      if (addEvaluationRes.trim().toLowerCase().includes("successfully")) {
        showSuccessToast("Success!")
      } else if (addEvaluationRes.trim().toLowerCase().includes("duplicate")) {
        showErrorToast(<Langs str="DUPICATE_WARNING" />)
      } else {
        showErrorToast(addEvaluationRes)
      }
      dispatch(setAddResponse(null))
    }
  }, [addEvaluationRes])

  useEffect(() => {
    if (editEvaluationRes != null) {
      if (editEvaluationRes.trim().toLowerCase().includes("successfully")) {
        showSuccessToast("Success!")
      } else if (editEvaluationRes.trim().toLowerCase().includes("duplicate")) {
        showErrorToast(<Langs str="DUPICATE_WARNING" />)
      } else {
        showErrorToast(editEvaluationRes)
      }
      dispatch(setEditResponse(null))
    }
  }, [editEvaluationRes])

  useEffect(() => {
    if (urlParams.subView && viewsOptions[urlParams.subView]) {
      dispatch(setSelectedView(urlParams.subView))
    }
  }, [urlParams])

  useEffect(() => {
    toastRes()
  }, [teamPermissionsMessage])

  useEffect(() => {
    if (selectedTeamsIds && selectedTeamsIds.length) {
      dispatch(getCollabs({ selectedTeamsIds, isCurrent: true }))
      dispatch(getGroupes(selectedTeamsIds))
      dispatch(getMembers({ teamsIds: selectedTeamsIds }))
    }
  }, [dispatch, selectedTeamsIds])

  function toastRes() {
    if (teamPermissionsMessage != null) {
      if (teamPermissionsMessage.trim().toLowerCase().includes("successfully")) {
        showSuccessToast(teamPermissionsMessage)
      } else {
        showErrorToast(teamPermissionsMessage)
      }
      dispatch(setUpdateTeamPermissionsRes())
    }
  }

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  const handleAddGroupModal = () => {
    dispatch(showGroupeModal({ type: "ADD_GROUPE" }))
  }

  const handleReorderCollabs = (e) => {
    dispatch(setCurrentCollabs(e.value))
    let teamId = e.value[0].id_team
    let idsOrder = e.value.reduce((array, current) => {
      array.push(current.id)
      return array
    }, [])
    dispatch(updateCollabsOrder({
      id_team: teamId,
      idsOrder: idsOrder,
      selectedDate: selectedDate
    }))
  }

  const handleViewChange = (view) => {
    let selectedTeamId = selectedTeamsIds[0]
    navigate(`/groupes/${selectedTeamId}/${view}`)
  }

  const statusBodyTemplate = (rowData) => {
    return <div style={{ color: (["Présent", "Actif"].includes(rowData.statut) ? "green" : "red") }}>
      {rowData.statut}
    </div>
  }

  const entryDateBodyTemplate = (rowData) => {
    return <div>{rowData.entryDate ? getFormattedDate(rowData.entryDate) : "-"}</div>
  }

  const autoCollabBodyTemplate = (rowData) => {
    return <input type="checkbox"
      id={rowData.id}
      checked={rowData.auto}
      disabled={!checkPermission(config.scopes.groups, config.permissionTypes.edit, rowData.id_team)}
      onChange={handleAutoCollabCheckChange} />
  }

  const handleAutoCollabCheckChange = (event) => {
    updateCollab(event.target.id, "auto", event.target.checked ? 1 : 0, 'number')
  }

  const exitDateBodyTemplate = (rowData) => {
    return <label className="text-center">
      {rowData.exitDate ? getFormattedDate(rowData.exitDate) : "-"}
    </label>
  }

  function getTeamLabelFromId(teamId) {
    let label = ""
    let teamsFiltered = teams && teams.filter(t => t.id == teamId)
    if (teamsFiltered && teamsFiltered[0]) {
      label = teamsFiltered[0].label
    }
    return label
  }

  function updateCollab(id, key, value, type) {
    let found
    let updateList = []
    for (let i = 0; i < updatedCollabs.length; i++) {
      const collab = Object.assign({}, updatedCollabs[i])
      if (collab.id == id) {
        collab[key] = value
        if (type == 'number') {
          collab[key] = Number(value)
        }
        found = true
      }
      updateList.push(collab)
    }
    if (!found) {
      for (let i = 0; i < collabsList.length; i++) {
        const collab = collabsList[i]
        if (collab.id == id) {
          let collabUpdates = { id: id }
          collabUpdates[key] = value
          if (type == 'number') {
            collabUpdates[key] = Number(value)
          }
          updateList.push(collabUpdates)
          break
        }
      }
    }
    dispatch(setUpdatedCurrentCollabs(updateList))
  }

  const handleColorChange = (data, color) => {
    updateCollab(data.id, "color", color)
  }

  function listSelectedTeam() {
    let teamIds = selectedTeamsIds.filter(id => !myTeam || checkPermission(config.scopes.groups, config.permissionTypes.view, myTeam.id) || myTeam.id != id)
    return teamIds
  }

  function listCollabs() {
    let list = collabs.filter(c => ['Présent', 'Actif'].includes(c.statut) &&
      listSelectedTeam().includes(c.id_team) &&
      checkPermission(config.scopes.groups, config.permissionTypes.view, c.id_team)
    )
    return list
  }

  function listManagers() {
    let list = managers.filter(c => ['Présent', 'Actif'].includes(c.statut) &&
      listSelectedTeam().includes(c.id_team) &&
      checkPermission(config.scopes.groups, config.permissionTypes.view, c.id_team)
    )
    return list
  }

  const handleSaveButtonClick = async () => {
    dispatch(setIsLoading(true))
    let response = await dispatch(updateCollabs({
      items: updatedCollabs
    }))
    let error = response["payload"]["responseError"]
    if (error) {
      showErrorToast()
    }
    dispatch(setIsLoading(false))
  }

  const colorBodyTemplate = (rowData) => {
    return <ColorPickerButton
      color={rowData.color}
      data={rowData}
      onColorChange={handleColorChange}
      disabled={!checkPermission(config.scopes.groups, config.permissionTypes.edit, rowData.id_team)} />
  }

  function getTeamLocalizationFromId(teamId) {
    let localization = ""
    let teamsFiltered = teams && teams.filter(t => t.id == teamId)
    if (teamsFiltered && teamsFiltered[0]) {
      localization = teamsFiltered[0].localization
    }
    return localization
  }

  const soldeOldBodyTemplate = (rowData) => {
    return <label>
      <strong>
        {rowData.solde_cp_old}
      </strong>
    </label>
  }

  const soldeCurrentBodyTemplate = (rowData) => {

    return <label>
      <strong>
        {rowData.solde_cp_current}
      </strong>
    </label>
  }

  const soldeExpirylBodyTemplate = (rowData) => {
    return <label className="text-center text-danger">
      {rowData.solde_cp_old_expiry ? getFormattedDate(rowData.solde_cp_old_expiry) : '-'}
    </label>
  }

  const headerTemplate = (data) => {
    return <React.Fragment>
      <span className="vertical-align-middle ml-2 font-bold line-height-3">
        {getTeamLabelFromId(data.id_team)}
      </span>
    </React.Fragment>
  }

  const footerTemplate = (data) => {
    return (
      <React.Fragment>
        <td colSpan={100}>
          <div className="d-flex justify-content-start fw-bold w-100">
            <Langs str={"Total"} /> <Langs str={viewsOptions[selectedView].label} /> : {collabsList.filter(c => c.id_team == data.id_team).length}
          </div>
        </td>
      </React.Fragment>
    )
  }
  const footerTemplateMan = (data) => {
    return (
      <React.Fragment>
        <td colSpan={100}>
          <div className="d-flex justify-content-start fw-bold w-100">
            <Langs str={"Total"} /> <Langs str={viewsOptions[selectedView].label} /> : {managersList.filter(c => c.id_team == data.id_team).length}
          </div>
        </td>
      </React.Fragment>
    )
  }

  function renderFloatingButton() {
    if (updatedCollabs.length) {
      return <SaveButton
        style={{ color: 'white', backgroundColor: '#FA9016', padding: 10 }}
        active={true}
        image={saveIcon}
        onClick={handleSaveButtonClick}
        tooltip="SAVE" />
    }
  }

  const handleExpandRowsCollabs = (e) => {
    // Expend only one row at a time
    if (e.data.length) {
      setExpandedRowsCollabs([e.data[e.data.length - 1]])
    }
    else {
      setExpandedRowsCollabs([])
    }
  }

  const handleExpandRowsManagers = (e) => {
    // Expend only one row at a time
    if (e.data.length) {
      setExpandedRowsManagers([e.data[e.data.length - 1]])
    }
    else {
      setExpandedRowsManagers([])
    }
  }

  const handleDeleteSelectedGroupe = (data) => {
    let ids = [selectedGroup.id]
    setShowDeletePopup(false)
    setSelectedGroup(null)
    if (ids && ids.length) {
      dispatch(deleteGroupes({ ids: ids })).then(
        (response) => {
          dispatch(getGroupes(selectedTeamsIds))
        }
      )
    }
  }

  const handleDeleteGroup = (groupeData) => {
    setSelectedGroup(groupeData)
    setShowDeletePopup(true)
  }

  function managersExist(managers) {
    return managers.length > 0;
  }

  const exportExcel = () => {
    let exportData = collabsList.reduce((array, current) => {
      let collabData = {
        "Matricule": current.matricule,
        "Nom": current.nom || "",
        "Prénom": current.prenom || "",
        "Email": current.email || "",
        "Poste": current.poste || "",
        "Solde": current.solde,
        "Solde N": current.solde_cp_current,
        "Solde N-1": current.solde_cp_old,
        "Solde N-1 expiration": current.solde_cp_old_expiry ? moment(current.solde_cp_old_expiry).format("DD-MM-YYYY") : "",
        "Date d'entrée": current.entryDate ? moment(current.entryDate).format("DD-MM-YYYY") : "",
      }
      array.push(collabData)
      return array
    }, [])
    ExcelManager.getInstance().generateAndDownloadExcelFromData(exportData, "collabs")
  }

  const viewCollabDetails = (rowData) => {
    return <ImageButton
      image={userInfo}
      imageFocus={userInfoFocus}
      onClick={() => viewCollabModal(rowData)}
      active={true}
      tooltip={<Langs str="VIEW_DETAILS" />}
      width={32} />
  }

  function viewCollabModal(data) {
    const modalData = { ...data, show: true };
    dispatch(setCollabModalData(modalData))
  }

  function renderSelectedView() {
    let subView = viewsOptions[selectedView]
    switch (subView.label) {
      case "Groupes":
        let filteredGroupesList = groupesList.filter((groupe) => listSelectedTeam().includes(groupe.id_team) &&
          checkPermission(config.scopes.groups, config.permissionTypes.view, groupe.id_team)
        )

        return <div className={styles.groupesView}>
          {filteredGroupesList.length ?
            filteredGroupesList.map((groupe) => (
              <GroupeCard data={groupe} key={`GroupeCard_${groupe.id}`} onDeleteGroup={handleDeleteGroup} />
            ))
            :
            <Row className="d-flex flex-row justify-content-center h-100">
              <img src={emptyPageImg} className={styles.emptyPageImg} />
              <span className={styles.noGroupstext}>
                <Langs str={"No groups yet."} />
                <button onClick={handleAddGroupModal}
                  className={styles.textBtn}>
                  <Langs str="Create new group" />
                </button>
              </span>
            </Row>
          }
        </div>
      case "Collabs":
        return <div className={styles.collabsCard} key="dt-collabs">
          {isMobile == 1 &&
            <DataTable ref={dtCollabs} value={collabsList}
              reorderableRows
              onRowReorder={handleReorderCollabs}
              dataKey="id"
              scrollable scrollHeight="calc(100vh - 185px)"
              // rowGroupMode={"subheader"}
              groupRowsBy={"id_team"}
              expandableRowGroups={listSelectedTeam().length > 1}
              expandedRows={expandedRowsCollabs}
              onRowToggle={handleExpandRowsCollabs}
              sortMode="single"
              sortField="id_team"
              rowGroupHeaderTemplate={headerTemplate}
              rowGroupFooterTemplate={footerTemplate}>
              {listSelectedTeam().length <= 1 && <Column rowReorder style={{ width: '3rem' }} />}
              <Column field="matricule" header={<Langs str="Matricule" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="nom" header={<Langs str="Family Name" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="prenom" header={<Langs str="Name" />} style={{ minWidth: '9rem' }}></Column>
              <Column header={<Langs str="AT" />} body={autoCollabBodyTemplate} style={{ minWidth: '6rem' }}></Column>
              <Column field="statut" header={<Langs str="Status" />} body={statusBodyTemplate} style={{ minWidth: '7' }}></Column>
              <Column field="poste" header={<Langs str="Position" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="mobileNumber" header={<Langs str="Mobile" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="email" header={<Langs str="Email" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="solde_cp_old_remaining" header={<Langs str="CPN-1 SILAE" />} style={{ minWidth: '9rem' }}></Column>
              <Column field="solde_cp_current_remaining" header={<Langs str="CPN SILAE" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="solde_cp_old" header={<Langs str="CPN-1" />} body={soldeOldBodyTemplate} style={{ minWidth: '8rem' }}></Column>
              <Column field="solde_cp_old_expiry" header={<Langs str="Expiration CPN-1" />} body={soldeExpirylBodyTemplate} style={{ minWidth: '9rem' }}></Column>
              <Column field="solde_cp_current" header={<Langs str="CPN" />} body={soldeCurrentBodyTemplate} style={{ minWidth: '8rem' }}></Column>
              {/*<Column field="solde" header={<Langs str="Total CP" />} style={{ minWidth: '7rem' }}></Column>*/}
              <Column field="entryDate" header={<Langs str="ENTRY_DATE" />} body={entryDateBodyTemplate} style={{ minWidth: '8rem' }}></Column>
              <Column field="exitDate" header={<Langs str="EXIT_DATE" />} body={exitDateBodyTemplate} style={{ minWidth: '8rem' }}></Column>
              <Column field="color" header={<Langs str="COLOR" />} body={colorBodyTemplate} style={{ minWidth: '7rem' }}></Column>
              <Column field="viewCollabDetailsBtn" body={viewCollabDetails} style={{ minWidth: '6rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
            </DataTable>
          }
          {(isMobile == 2 || isMobile == 3) &&
            <DataTable ref={dtCollabs} value={collabsList}
              reorderableRows
              onRowReorder={handleReorderCollabs}
              dataKey="id"
              scrollable scrollHeight="calc(100vh - 185px)"
              rowGroupMode={"subheader"}
              groupRowsBy={"id_team"}
              expandableRowGroups={listSelectedTeam().length > 1}
              expandedRows={expandedRowsCollabs}
              onRowToggle={handleExpandRowsCollabs}
              sortMode="single"
              sortField="id_team"
              rowGroupHeaderTemplate={headerTemplate}
              rowGroupFooterTemplate={footerTemplate}>
              <Column field="nom" header={<Langs str="Family Name" />} style={{ minWidth: '6rem' }}></Column>
              <Column field="prenom" header={<Langs str="Name" />} style={{ minWidth: '6rem' }}></Column>
              <Column field="viewCollabDetailsBtn" body={viewCollabDetails} style={{ minWidth: '4rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
            </DataTable>
          }
        </div>
      case "Managers":
        return <div className={styles.collabsCard} key="dt-managers">
          {isMobile == 1 &&
            <DataTable ref={dtManagers} value={managersList}
              dataKey="id"
              scrollable scrollHeight="calc(100vh - 185px)"
              rowGroupMode={"subheader"}
              groupRowsBy={"id_team"}
              expandableRowGroups={listSelectedTeam().length > 1}
              expandedRows={expandedRowsManagers}
              onRowToggle={handleExpandRowsManagers}
              sortMode="single"
              sortField="id_team"
              rowGroupHeaderTemplate={headerTemplate}
              rowGroupFooterTemplate={footerTemplateMan}>
              <Column field="matricule" header={<Langs str="Matricule" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="nom" header={<Langs str="Family Name" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="prenom" header={<Langs str="Name" />} style={{ minWidth: '9rem' }}></Column>
              <Column field="statut" header={<Langs str="Status" />} body={statusBodyTemplate} style={{ minWidth: '7' }}></Column>
              <Column field="type" header={<Langs str="Position" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="mobile_number" header={<Langs str="Mobile" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="email" header={<Langs str="Email" />} style={{ minWidth: '8rem' }}></Column>
              <Column field="viewCollabDetailsBtn" body={viewCollabDetails} style={{ minWidth: '6rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
            </DataTable>
          }
          {(isMobile == 2 || isMobile == 3) &&
            <DataTable ref={dtManagers} value={managersList}
              dataKey="id"
              scrollable scrollHeight="calc(100vh - 185px)"
              rowGroupMode={"subheader"}
              groupRowsBy={"id_team"}
              expandableRowGroups={listSelectedTeam().length > 1}
              expandedRows={expandedRowsManagers}
              onRowToggle={handleExpandRowsManagers}
              sortMode="single"
              sortField="id_team"
              rowGroupHeaderTemplate={headerTemplate}
              rowGroupFooterTemplate={footerTemplateMan}>
              <Column field="nom" header={<Langs str="Family Name" />} style={{ minWidth: '6rem' }}></Column>
              <Column field="prenom" header={<Langs str="Name" />} style={{ minWidth: '6rem' }}></Column>
              <Column field="viewCollabDetailsBtn" body={viewCollabDetails} style={{ minWidth: '4rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
            </DataTable>
          }
        </div>
      default:
        break
    }
  }

  function renderTopButtons() {
    let subView = viewsOptions[selectedView];
    switch (subView.label) {
      case "Groupes":
        return (
          <Col md={2} className="d-flex justify-content-end align-items-center p-0 m-0">
            {selectedTeamsIds.some(teamId =>
              checkPermission(config.scopes.groups, config.permissionTypes.edit, teamId)
            ) && (
                <ImageButton
                  image={addIcon}
                  imageFocus={addIconFocus}
                  onClick={handleAddGroupModal}
                  tooltip={<Langs str="Create new group" />}
                  active={true}
                  width={32}
                />
              )}
          </Col>
        )
      case "Collabs":
        return (
          <Col md={2} className="d-flex justify-content-end align-items-center p-0 m-0">
            <ImageButton
              image={exportIcon}
              imageFocus={exportIconFocus}
              onClick={exportExcel}
              active={true}
              tooltip="Export"
              width={32}
            />
          </Col>
        )
      default:
        break
    }
  }

  return (
    <div className={styles.containerView}>
      <div className="topMenu">
        {isMobile == 2 ? (
          <div>
            <Row>
              <Col md={5}>
                <span className="headerTitle">
                  {<Langs str={viewsOptions[selectedView] ? viewsOptions[selectedView].label : "Groupes"} />}
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={5} className="d-flex align-items-center">
                <TeamSelector includeMyTeam />
              </Col>
              <Col md={5} className="d-flex align-items-center justify-content-between">
                <ViewSelectButton
                  options={managersExist(managers) ? viewsOptions : viewsOptions.slice(0, 2)}
                  selectedView={viewsOptions[selectedView].value}
                  onViewChange={handleViewChange}
                />
                <div className="d-flex align-items-center">
                  {renderTopButtons()}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <Row>
            <Col md={5}>
              <span className="headerTitle">
                {<Langs str={viewsOptions[selectedView] ? viewsOptions[selectedView].label : "Groupes"} />}
              </span>
              <TeamSelector includeMyTeam />
            </Col>
            <Col md={5} className="d-flex justify-content-start pe-4 align-items-center p-0 m-0">
              <ViewSelectButton
                options={managersExist(managers) ? viewsOptions : viewsOptions.slice(0, 2)}
                selectedView={viewsOptions[selectedView].value}
                onViewChange={handleViewChange}
              />
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {renderTopButtons()}
            </Col>
          </Row>
        )}
      </div>
      {renderSelectedView()}
      {renderFloatingButton()}
      <ShiftFormModal
        isFromGroupesView={true}
        show={showFormPopup}
        onHide={() => dispatch(hideFormPopup())}
      />
      <ConfirmDeleteModal
        show={showDeletePopup}
        title={<Langs str='DELETE_GROUPE' />}
        message={<Langs str='DELETE_GROUPE_MSG' />}
        onDelete={handleDeleteSelectedGroupe}
        onHide={() => setShowDeletePopup(false)} />
      <CollabModalDetails page="groups" type={viewsOptions[selectedView].label} />
      <PermissionsModal page="groups" />
      <AddEvaluationModal />
    </div>
  )
}
