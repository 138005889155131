import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setDraggingItem } from '../../../containers/planning/planningSlice';
import { ShiftCell } from '../../scheduler/cells/shiftCell.js';
import styles from './Rows.module.css';
import { getUserPreferences } from '../../../app/utils/helpers';

export function SamplesRow(props) {

    const dispatch = useDispatch()
    const selectedSamplesView = useSelector((state) => state.planning.selectedSamplesView)
    const sampleShifts = useSelector((state) => state.sampleShifts.list)
    const userId = useSelector((state) => state.login.userId)
    const sampleLeaves = useSelector((state) => state.sampleLeaves.list)
    const draggingItem = useSelector((state) => state.planning.draggingItem)

    const handleDragStart = (value, event) => {
        if (draggingItem !== value)
            dispatch(setDraggingItem(value))
    }

    const handleDragStop = (value, event) => {
        dispatch(setDraggingItem(null))
    }

    function getSamples() {
        if (selectedSamplesView === "SHIFTS") {
            let shiftsArray = []
            if (sampleShifts && sampleShifts.length) {
                shiftsArray = shiftsArray.concat(sampleShifts)
            }
            if (sampleLeaves && sampleLeaves.length) {
                shiftsArray = shiftsArray.concat(sampleLeaves.filter(s => s.visibility))
            }
            let shiftsUserOrder = getUserPreferences(userId, "SHIFTS_SAMPLES_ORDER")
            if (shiftsUserOrder) {
                shiftsArray = shiftsArray.sort((a, b) => {
                    return shiftsUserOrder.indexOf(`${a["id"]}`) - shiftsUserOrder.indexOf(`${b["id"]}`)
                })
            }
            return shiftsArray.map(shift => {
                if (shift.id)
                    return <div key={"ShiftCell_" + shift.id}
                        draggable={true}
                        className={styles.draggableShift}
                        onDragStart={(handleDragStart.bind(this, shift))}
                        onDragEnd={handleDragStop.bind(this, shift)}>
                        <ShiftCell data={shift} isInteractive={false} />
                    </div>
            })
        }
    }

    return <div className={styles.samplesContainer}>
        {getSamples()}
    </div>
}