import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from "react-redux";
import styles from "./CollabsView.module.css";
import Langs from "../../app/lang/langs";
import exportIcon from "../../assets/images/export.png";
import exportIconFocus from "../../assets/images/exportFocus.png";
import clearIcon from "../../assets/images/clear.png";
import clearIconFocus from "../../assets/images/clearFocused.png";
import userInfo from "../../assets/images/userInfo.png";
import userInfoFocus from "../../assets/images/userInfoFocus.png";
import ImageButton from "../../components/buttons/ImageButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  checkPermission,
  getFormattedDate,
  getStatusTagClass,
  getVarFormattedDate
} from "../../app/utils/helpers";
import ExcelManager from "../../app/managers/excelManager";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";
import CollabModalDetails from "./CollabModalDetails";
import ResetPasswordDialog from './ResetPasswordDialog';
import PermissionsModal from "./PermissionsModal";
import { showSuccessToast, showErrorToast } from "../../app/utils/helpers";
import { ViewSelectButton } from "../../components/buttons/ViewSelectButton";
import WarningDetailsDialog from "../warnings/dialogs/WarningDetails";
import deleteIcon from "../../assets/images/delete.png";
import deleteIconFocus from "../../assets/images/delete-orange.png";
import { config } from "../../app/utils/config";
import addIcon from "../../assets/images/add.png";
import addIconFocus from "../../assets/images/addFocus.png";
import AddWarning from "../warnings/dialogs/AddWarning";
import { Dialog } from "primereact/dialog";
import { DynamicButton } from "../../components/buttons/DynamicButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllCollabs,
  setCollabModalData,
  setUpdatePermissionsRes,
  getAllWarnings,
  setSelectedView,
  setAddWarningRes,
  setWarningData,
  setWarningDetailsDialog,
  deleteWarning,
  setWarningDeleteRes,
  setWarningEditRes,
  setAddWarningDialog
} from "../../components/collabs/collabsSlice";

export function CollabsView() {

  const dispatch = useDispatch()
  const urlParams = useParams()
  const navigate = useNavigate()
  const mounted = useRef(null)
  const dtCollabsView = useRef(null)
  const dttCollabsView = useRef(null)

  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const permissionsMessage = useSelector((state) => state.collabs.updatePermissionsRes);
  const allCollabsList = useSelector((state) => state.collabs.allCollabsList)
  const allWarningsList = useSelector((state) => state.collabs.warningsList)
  const deleteWarningRes = useSelector((state) => state.collabs.warningDeleteRes);
  const editWarningsRes = useSelector((state) => state.collabs.warningEditRes);
  const addWarningRes = useSelector((state) => state.collabs.addWarningRes)
  const selectedView = useSelector((state) => state.collabs.selectedView)
  const [filteredCollabsList, setFilteredCollabsList] = useState(allCollabsList);
  const [filteredWarningsList, setFilteredWarningsList] = useState(allWarningsList);
  const permissions = useSelector((state) => state.login.permissions);
  const [deleteWarningId, setDeleteWarningId] = useState(null)
  const isMobile = useSelector((state) => state.settings.isMobile)

  let loader = useSelector((state) => state.collabs.allCollabsLoader)


  const [filter, setFilter] = useState({
    matricule: '',
    nom: '',
    prenom: '',
    company: '',
    team: '',
    entree_date: ''
  })

  const [tempFilter, setTempFilter] = useState({
    matricule: '',
    nom: '',
    prenom: '',
    company: '',
    team: '',
    entree_date: ''
  })


  const [warningFilter, setWarningFilter] = useState({
    collab_matricule: '',
    collab_nom: '',
    collab_prenom: '',
    team_name: '',
    warning_date: ''
  })

  const [tableFilters] = useState({
    statut: { value: null, matchMode: FilterMatchMode.EQUALS },
    company: { value: null, matchMode: FilterMatchMode.EQUALS }
  })

  const [uniqueStatuses, setUniqueStatuses] = useState([''])
  const [viewsOptions, setViewOptions] = useState([
    { label: <Langs str='Collabs' />, value: 0 },
    { label: <Langs str='Suivis' />, value: 1 }
  ])

  const [confirmDeleteVisibility, setConfirmDeleteVisibility] = useState(false)

  useEffect(() => {
    if (checkPermission(config.scopes.collabs, config.permissionTypes.view)) {
      dispatch(setSelectedView(0))
    }else if (checkPermission(config.scopes.warnings, config.permissionTypes.view) && !checkPermission(config.scopes.collabs, config.permissionTypes.view)) {
      dispatch(setSelectedView(1))
    }
  }, [mounted, permissions])

  useEffect(() => {
    if (urlParams.subView && viewsOptions[urlParams.subView]) {
      dispatch(setSelectedView(urlParams.subView))
    }
  }, [urlParams])

  useEffect(() => {
    if (!mounted.current) {
      if (checkPermission(config.scopes.collabs, config.permissionTypes.view)) {
        dispatch(getAllCollabs())
      }
      if (checkPermission(config.scopes.warnings, config.permissionTypes.view)) {
        dispatch(getAllWarnings())
      }
      mounted.current = true
    }
  }, [dispatch])


  useEffect(() => {
    const formattedFilter = {
      ...warningFilter,
      warning_date: warningFilter.warning_date ? getFormattedDate(warningFilter.warning_date.trim()) : ''
    }
    setFilteredWarningsList(filterWarningsList(warningFilter))
  }, [allWarningsList, warningFilter])

  useEffect(() => {
    setFilteredCollabsList(filterCollabsList(filter))
  }, [allCollabsList])

  useEffect(() => {
    const formattedFilter = {
      ...filter,
      entree_date: filter.entree_date ? getFormattedDate(filter.entree_date.trim()) : ''
    }
    let filteredList = filterCollabsList(formattedFilter)
    setFilteredCollabsList(filteredList)
    const newUniqueStatuses = listStatusDropDown(filteredList)
    const updatedStatuses = ['', ...newUniqueStatuses]
    setUniqueStatuses(updatedStatuses)
  }, [filter])

  useEffect(() => {
    toastRes()
  }, [permissionsMessage])

  useEffect(() => {
    const trimmedFilter = Object.keys(tempFilter).reduce((acc, key) => {
      acc[key] = tempFilter[key].trim();
      return acc;
    }, {});
    setFilter(trimmedFilter)
  }, [tempFilter])

  useEffect(() => {
    if (deleteWarningRes != null) {
      if (deleteWarningRes.trim().toLowerCase().includes("successfully")) {
        showSuccessToast(deleteWarningRes)
      } else {
        showErrorToast(deleteWarningRes)
      }
      dispatch(setWarningDeleteRes(null))
    }
  }, [deleteWarningRes])

  useEffect(() => {
    if (editWarningsRes != null) {
      if (editWarningsRes.trim().toLowerCase().includes("successfully")) {
        showSuccessToast(editWarningsRes)
      } else {
        showErrorToast(editWarningsRes)
      }
      dispatch(setWarningEditRes(null))
    }
  }, [editWarningsRes])

  useEffect(() => {
    if (addWarningRes != null) {
      if (addWarningRes.trim().toLowerCase().includes("successfully")) {
        showSuccessToast(<Langs str="WARNING_SUCCESS" />)
      } else {
        showErrorToast(<Langs str="DUPICATE_WARNING" />)
      }
      dispatch(setAddWarningRes(null))
    }
  }, [addWarningRes])

  useEffect(() => {
    if (isMobile == 2 || isMobile == 3) {
      setTempFilter({
        nom: '',
        prenom: '',
      })
      setFilter({
        nom: '',
        prenom: '',
      })
      setWarningFilter({
        collab_nom: '',
        collab_prenom: '',
      })

    } else {
      setTempFilter({
        matricule: '',
        nom: '',
        prenom: '',
        company: '',
        team: '',
        entree_date: ''
      })
      setFilter({
        matricule: '',
        nom: '',
        prenom: '',
        company: '',
        team: '',
        entree_date: ''
      })
      setWarningFilter({
        collab_matricule: '',
        collab_nom: '',
        collab_prenom: '',
        team_name: '',
        warning_date: ''
      })
    }
  }, [isMobile])

  const statusBodyTemplate = (rowData) => {
    return <div style={{ color: (["Présent", "Actif"].includes(rowData.statut) ? "green" : "red") }}>
      {rowData.statut}
    </div>
  }

  const soldeExpirylBodyTemplate = (rowData) => {
    return (<label className="text-center text-danger">
      {rowData.solde_cp_old_expiry ? getFormattedDate(rowData.solde_cp_old_expiry) : '-'}
    </label>)
  }

  const soldeCurrentBodyTemplate = (rowData) => {
    return <label>
      <strong>
        {rowData.solde_cp_current}
      </strong>
    </label>
  }

  const soldeOldBodyTemplate = (rowData) => {
    return <label>
      <strong>
        {rowData.solde_cp_old}
      </strong>
    </label>
  }

  const entryDateBodyTemplate = (rowData) => {
    return (<label className="text-center">
      {rowData.entryDate ? getFormattedDate(rowData.entryDate) : '-'}
    </label>)
  }

  const exitDateBodyTemplate = (rowData) => {
    return (<label className="text-center">
      {rowData.exitDate ? getFormattedDate(rowData.exitDate) : '-'}
    </label>)
  }

  const createdAtDateBodyTemplate = (rowData) => {

    const formatDate = (timestamp) => {
      if (!timestamp) return '-';
      const date = new Date(timestamp);
      date.setHours(date.getHours() + 2);
      const isoString = date.toISOString().substring(0, 16);
      const rearranged = isoString.substring(8, 10) + '-' + isoString.substring(5, 7) + '-' + isoString.substring(0, 4) + ' ' + isoString.substring(11, 16);
      return rearranged;
    }

    return (
      <label className="">
        {formatDate(rowData.created_at)} <Langs str="by" />
        <br />
        {rowData.created_by ? `${rowData.created_by.nom} ${rowData.created_by.prenom}` : '-'}
      </label>
    )
  }

  const updatedAtDateBodyTemplate = (rowData) => {
    if (!rowData.updated_by.nom && !rowData.updated_by.prenom) {
      return null;
    }

    const formatDate = (timestamp) => {
      if (!timestamp) return '-';
      const date = new Date(timestamp);
      date.setHours(date.getHours() + 2);
      const isoString = date.toISOString().substring(0, 16);
      const rearranged = isoString.substring(8, 10) + '-' + isoString.substring(5, 7) + '-' + isoString.substring(0, 4) + ' ' + isoString.substring(11, 16);
      return rearranged;
    };

    return (
      <label className="">
        {formatDate(rowData.updated_at)} <Langs str="by" />
        <br />
        {rowData.created_by ? `${rowData.updated_by.nom} ${rowData.updated_by.prenom}` : '-'}
      </label>
    )
  }
  const commentsBodyTemplate = (rowData) => {
    const commentsArray = rowData.comments.split('\n');

    return (
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        {commentsArray.map((comment, index) => (
          comment.trim() !== "" && (
            <li key={index} style={{
              position: 'relative',
              paddingLeft: '20px',
              wordWrap: 'break-word',
              maxWidth: '35ch',
              marginBottom: '8px'
            }}>
              <span style={{
                position: 'absolute',
                left: '0',
                top: '0'
              }}>•</span>
              {comment}
            </li>
          )
        ))}
      </ul>
    )
  }

  const warningDateBodyTemplate = (rowData) => {
    return (<label className="">
      {rowData.warning_date ? getFormattedDate(rowData.warning_date) : '-'}
    </label>)
  }

  const categoryBodyTemplate = (rowData) => {

    let severity
    if (rowData.category === 'warning') {
      severity = 'warning'
    } else if (rowData.category === 'recall the order') {
      severity = 'danger'
    } else if (rowData.category === 'formal notice') {
      severity = 'info'
    }
    return (
      <Tag value={<Langs str={rowData.category} />} style={{ backgroundColor: rowData.color }} />
    )
  }

  const warningStatusBodyTemplate = (rowData) => {
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      let capitalized = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return <Langs str={capitalized} />
    }

    let color = '';
    switch (rowData.status.toLowerCase()) {
      case 'sent':
        color = "green";
        break;
      case 'received':
        color = "#FFD11A";
        break;
      case 'signed':
        color = "blue";
        break;
      default:
        color = "black";
    }
    return (
      <div style={{ color: color }}>
        {capitalize(rowData.status)}
      </div>
    )
  }

  const viewCollabDetails = (rowData) => {
    return <ImageButton
      image={userInfo}
      imageFocus={userInfoFocus}
      onClick={() => viewCollabModal(rowData)}
      active={true}
      tooltip={<Langs str="VIEW_DETAILS" />}
      width={32} />
  }

  const viewWarningDetails = (rowData) => {
    const hasEditPermission = checkPermission(config.scopes.warnings, config.permissionTypes.edit);
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <ImageButton
          image={userInfo}
          imageFocus={userInfoFocus}
          onClick={() => viewWarningModal(rowData)}
          active={true}
          tooltip={<Langs str="VIEW_DETAILS" />}
          width={32}
        />
        {hasEditPermission && (
          <ImageButton
            image={deleteIcon}
            imageFocus={deleteIconFocus}
            onClick={() => showConfirmDelete(rowData.id)}
            active={true}
            tooltip={<Langs str="DELETE_WARNING" />}
            width={32}
          />
        )}
      </div>
    )
  }

  const showConfirmDelete = (id) => {
    setDeleteWarningId(id)
    setConfirmDeleteVisibility(true)
  }

  const deleteWarningBody = (rowData) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <ImageButton
          image={deleteIcon}
          imageFocus={deleteIconFocus}
          onClick={() => dispatch(deleteWarning(rowData.id))}
          active={true}
          tooltip={<Langs str="VIEW_DETAILS" />}
          width={32} />
      </div>

    )
  }

  function toastRes() {
    if (permissionsMessage != null) {
      if (permissionsMessage.trim().toLowerCase().includes("successfully")) {
        showSuccessToast(permissionsMessage)
      } else {
        showErrorToast(permissionsMessage)
      }
      dispatch(setUpdatePermissionsRes())
    }
  }

  function viewCollabModal(data) {
    const modalData = { ...data, show: true };
    dispatch(setCollabModalData(modalData))
  }
  function viewWarningModal(data) {
    dispatch(setWarningData(data));
    dispatch(setWarningDetailsDialog(true))
  }

  const exportExcel = () => {
    let exportData = filteredCollabsList.reduce((array, current) => {
      let collabData = {
        "Matricule": current.matricule,
        "Entité": current.company || "",
        "Nom": current.nom || "",
        "Prénom": current.prenom || "",
        "Email": current.email || "",
        "Poste": current.poste || "",
        "Mobile": current.mobileNumber || "",
        "Localization": current.localization || "",
        "Client": current.team || "",
        "Statut": current.statut || "",
        "Date d'ancienntée": current.ancienneteeDate ? moment(current.ancienneteeDate).format("DD-MM-YYYY") : "",
        "Date d'entrée": current.entryDate ? moment(current.entryDate).format("DD-MM-YYYY") : "",
        "Date de sortie": current.exitDate || "",
        "Solde": current.solde || "",
        "Solde N": current.solde_cp_current || "",
        "Solde N-1": current.solde_cp_old || "",
        "Solde N-1 expiration": current.solde_cp_old_expiry ? moment(current.solde_cp_old_expiry).format("DD-MM-YYYY") : "",
        "Forfait déplacement": current.forfait_deplacement,
        "Frais téléphonique": current.frais_tel,
      }
      array.push(collabData)
      return array
    }, [])
    let exportDataWarning = filteredWarningsList.reduce((array, current) => {
      let warningData = {
        "Matricule": current.collab_matricule,
        "Nom": current.collab_nom || "",
        "Prénom": current.collab_prenom || "",
        "Team": current.team_name || "",
        "Email": current.collab_email || "",
        "Category": current.category || "",
        "Status": current.status || "",
        "Poste": current.poste || "",
        "Date": current.warning_date ? moment(current.warning_date).format("DD-MM-YYYY") : "",
        "Date de création": current.created_at ? moment(current.created_at).format("DD-MM-YYYY") : "",
        "Created By": current.created_by ? `${current.created_by.nom} ${current.created_by.prenom}` : "",
        "Date de mise à jour": current.updated_at ? moment(current.updated_at).format("DD-MM-YYYY") : "",
        "Updated By": current.updated_by ? `${current.updated_by.nom} ${current.updated_by.prenom}` : ""
      }
      array.push(warningData)
      return array
    }, [])
    if (selectedView == 0) {
      ExcelManager.getInstance().generateAndDownloadExcelFromData(exportData, execelExtensionName())
    } else if (selectedView == 1) {
      ExcelManager.getInstance().generateAndDownloadExcelFromData(exportDataWarning, execelExtensionNameWarnings())
    }
  }
  const HandleAddWarningModal = () => {
    dispatch(setAddWarningDialog(true))
  }

  function execelExtensionName() {
    const usedFilters = Object.entries(filter).filter(([key, value]) => value !== '').map(([key]) => key);
    if (usedFilters.length === 0) {
      return "all_collabs";
    } else {

      return `filtered_by_${usedFilters.join('_')}`;
    }
  }

  function execelExtensionNameWarnings() {
    const usedFilters = Object.entries(warningFilter).filter(([key, value]) => value !== '').map(([key]) => key);
    if (usedFilters.length === 0) {
      return "all_warnings";
    } else {

      return `filtered_by_${usedFilters.join('_')}`;
    }
  }

  let companies = [], statuses = []
  filteredCollabsList.map(collab => {
    if (collab.company && !companies.includes(collab.company))
      companies.push(collab.company)
    if (collab.statut && !statuses.includes(collab.statut))
      statuses.push(collab.statut)
  })

  const statusItemTemplate = (option) => {
    if (option)
      return <Tag value={<Langs str={option} />} severity={getStatusTagClass(option)} />
    return <div><Langs str={'Select'} /></div>
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        valueTemplate={statusItemTemplate}
        placeholder={<Langs str={"Select"} />}
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const companiesItemTemplate = (option) => {
    if (option)
      return (
        <div className="flex align-items-center gap-2">
          <span>{option}</span>
        </div>
      )
    return <div><Langs str={'Select'} /></div>
  }

  const companyRowFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value}
        options={companies}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={companiesItemTemplate}
        valueTemplate={companiesItemTemplate}
        placeholder={<Langs str={"Select"} />}
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  function renderFilterSpan(filterKey) {
    const langFilter = {
      matricule: 'Matricule',
      nom: 'Family Name',
      prenom: 'Name',
      company: 'Company',
      team: 'Client',
      entree_date: 'FILTER_ENTRY_DATE'
    };
    if (langFilter.hasOwnProperty(filterKey)) {
      return <Langs str={langFilter[filterKey]} />;
    } else {
      return <span>Invalid filter key</span>;
    }
  }

  function renderWarningFilterSpan(filterKey) {
    const langFilter = {
      collab_matricule: 'Matricule',
      collab_nom: 'Family Name',
      collab_prenom: 'Name',
      team_name: 'Client',
      warning_date: 'warning_date'
    };
    if (langFilter.hasOwnProperty(filterKey)) {
      return <Langs str={langFilter[filterKey]} />;
    } else {
      return <span>Invalid filter key</span>;
    }
  }

  function listStatusDropDown(objects) {
    const statusSet = new Set(objects.map(obj => obj.statut).filter(Boolean));
    return Array.from(statusSet);
  }

  function filterCollabsList(formattedFilter) {
    const areAllFiltersEmpty = Object.values(formattedFilter).every(value => value === '')
    //console.log(formattedFilter)
    if (areAllFiltersEmpty) {
      return allCollabsList
    }
    return allCollabsList.filter(collab => {
      return (
        (!formattedFilter.team || (collab.team && collab.team.toLowerCase().includes(formattedFilter.team.toLowerCase()))) &&
        (!formattedFilter.matricule || (collab.matricule && collab.matricule.toLowerCase().includes(formattedFilter.matricule.toLowerCase()))) &&
        (!formattedFilter.nom || (collab.nom && collab.nom.toLowerCase().includes(formattedFilter.nom.toLowerCase()))) &&
        (!formattedFilter.prenom || (collab.prenom && collab.prenom.toLowerCase().includes(formattedFilter.prenom.toLowerCase()))) &&
        (!formattedFilter.company || (collab.company && collab.company.toLowerCase().includes(formattedFilter.company.toLowerCase()))) &&
        (!formattedFilter.entree_date || (collab.entryDate && getFormattedDate(collab.entryDate) >= formattedFilter.entree_date))
      )
    })
  }

  function filterWarningsList(formattedFilter) {
    const areAllFiltersEmpty = Object.values(formattedFilter).every(value => value === '')
    if (areAllFiltersEmpty) {
      return allWarningsList
    }

    return allWarningsList.filter(warning => {
      return (
        (!formattedFilter.team_name || (warning.team_name && warning.team_name.toLowerCase().includes(formattedFilter.team_name.toLowerCase()))) &&
        (!formattedFilter.collab_matricule || (warning.collab_matricule && warning.collab_matricule.toLowerCase().includes(formattedFilter.collab_matricule.toLowerCase()))) &&
        (!formattedFilter.collab_nom || (warning.collab_nom && warning.collab_nom.toLowerCase().includes(formattedFilter.collab_nom.toLowerCase()))) &&
        (!formattedFilter.collab_prenom || (warning.collab_prenom && warning.collab_prenom.toLowerCase().includes(formattedFilter.collab_prenom.toLowerCase()))) &&
        (!formattedFilter.company || (warning.company && warning.company.toLowerCase().includes(formattedFilter.company.toLowerCase()))) &&
        (!formattedFilter.warning_date || (warning.warning_date && getVarFormattedDate(warning.warning_date, "yyyy-mm-dd") == formattedFilter.warning_date))
      )
    })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempFilter(prevTempFilter => ({
      ...prevTempFilter,
      [name]: value
    }))
  }

  const handleInputChangeWarningFilter = (e) => {
    const { name, value } = e.target;
    setWarningFilter(prevWarningFilter => ({
      ...prevWarningFilter,
      [name]: value.trim()
    }))
  }

  const clearFilter = (e) => {
    e.preventDefault();
    if (isMobile == 1) {
      setTempFilter({
        matricule: '',
        nom: '',
        prenom: '',
        company: '',
        team: '',
        entree_date: ''
      });
    }
    if (isMobile == 2 || isMobile == 3) {
      setTempFilter({
        nom: '',
        prenom: '',
      });
    }

  }
  const clearWarningFilter = (e) => {
    e.preventDefault();
    if (isMobile == 1) {
      setWarningFilter({
        collab_matricule: '',
        collab_nom: '',
        collab_prenom: '',
        team_name: '',
        warning_date: ''
      })
    }
    if (isMobile == 2 || isMobile == 3) {
      setWarningFilter({
        collab_nom: '',
        collab_prenom: ''
      })
    }
  }

  const handleViewChange = (view) => {
    if (view != null) {
      let selectedTeamId = selectedTeamsIds[0]
      navigate(`/collabs/${selectedTeamId}/${view}`)
    }
  }

  function deleteWarningFinal() {
    dispatch(deleteWarning(deleteWarningId));
    setDeleteWarningId(null);
    setConfirmDeleteVisibility(false);
  }

  function renderConfirmDelete() {
    return (
      <div className="card flex justify-content-center">
        <Dialog header={<Langs str="DELETE" />}
          draggable={false}
          visible={confirmDeleteVisibility}
          style={isMobile == 1 ? { width: '25vw' } : { width: '100vw' }}
          onHide={() => { setConfirmDeleteVisibility(false); setDeleteWarningId(null) }}>
          <label className="mb-3 text-center w-100">
            <Langs str="CONFIRM_DELETE" />
          </label>
          <div className="w-50 mx-auto">

            <DynamicButton
              onClick={() => deleteWarningFinal()}
              title={<Langs str="DELETE_WARNING" />}
              active={true}
              color="red"
            />
          </div>
        </Dialog>
      </div>
    )
  }

  function renderInput({ key, value, handleInputChange, uniqueStatuses }) {
    if (key.includes('date'))
      return <input
        type="date"
        className="form-control"
        aria-label={key}
        name={key}
        value={value}
        onChange={handleInputChange}
      />
    else if (key.includes('statut'))
      return <select
        className="form-control"
        name={key}
        value={value}
        onChange={handleInputChange}
      >
        {uniqueStatuses.map((status) => (
          <option key={status} value={status}>
            {status === '' ? <Langs str="PICK_STATUS" /> : status}
          </option>
        ))}
      </select>
    else return <input
      type="text"
      className="form-control"
      aria-label={key}
      name={key}
      value={value}
      onChange={handleInputChange}
      placeholder={isMobile === 2 ? (key == 'collab_nom' || key == "nom" ? "Nom" : "Prenom") : ""}
    />
  }

  function filterCollabs() {
    if (isMobile == 3) {
      return null
    }
    let subView = viewsOptions[selectedView]
    switch (subView.value) {
      case 0:
        if (checkPermission(config.scopes.collabs, config.permissionTypes.view)) {
          return (
            <div className={styles.filterContainer}>
              <div className={`${styles.filterInnerContainer} container-fluid my-4 p-1`}>
                <div className="row mx-0 w-100">
                  <form className="d-flex w-100 justify-content-between" role="search">
                    {isMobile == 1 &&
                      Object.entries(tempFilter).map(([key, value]) => (
                        <div key={key} className="input-group flex-grow-1 me-2">
                          <span className="input-group-text" id={`basic-addon-${key}`}>
                            {renderFilterSpan(key)}
                          </span>
                          {renderInput({ key, value, handleInputChange, uniqueStatuses })}
                        </div>
                      ))
                    }
                    {(isMobile == 2 || isMobile == 3) &&
                      Object.entries(tempFilter).map(([key, value]) => (
                        <div key={key} className="input-group flex-grow-1 me-2">
                          {renderInput({ key, value, handleInputChange, uniqueStatuses })}
                        </div>
                      ))
                    }
                    <ImageButton
                      image={clearIcon}
                      imageFocus={clearIconFocus}
                      onClick={clearFilter}
                      active={true}
                      tooltip="Clear"
                      width={32} />
                  </form>
                </div>
              </div>
            </div>
          )
        }
      case 1:
        return (
          <div className={styles.filterContainer}>
            <div className={`${styles.filterInnerContainer} container-fluid my-4 p-1`}>
              <div className="row mx-0 w-100">
                <form className="d-flex w-100 justify-content-between" role="search">
                  {isMobile == 1 &&
                    Object.entries(warningFilter).map(([key, value]) => (
                      <div key={key} className="input-group flex-grow-1 me-2">
                        <span className="input-group-text" id={`basic-addon-${key}`}>
                          {renderWarningFilterSpan(key)}
                        </span>
                        {renderInput({ key, value, "handleInputChange": handleInputChangeWarningFilter, uniqueStatuses })}
                      </div>
                    ))
                  }
                  {(isMobile == 2 || isMobile == 3) &&
                    Object.entries(warningFilter).map(([key, value]) => (
                      <div key={key} className="input-group flex-grow-1 me-2">
                        {renderInput({ key, value, "handleInputChange": handleInputChangeWarningFilter, uniqueStatuses })}
                      </div>
                    ))
                  }
                  <ImageButton
                    image={clearIcon}
                    imageFocus={clearIconFocus}
                    onClick={clearWarningFilter}
                    active={true}
                    tooltip="Clear"
                    width={32} />
                </form>
              </div>
            </div>
          </div>
        )
      default:
        break
    }
  }

  function renderBody() {
    let subView = viewsOptions[selectedView]
    switch (subView.value) {
      case 0:
        if (loader === 'success') {
          return <div className={styles.collabsCard}>
            {isMobile == 1 &&
              <DataTable ref={dtCollabsView} value={filteredCollabsList}
                dataKey="id"
                paginator
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}
                rows={10}
                scrollable scrollHeight="calc(100vh - 300px)"
                sortMode="single"
                filters={tableFilters}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              >
                {filteredCollabsList.length <= 1 && <Column style={{ width: '3rem' }} />}
                <Column field="matricule" header={<Langs str="Matricule" />} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="company" header={<Langs str="Company" />} sortable style={{ minWidth: '8rem' }} filterField="company" showFilterMatchModes={false} filter filterElement={companyRowFilterTemplate}></Column>
                <Column field="nom" header={<Langs str="Family Name" />} sortable dataType="text" style={{ minWidth: '8rem' }}></Column>
                <Column field="prenom" header={<Langs str="Name" />} sortable style={{ minWidth: '9rem' }}></Column>
                <Column field="statut" header={<Langs str="Status" />} sortable body={statusBodyTemplate} style={{ minWidth: '7' }} showFilterMatchModes={false} filter filterElement={statusRowFilterTemplate}></Column>
                <Column field="poste" header={<Langs str="Position" />} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="team" header={<Langs str="Client" />} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="mobileNumber" header={<Langs str="Mobile" />} style={{ minWidth: '8rem' }}></Column>
                <Column field="email" header={<Langs str="Email" />} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="solde_cp_old_remaining" header={<Langs str="CPN-1 SILAE" />} sortable style={{ minWidth: '9rem' }}></Column>
                <Column field="solde_cp_current_remaining" header={<Langs str="CPN SILAE" />} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="solde_cp_old" header={<Langs str="CPN-1" />} sortable body={soldeOldBodyTemplate} style={{ minWidth: '8rem' }}></Column>
                <Column field="solde_cp_old_expiry" header={<Langs str="Expiration CPN-1" />} sortable body={soldeExpirylBodyTemplate} style={{ minWidth: '9rem' }}></Column>
                <Column field="solde_cp_current" header={<Langs str="CPN" />} sortable body={soldeCurrentBodyTemplate} style={{ minWidth: '8rem' }}></Column>
                <Column field="entryDate" header={<Langs str="ENTRY_DATE" />} sortable dataType="date" body={entryDateBodyTemplate} style={{ minWidth: '8rem' }}></Column>
                <Column field="exitDate" header={<Langs str="EXIT_DATE" />} sortable dataType="date" body={exitDateBodyTemplate} style={{ minWidth: '8rem' }}></Column>
                <Column field="frais_tel" header={<Langs str="Frais téléphonique" />} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="forfait_deplacement" header={<Langs str="Forfait déplacement" />} sortable style={{ minWidth: '8rem' }}></Column>
                <Column field="viewCollabDetailsBtn" body={viewCollabDetails} style={{ minWidth: '6rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
              </DataTable>
            }
            {isMobile == 2 &&
              <DataTable ref={dtCollabsView} value={filteredCollabsList}
                dataKey="id"
                paginator
                paginatorTemplate=" PrevPageLink NextPageLink CurrentPageReport"
                rowsPerPageOptions={[10, 25, 50]}
                rows={10}
                scrollable scrollHeight={isMobile == 1 ? "calc(100vh - 300px)" : "calc(100vh - 350px)"}
                sortMode="single"
                filters={tableFilters}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              >
                {filteredCollabsList.length <= 1 && <Column style={{ width: '3rem' }} />}
                <Column field="nom" header={<Langs str="Family Name" />} sortable filter filterPlaceholder="Search by name" style={{ minWidth: '8rem' }}></Column>
                <Column field="prenom" header={<Langs str="Name" />} sortable style={{ minWidth: '7rem' }}></Column>
                <Column field="viewCollabDetailsBtn" body={viewCollabDetails} style={{ minWidth: '2rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
              </DataTable>
            }
            {isMobile == 3 &&
              <DataTable ref={dtCollabsView} value={filteredCollabsList}
                dataKey="id"
                paginator
                paginatorTemplate=" PrevPageLink NextPageLink CurrentPageReport"
                rowsPerPageOptions={[10, 25, 50]}
                rows={10}
                scrollable scrollHeight={isMobile == 1 ? "calc(100vh - 300px)" : "calc(100vh - 195px)"}
                sortMode="single"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              >
                {filteredCollabsList.length <= 1 && <Column style={{ width: '3rem' }} />}
                <Column field="nom" header={<Langs str="Family Name" />} sortable filter filterPlaceholder="Search by name" style={{ minWidth: '8rem' }}></Column>
                <Column field="prenom" header={<Langs str="Name" />} sortable filter filterPlaceholder="Search by name" style={{ minWidth: '7rem' }}></Column>
                <Column field="viewCollabDetailsBtn" body={viewCollabDetails} style={{ minWidth: '2rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
              </DataTable>
            }
          </div>
        }
        break
      case 1:
        return <div className={styles.collabsCard}>
          {isMobile == 1 &&
            <DataTable ref={dttCollabsView} value={filteredWarningsList}
              dataKey="id"
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[10, 25, 50]}
              rows={10}
              scrollable scrollHeight="calc(100vh - 300px)"

              sortMode="single"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
              {filteredWarningsList.length <= 1 && <Column style={{ width: '3rem' }} />}
              <Column field="collab_matricule" header={<Langs str="Matricule" />} sortable style={{ minWidth: '8rem' }}></Column>
              <Column field="collab_nom" header={<Langs str="Family Name" />} sortable dataType="text" style={{ minWidth: '8rem' }}></Column>
              <Column field="collab_prenom" header={<Langs str="Name" />} sortable style={{ minWidth: '9rem' }}></Column>
              <Column field="category" header={<Langs str="category" />} sortable body={categoryBodyTemplate} style={{ minWidth: '11rem' }}></Column>
              <Column field="status" header={<Langs str="statut" />} sortable body={warningStatusBodyTemplate} style={{ minWidth: '9rem' }}></Column>
              <Column field="team_name" header={<Langs str="Client" />} sortable style={{ minWidth: '9rem' }}></Column>
              <Column field="warning_date" header={<Langs str="date" />} sortable body={warningDateBodyTemplate} style={{ minWidth: '9rem' }}></Column>
              <Column field="created_at" header={<Langs str="created_at" />} sortable body={createdAtDateBodyTemplate} style={{ minWidth: '9rem' }}></Column>
              <Column field="updated_at" header={<Langs str="updated_at" />} sortable body={updatedAtDateBodyTemplate} style={{ minWidth: '9rem' }}></Column>
              <Column field="comments" header={<Langs str="DESCRIPTION" />} body={commentsBodyTemplate} style={{ minWidth: '9rem' }}></Column>
              <Column field="viewCollabDetailsBtn" body={viewWarningDetails} style={{ minWidth: '6rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
            </DataTable>
          }
          {isMobile == 2 &&
            <DataTable ref={dttCollabsView} value={filteredWarningsList}
              dataKey="id"
              paginator
              paginatorTemplate="PrevPageLink NextPageLink CurrentPageReport"
              rowsPerPageOptions={[10, 25, 50]}
              rows={10}
              scrollable scrollHeight="calc(100vh - 350px)"
              sortMode="single"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
              {filteredWarningsList.length <= 1 && <Column style={{ width: '3rem' }} />}
              <Column field="collab_nom" header={<Langs str="Family Name" />} sortable dataType="text" style={{ minWidth: '6rem' }}></Column>
              <Column field="collab_prenom" header={<Langs str="Name" />} sortable style={{ minWidth: '6rem' }}></Column>
              <Column field="viewCollabDetailsBtn" body={viewWarningDetails} style={{ minWidth: '4rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
            </DataTable>
          }
          {isMobile == 3 &&
            <DataTable ref={dttCollabsView} value={filteredWarningsList}
              dataKey="id"
              paginator
              paginatorTemplate="PrevPageLink NextPageLink CurrentPageReport"
              rowsPerPageOptions={[10, 25, 50]}
              rows={10}
              scrollable scrollHeight="calc(100vh - 180px)"
              sortMode="single"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
            >
              {filteredWarningsList.length <= 1 && <Column style={{ width: '3rem' }} />}
              <Column field="collab_nom" header={<Langs str="Family Name" />} sortable filter filterPlaceholder="Search by name" style={{ minWidth: '6rem' }}></Column>
              <Column field="collab_prenom" header={<Langs str="Name" />} sortable filter filterPlaceholder="Search by name" style={{ minWidth: '6rem' }}></Column>
              <Column field="viewCollabDetailsBtn" body={viewWarningDetails} style={{ minWidth: '4rem', textAlign: 'right' }} alignFrozen="right" frozen={true}></Column>
            </DataTable>
          }
        </div>
        break
      default:
        break
    }
  }
  function topMenu() {
    if (isMobile == 1) {
      return (
        <Row>
          <Col md={5}>
            <span className="headerTitle">
              {
                selectedView == 1
                  ? <Langs str="Suivis" />
                  : <Langs str="collabs" />
              }
            </span>
          </Col>
          <Col md={5}
            className="d-flex justify-content-start pe-4 align-items-center p-0 m-0">
            {
              checkPermission(config.scopes.warnings, config.permissionTypes.view) &&
              checkPermission(config.scopes.collabs, config.permissionTypes.view) &&
              (
                <ViewSelectButton
                  options={viewsOptions}
                  selectedView={viewsOptions[selectedView].value}
                  onViewChange={handleViewChange}
                />
              )
            }
          </Col>
          <Col md={2}
            className="d-flex justify-content-end pe-4 align-items-center p-0 m-0 ms-auto">
            <ImageButton
              image={exportIcon}
              imageFocus={exportIconFocus}
              onClick={exportExcel}
              active={true}
              tooltip="Export"
              width={32} />
            {checkPermission(config.scopes.warnings, config.permissionTypes.edit) && selectedView == 1 && (
              <ImageButton
                image={addIcon}
                imageFocus={addIconFocus}
                onClick={HandleAddWarningModal}
                active={true}
                tooltip={<Langs str="ADD_WARNING" />}
                width={32} />
            )}
          </Col>
        </Row>
      )
    }
    if (isMobile == 2 || isMobile == 3) {
      return (
        <Row>
          <Col md={5}>
            <span className="headerTitle">
              {
                selectedView == 1
                  ? <Langs str="Suivis" />
                  : <Langs str="collabs" />
              }
            </span>
          </Col>
          <Col md={7}
            className="d-flex justify-content-between align-items-center m-0 mt-3">
            {
              checkPermission(config.scopes.warnings, config.permissionTypes.view) &&
              checkPermission(config.scopes.collabs, config.permissionTypes.view) &&
              (
                <ViewSelectButton
                  options={viewsOptions}
                  selectedView={viewsOptions[selectedView].value}
                  onViewChange={handleViewChange}
                />
              )
            }
            <div>
              <ImageButton
                image={exportIcon}
                imageFocus={exportIconFocus}
                onClick={exportExcel}
                active={true}
                tooltip="Export"
                width={32} />
              {checkPermission(config.scopes.warnings, config.permissionTypes.edit) && selectedView == 1 && (
                <ImageButton
                  image={addIcon}
                  imageFocus={addIconFocus}
                  onClick={HandleAddWarningModal}
                  active={true}
                  tooltip={<Langs str="ADD_WARNING" />}
                  width={32} />
              )}
            </div>
          </Col>
        </Row>
      )
    }
  }

  return (
    <div className={styles.containerView}>
      <div className="topMenu">
        {topMenu()}
      </div>
      {filterCollabs()}
      {renderBody()}
      {renderConfirmDelete()}
      <CollabModalDetails page="collabs"/>
      <ResetPasswordDialog />
      <PermissionsModal page="collabs" />
      <WarningDetailsDialog />
      <AddWarning />
    </div>
  )
}
