import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Modal from 'react-bootstrap/Modal';
import Langs from "../../../app/lang/langs";
import { ThemeButton } from '../../buttons/ThemeButton'
import { TransparentDatePicker } from '../../buttons/TransparentDatePicker';
import { SelectInput } from '../../inputs/Select';
import moment from 'moment';
import { generateCraZip , fetchZipGenerationProgress,setZipGenerationProgress, setIsLoadingCra} from '../../collabs/collabsSlice';

export function ImpressCraModal(props) {


    const dispatch = useDispatch()

    const selectedTeams = useSelector((state) => state.teams.selectedTeamsIds)
    const selectedDate = useSelector((state) => state.planning.selectedDate)
    const settings = useSelector((state) => state.settings)
    const isLoadingCra = useSelector((state) => state.collabs.isLoadingCra)
    const userId = useSelector((state) => state.login.userId)
    const zipGenerationProgress = useSelector((state) => state.collabs.zipGenerationProgress);


    function hide() {
        props.onHide()
    }
    const mounted = useRef(false)

    const [formError, setFormError] = useState(null);
    const [formData, setFormData] = useState({
        date: new Date(),
        id_company: null,
    });

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            dispatch(fetchZipGenerationProgress());
        }
    });

    useEffect(() => {
        if (selectedDate) {
            setFormData(formData => ({ ...formData, date: selectedDate }));
        }
    }, [selectedDate]);

    useEffect(() => {
        let intervalId;

        if (isLoadingCra) {

            intervalId = setInterval(() => {
                dispatch(fetchZipGenerationProgress());
            }, 2500);
        }

        return () => clearInterval(intervalId);
    }, [isLoadingCra, dispatch]);

    const handleImpressCra = async () => {
        dispatch(fetchZipGenerationProgress())
        if (zipGenerationProgress !== null) {
            console.log("Une génération de CRA est déjà en cours. Veuillez patienter. :" +  zipGenerationProgress);
            return;
        } else {
            setIsLoadingCra(true);

            if (!formData.id_company) {
                setFormError('id_company');
                return;
            }

            setFormError(null);

            const monthDate = moment(selectedDate, "x").format("MM-YYYY");
            const fileName = `CRA_${monthDate}_${formData.id_company}.zip`;

            hide();

            dispatch(generateCraZip({
                id_collab: userId,
                teams: selectedTeams,
                monthDate: monthDate,
                selectedDate: selectedDate,
                id_company: formData.id_company,
                fileName: fileName,
            })).then(() => {
                setIsLoadingCra(false);
                dispatch(setZipGenerationProgress(null));
            });
        }
    }


    const handleCompanyChange = (option) => {
        setFormData({ ...formData, id_company: option.value })
    }

    const handleDateChange = (date) => {
        setFormData({ ...formData, date: date })
    }

    function renderBodyView() {
        return <Modal.Body>
            {zipGenerationProgress !== null && <Row className="d-flex justify-content-center align-items-center p-2">
                <Col className="text-center text-warning">
                    <Langs str={`Une génération de CRA est en cours, veuillez patienter.`} />
                </Col>
            </Row>}
            <Row className="d-flex justify-content-center align-items-center p-2">
                <Col md={3}>
                    <span>
                        <Langs str="Month" />
                    </span>
                </Col>
                <Col md={9}>
                    <TransparentDatePicker
                        viewType="MONTHLY"
                        onChange={handleDateChange}
                        value={formData.date}
                        disabled={zipGenerationProgress !== null} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center p-2">
                <Col md={3}>
                    <label>
                        <Langs str="COMPANY" />
                    </label>
                </Col>
                <Col md={9}>
                    <SelectInput
                        value={formData.id_company}
                        onChange={handleCompanyChange}
                        error={formError === "id_company"}
                        errorMessage="Veuillez sélectionner une entreprise."
                        options={[{ label: "Sélectionner ...", value: null }, ...settings.companies]}
                        disabled={zipGenerationProgress !== null}
                    />
                </Col>
            </Row>
        </Modal.Body>
    }

    function renderHeaderView() {
        return <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <Langs str='Impress' />
            </Modal.Title>
        </Modal.Header>
    }

    function renderFooterView() {
        return <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
                <PrimaryButton
                    title="CANCEL"
                    active={true}
                    onClick={() => hide()} />
            </Col>
            <Col md={3}>
                <ThemeButton
                    title={zipGenerationProgress === null ? "Imprimer" : "Rafraichir"}
                    disabled={zipGenerationProgress !== null}
                    active={true}
                    onClick={() => handleImpressCra()}
                />

            </Col>
        </Modal.Footer>
    }


    return <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >

        {renderHeaderView()}
        {renderBodyView()}
        {renderFooterView()}

    </Modal>
}