import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tree } from 'primereact/tree';
import './VcompCompnentsStyle.css'
import { Col, Row } from "react-bootstrap";
import { ProgressBar } from 'primereact/progressbar';
import Langs from "../../app/lang/langs";


export default function ProgressViewCollab(props) {

    const dispatch = useDispatch()

    const isMobile = useSelector((state) => state.settings.isMobile)


    const [nodes, setNodes] = useState([])
    const [localProg, setLocalProg] = useState(0)


    useEffect(() => {
        if (props.nodes && props.nodes.length > 0) {
            if (props.nodes?.length > 0) {
                const excludedKeys = [];
                const processNode = (node) => {
                    const keyPrefix = node.scope === 'category' ? 'cat' :
                        node.scope === 'subcategory' ? 'subcat' : 'apt';
                    const key = `${keyPrefix}-${node.id}`;
                    let processedChildren = node.children ? node.children.map(processNode).filter(child => child !== undefined) : [];
                    if ((keyPrefix === 'cat' || keyPrefix === 'subcat') && processedChildren.length === 0) {
                        return undefined;
                    }
                    if (!excludedKeys.includes(key)) {
                        return {
                            key: key,
                            id: node.id,
                            label: node.nom,
                            progress: node.progress * 20,
                            coef: node.coef,
                            className: node.scope,
                            pos: node.pos,
                            visibility: node.visibility,
                            description: node.description,
                            type: node.type,
                            aspect: node.aspect ? node.aspect : null,
                            collabs_answers: node.collabs_answers ? node.collabs_answers : [],
                            collabs_no_answers: node.collabs_no_answers ? node.collabs_no_answers : [],
                            children: node.children ? node.children.map(child => processNode(child)).filter(child => child !== undefined) : []
                        };
                    }
                };

                const sdata = props.nodes.map(category =>
                    processNode(category)
                );
                const categories = filterByCollabIdAndRecalculateScores(sdata);
                const totalCoef = categories.reduce((acc, cat) => acc + (cat.coef || 1), 0);
                const weightedSum = categories.reduce((acc, cat) => acc + ((cat.progress || 0) * (cat.coef || 1)), 0);
                const averageProgress = totalCoef > 0 ? weightedSum / totalCoef : 0;
                setLocalProg(parseFloat(averageProgress).toFixed(2));
                setNodes(sdata.filter(s => s !== undefined));
            }
        } else {
            setNodes([])
            setLocalProg(0)
        }
    }, [props])


    function findNodeByKey(tree, key) {
        if (!tree) return null;
        if (!key) return null;
        for (let node of tree) {
            if (node.key === key) {
                return node;
            }
            if (node.children && node.children.length) {
                const found = findNodeByKey(node.children, key);
                if (found) return found;
            }
        }
        return null;
    }
    function filterByCollabIdAndRecalculateScores(data) {
        const dataClone = JSON.parse(JSON.stringify(data));
        function recurse(elements) {
            return elements.map(element => {
                // if (element.className === "aptitude") {
                //     const collabAnswers = element.collabs_answers?.find(ca => ca.id_collab === collabId.id);
                //     const collabNoAnswers = element.collabs_no_answers?.some(ca => ca.id === collabId.id);
                //     if (collabAnswers) {
                //         element.progress = collabAnswers.progress;
                //     } else if (collabNoAnswers) {
                //         element.progress = 0;
                //     }
                // }
                if (element && element.children && element.children.length > 0) {
                    element.children = recurse(element.children);
                    const totalCoef = element.children.reduce((acc, child) => acc + child.coef, 0);
                    const weightedSum = element.children.reduce((acc, child) => acc + (child.progress * child.coef), 0);
                    const averageProgress = totalCoef > 0 ? weightedSum / totalCoef : 0;
                    element.progress = averageProgress;
                }
                return element;
            }).filter(element => element !== null);
        }
        return recurse(dataClone);
    }


    const getColor = (num) => {
        if (num < 0 || num > 100) {
            return 'gray';
        }
        if (num < 25) {
            return 'red';
        } else if (num < 50) {
            return 'orange';
        } else if (num < 75) {
            return '#FFBF00';
        } else {
            return 'green';
        }
    }

    const nodeTemplate = (node) => {

        const valueDisplay = (value) => {
            if (value < 10) {
                return
            }
            return (
                <div className="w-100" >
                    {parseFloat(value).toFixed(1)}%
                </div>
            )
        }
        return (
            <div className="node-container row" style={{ height: 'fit-content' }}>
                <Col lg={5}>
                    <span>{node.label}</span>&nbsp;{node.aspect === 'theoretical' && <b>[T]</b>}{node.aspect === 'practical' && <b>[P]</b>}&nbsp;
                </Col>
                <Col lg={1} >
                    {node.progress < 10 &&
                        <span>{parseFloat(node.progress).toFixed(2)}%</span>
                    }
                </Col>
                <Col lg={6} className="pe-3 ps-0">
                    <Col lg={12}><ProgressBar color={getColor(node.progress)} displayValueTemplate={valueDisplay} showValue={true} value={node.progress}></ProgressBar></Col>
                </Col>
            </div>
        );
    };


    function body() {
        return (
            <div style={{ height: isMobile === 2 ? "calc(100vh - 350px)" : 'calc(100vh - 350px)', overflowY: 'auto' }}>
                <Tree
                    value={filterByCollabIdAndRecalculateScores(nodes)}
                    nodeTemplate={nodeTemplate}
                    filterPlaceholder="Search..."
                    className="w-full md:w-30rem custom-tree border-0"
                />
            </div>
        )
    }

    return (
        <div>
            <Row>
                <Col lg={12} className="px-4 my-2 d-flex justify-content-start">
                    <Langs str='Overall Progress' />: {localProg < 10 ? `${localProg}%` : ''}
                </Col>
                <Col lg={12} className="px-4 my-2">
                    <ProgressBar color={getColor(localProg)} value={localProg} showValue={localProg >= 10}></ProgressBar>
                </Col>
                <Col lg={12}>
                    {body()}
                </Col>
            </Row>
        </div>
    )
}