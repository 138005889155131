

export const config = {
    env: "PROD", // OPTIONS: "STAG" ; "PROD" ; "PREPROD"
    LogRocket: false,
    tenantUrl: "https://login.microsoftonline.com/groupevital.com",
    clientId: "c6701e56-6f31-4a76-af87-92dd0c8848a0",
    scopes: {
        collabs: "collabs",
        shifts: "shifts",
        leaves: "leaves",
        ownLeaves: "leaves.own",
        groups: "groups",
        settings: "settings",
        reports: "reports",
        warnings: "warnings",
        evaluations: "evaluations",
        vcomp: "vcomp"
    },
    permissionTypes: {
        view: "view",
        edit: "edit",
        remove: "remove"
    },
    apiPaths: {
        user: {
            login: "/user/login",
            session: "/user/session",
            logout: "/user/logout",
            msAuth: "/ms-auth",
            sendActivationLink: "/user/sendActivationLink",
            setPassword: "/user/setPassword",
        },
        planning: {
            getHolidays: "/v1/planning/getHolidays",
            getTeams: "/v1/planning/getTeams",
            addGroupe: "/v1/planning/addGroupe",
            getGroupes: "/v1/planning/getGroupes",
            updateGroupe: "/v1/planning/updateGroupe",
            deleteGroupes: "/v1/planning/deleteGroupes",
            getCollabs: "/v1/planning/getCollabs",
            getAllCollabs: "/v1/planning/getAllCollabs",
            resetCollabPassword: "/v1/planning/resetCollabPass",
            updateCollabs: "/v1/planning/updateCollabs",
            getMembers: "/v1/planning/getMembers",
            addGroupeMembers: "/v1/planning/addGroupeMembers",
            removeGroupeMembers: "/v1/planning/removeGroupeMembers",
            getSettings: "/v1/planning/getSettings",
            updateSettings: "/v1/planning/updateSettings",
            getTeamSettings: "/v1/planning/getTeamSettings",
            updateTeamSettings: "/v1/planning/updateTeamSettings",
            addShiftSample: "/v1/planning/addShiftSample",
            getShiftSamples: "/v1/planning/getShiftSamples",
            deleteShiftSamples: "/v1/planning/deleteShiftSamples",
            updateShiftSample: "/v1/planning/updateShiftSample",
            getPlanning: "/v1/planning/getPlanning",
            addShifts: "/v1/planning/addShifts",
            updateShifts: "/v1/planning/updateShifts",
            deleteShifts: "/v1/planning/deleteShifts",
            prefill: "/v1/planning/prefill",
            delete: "/v1/planning/delete",
            getClients: "/v1/planning/getClients",
            submitShifts: "/v1/planning/submitShifts",
            addLeaveSample: "/v1/planning/addLeaveSample",
            getLeaveSamples: "/v1/planning/getLeaveSamples",
            deleteLeaveSamples: "/v1/planning/deleteLeaveSamples",
            updateLeaveSample: "/v1/planning/updateLeaveSample",
            requestLeave: "/v1/planning/requestLeave",
            submitLeave: "/v1/planning/submitLeave",
            deleteLeave: "/v1/planning/deleteLeave",
            requestDeleteLeave: "/v1/planning/requestDeleteLeave",
            getTimesheets: "/v1/planning/getTimesheets",
            getCollabPlanning: "/v1/planning/getCollabPlanning",
            updateTimesheet: "/v1/planning/updateTimesheet",
            updateCollabsOrder: "/v1/planning/updateCollabsOrder",
            getReports: "/v1/planning/getReports",
            generateReport: "/v1/planning/generateReport",
            getCollabReport: "/v1/planning/getCollabReport",
            generateCraPdf: "/v1/planning/generateCraPdf",
            generateCraZip: "/v1/planning/generateCraZip",
            stopZipGeneration: "/v1/planning/stopZipGeneration",
            zipGenerationProgress : "/v1/planning/zipGenerationProgress",
            insertPrimeCollab: "/v1/planning/insertPrimeCollab",
            getPermissions: "/v1/planning/getPermissions",
            setPermissions: "/v1/planning/setPermissions",
            setTeamPermissions:"/v1/planning/setTeamPermissions",
            getTeamPermissions: "/v1/planning/getTeamPermissions",
            getAllWarnings:"/v1/planning/getWarnings",
            deleteWarning: "/v1/planning/deleteWarning",
            editWarnings: "/v1/planning/editWarnings",
            addWarning: "/v1/planning/addWarning",

            getEvaluations: "/v1/planning/getEvaluations",
            deleteEvaluation: "/v1/planning/deleteEvaluation",
            addEvaluation: "/v1/planning/addEvaluation",
            editEvaluation: "/v1/planning/editEvaluation",
            getEvaluationFields: "/v1/planning/getEvaluationFields",
            getEvaluationsReport: "/v1/planning/getEvaluationsReport",
            getMonthlyEvaluations: "/v1/planning/getMonthlyEvaluations",
            deleteEvaluationField:"/v1/planning/deleteEvaluationField",
            addEvaluationField:"/v1/planning/addEvaluationField",
            getEvaluationsTypes: "/v1/planning/getEvaluationsTypes",
            reorderEvaluationsFields: "/v1/planning/reorderEvaluationsFields",
            reorderEvaluationTypes: "/v1/planning/reorderEvaluationTypes",
            editEvaluationField: "/v1/planning/editEvaluationField",
            addEvaluationsType: "/v1/planning/addEvaluationsType",
            editEvaluationsType: "/v1/planning/editEvaluationsType",
            getCompanies: "/v1/planning/getCompanies",
            getSingleCollabDetails: "/v1/planning/getSingleCollabDetails",
        },
        vcomp:{
            addEditVcompProject: "/v1/vcomp/addEditVcompProject",
            deleteProject: "/v1/vcomp/deleteProject",
            getBankAptitudesOnCreation: "/v1/vcomp/getBankAptitudesOnCreation",
            addDefaultProjectForm: "/v1/vcomp/addDefaultProjectForm",
            reorderProjectForm: "/v1/vcomp/reorderProjectForm",
            getProjects: "/v1/vcomp/getProjects",
            addEditCategory: "/v1/vcomp/addEditCategory",
            addEditAptitude: "/v1/vcomp/addEditAptitude",
            getProjectAptitudes:"/v1/vcomp/getProjectAptitudes",
            deleteProjectCatSub:  "/v1/vcomp/deleteProjectCatSub",
            deleteProjectAptitude: "/v1/vcomp/deleteProjectAptitude",
            getProjectMembersAndManagers:"/v1/vcomp/getProjectMembersAndManagers",
            getCollabsNames: "/v1/vcomp/getCollabsNames",
            addRemoveProjectCollabsManagers: "/v1/vcomp/addRemoveProjectCollabsManagers",
            reorderPublicForm:"/v1/vcomp/reorderPublicForm",
            hidePublicCatSub:"/v1/vcomp/hidePublicCatSub",
            hidePublicAptitudes:"/v1/vcomp/hidePublicAptitudes",
            getAptitudeQuestions:"/v1/vcomp/getAptitudeQuestions",
            getProjectAptitudesWithValues:"/v1/vcomp/getProjectAptitudesWithValues",
            answerAptitudeDailyBase:"/v1/vcomp/answerAptitudeDailyBase",
            getProjectProgress: "/v1/vcomp/getProjectProgress",
            editResponseManager: "/v1/vcomp/editResponseManager",
            activateMcqCollabs: "/v1/vcomp/activateMcqCollabs",
            addEditQuestion: "/v1/vcomp/addEditQuestion",
            addEditQuestionAnswers: "/v1/vcomp/addEditQuestionAnswers",
            deleteQuestion: "/v1/vcomp/deleteQuestion",
            deleteQuestionAnswer: "/v1/vcomp/deleteQuestionAnswer",
            aptitudeMcqVisibility: "/v1/vcomp/aptitudeMcqVisibility",
            getProjectQuestions: "/v1/vcomp/getProjectQuestions",
            recordMcqResponsePerProject:"/v1/vcomp/recordMcqResponsePerProject",
            getCollabMcqResults: "/v1/vcomp/getCollabMcqResults",
            importProjectFromExcel: "/v1/vcomp/importProjectFromExcel",
            getCollabResponseHistory: "/v1/vcomp/getCollabResponseHistory",
            getProjectQuestionsBank: "/v1/vcomp/getProjectQuestionsBank",
        },
        ventr:{
            getUserDetails: "/v1/entretiens/getUserDetails",
            saveUserDetails: "/v1/entretiens/saveUserDetails",
            getInterviewsData: "/v1/entretiens/getInterviewsData",
            saveUserDetailsByManager: "/v1/entretiens/saveUserDetailsByManager",
        }
    }
}