import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from './VcompCompnents.module.css'
import { useDispatch, useSelector } from "react-redux";
import { setCollabMcqModalIdProject, setCollabMcqModalVisibility, setCollabProjectFormVisibility, setOnAnswerProject, setProjectModalVisible } from "./vcompSlice";
import noImageIcon from '../../assets/images/noImageProject.png'
import Langs from "../../app/lang/langs";
import moment from "moment/moment";
import { setOnCreateProject, setProjectMode, setCreateProjectModalVisible } from "./vcompSlice";
import { ThemeButton } from '../../components/buttons/ThemeButton'
import { Badge } from 'primereact/badge';



export default function ProjectCard(props) {

    const project = props.project;
    const manage = props.manage;
    const dispatch = useDispatch()

    const isMobile = useSelector((state) => state.settings.isMobile)

    const handleCardClick = () => {
        if (manage) {
            dispatch(setOnCreateProject({
                ...project, image_src:
                    project.imgsrc,
                start_date: moment(project.start_date, 'DD/MM/YYYY').toDate(),
                end_date: project.end_date ? moment(project.end_date, 'DD/MM/YYYY').toDate() : null,
                days_limit: project.days_limit / 7
            }));
            dispatch(setProjectMode("edit"));
            dispatch(setCreateProjectModalVisible(true));
        } else {
            if (statusReturn(project) === 'On going') {
                dispatch(setCollabProjectFormVisibility(true));
                dispatch(setOnAnswerProject(project))
            }
        }
    };

    const statusReturn = (project) => {
        const now = moment()
        const startDate = moment(project.start_date, 'DD/MM/YYYY')
        const endDate = project.end_date ? moment(project.end_date, 'DD/MM/YYYY') : null
        if(project.active !== 1){
            return 'Inactif'
        }
        if (endDate && endDate < now) {
            return 'Finalized'
        }
        if (startDate > now) {
            return 'Upcoming'
        }
        if (startDate < now) {
            return 'On going'
        }
        return 'no data'
    }

    const handleMcqModalOpen = () =>{
        if(project){
            dispatch(setCollabMcqModalVisibility(true))
            dispatch(setCollabMcqModalIdProject(project.id))
        }
    }


    return (
        <Col lg={6} xl={4} xxl={3} className="p-3">
            <div className={`card p-0 ${styles.projectCard}`}>
                <Row className="card-body">
                    <Col lg={12} className="d-flex justify-content-end">
                        {!manage && project.activate_mcq == 1 &&
                            <Badge style={{ cursor: 'pointer' }} severity="danger" value={<Langs str="TAKE_MCQ" /> } onClick={()=>{handleMcqModalOpen()}}></Badge>
                        }
                    </Col>
                    <Col lg={12} className={`border-bottom card-title text-center pb-4 ${styles.textShadow}`}>
                        {project && project.nom}
                    </Col>

                    <Col lg={6} className="py-4 d-flex justify-content-center border-bottom">
                        <img src={project.imgsrc ?? noImageIcon} className={styles.projectLogo} alt="project-icon" />
                    </Col>
                    <Col lg={6} className={isMobile == 2 ? "py-4 border-bottom row d-flex justify-content-center" : "py-4 border-bottom row"}>
                        <Col lg={12} className="p-0">
                            <p> Status: <span style={{ color: project.active === 1 ? 'green' : 'red' }}> <Langs str={statusReturn(project)} /> </span></p>
                        </Col>
                        <Col xs={12} className="p-0">
                            <ThemeButton
                                onClick={() => handleCardClick()}
                                className=''
                                title={manage ? <Langs str="Manage" /> : <Langs str="Respond" />}
                                active={true}
                                color="red"
                            />
                        </Col>
                    </Col>
                    <Col lg={12} className="row px-2 py-2">
                        <Col lg={12} className="d-flex justify-content-between">
                            <Langs str="START_DATE" /> <p>{project && project.start_date}</p>
                        </Col>
                        {project && project.end_date &&
                            <Col lg={12} className="d-flex justify-content-between">
                                <Langs str="END_DATE" /> <p>{project && project.end_date}</p>
                            </Col>
                        }
                        <Col lg={12} className="d-flex justify-content-between">
                            <Langs str="DELAY" /> <p><span style={{ color: "green" }}>{project && project.days_limit / 7}</span> semaine(s)</p>
                        </Col>
                        <Col lg={12} className="d-flex justify-content-between">
                            <Langs str="Session no" />&deg;<p><span style={{ color: "green" }}>{project && project.last_session_number + 1}</span></p>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}