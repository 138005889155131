import React, { useState, useRef, useEffect } from 'react';
import styles from './Buttons.module.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CustomColorPicker } from '../inputs/CustomColorPicker';

export function ColorPickerButton(props) {

    const [showPopover, setShowPopover] = useState(false)

    const popoverRef = useRef()
    const buttonRef = useRef()

    const handleShowPopover = () => {
        setShowPopover(!showPopover)
    }

    const handleColorChange = (color) => {
        if (props.onColorChange) {
            props.onColorChange(props.data, color)
        }
        setShowPopover(false)
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (popoverRef.current && !popoverRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setShowPopover(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [popoverRef])

    return <OverlayTrigger
        show={showPopover}
        key={'color_picker_btn'}
        overlay={
            <Popover id='popover-positioned-bottom'>
                <Popover.Body ref={popoverRef} className='text-center'>
                    <CustomColorPicker
                        color={props.color || "#000000"}
                        onChange={handleColorChange}
                    />
                </Popover.Body>
            </Popover>
        }>
        <button
            value={props.color}
            style={{ backgroundColor: props.color || "#000000" }}
            className={styles.colorPickerButton}
            ref={buttonRef}
            onClick={handleShowPopover}
            disabled={props.disabled}>
        </button>
    </OverlayTrigger>
}