import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTeams } from "./teamsSlice";
import styles from "./Teams.module.css";
import { TransparentSelect } from '../inputs/TransparentSelect';

export function TeamSelector(props) {

    const dispatch = useDispatch()
    const urlParams = useParams()

    const teams = useSelector((state) => state.teams.list)
    const myTeam = useSelector((state) => state.teams.myTeam)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)

    const handleSelectedTeamsChange = (teamsIds) => {
        dispatch(setSelectedTeams(teamsIds))
    }

    let teamsOptions = []

    if (props.onlyMyTeams) {
        return <label className={styles.teamLabel}>
            {myTeam.label}
        </label>
    }

    if (myTeam && props.includeMyTeam) {
        teamsOptions = [myTeam]
    }

    if (teams)
        teamsOptions = teamsOptions.concat(teams.filter(team => !teamsOptions.filter(t=> t.id == team.id).length))

    if (teamsOptions && teamsOptions.length == 1) {
        return <label className={styles.teamLabel}>
            {teamsOptions[0].label}
        </label>
    }

    return <TransparentSelect
        options={teamsOptions}
        value={selectedTeamsIds}
        min={1}
        max={15}
        onChange={handleSelectedTeamsChange} />
}