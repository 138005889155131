import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnEditType, setTypeModalVisibilty, addEvaluationsType, editEvaluationsType } from "../../../components/evaluations/evaluationsSlice";
import Langs from "../../../app/lang/langs";
import { Col, Row } from "react-bootstrap";
import styles from "../Settings.module.css";
import { ToggleButton } from "primereact/togglebutton";
import { MultiSelect } from "primereact/multiselect";
import './SettingsModal.css';
import { DynamicButton } from "../../../components/buttons/DynamicButton";

export default function EvaluationTypeModal(props) {

    const dispatch = useDispatch();

    const visible = useSelector((state) => state.evaluations.typeModal);
    const onEditType = useSelector((state) => state.evaluations.onEditType)
    const types = useSelector((state) => state.evaluations.typesIgnoreActive);
    const teams = useSelector((state) => state.teams.list);

    const [currentType, setCurrentType] = useState(null)
    const [title, setTitle] = useState('')
    const [active, setActive] = useState(false)
    const [associatedTeams, setAssociatedTeams] = useState([])
    const [titleClass, setTitleClass] = useState(styles.rowShadeGreen)

    const handleClose = () => {
        dispatch(setTypeModalVisibilty(false));
        setAssociatedTeams()
        setTitle('')
        setActive(false)
        setCurrentType(null)
        setTitleClass(styles.rowShadeGreen)
        dispatch(setOnEditType(null))
    }

    useEffect(() => {
        const type = types.find(item => item.id == onEditType);
        if (type) {
            setCurrentType(type)
        }
    }, [visible, onEditType, types, teams])

    useEffect(() => {
        if (currentType) {
            setTitle(currentType.title)
            setActive(currentType.active)
            if (teams) {
                setAssociatedTeams(currentType.associated_teams);
            }
        }
    }, [currentType])

    const handleAdd = () => {
        if (title == null || title.trim() == '') {
            setTitleClass(styles.rowShadeRed)
        } else {
            let addBody = {
                title: title,
                active: active,
                rank: types.length,
                added_teams: associatedTeams || []
            }
            dispatch(addEvaluationsType(addBody))
            handleClose()
        }

    }

    function analyzeTeamChanges(currentTeams, newTeams) {
        const removedTeams = currentTeams.filter(team => !newTeams.includes(team));
        const addedTeams = newTeams.filter(team => !currentTeams.includes(team));
        return [addedTeams, removedTeams];
    }

    const handleEdit = () => {
        if (title != null && title.trim() != '') {
            let teamsChange = analyzeTeamChanges(currentType.associated_teams, associatedTeams)
            let editBody = {
                id: currentType.id,
                title: title,
                active: active,
                rank: currentType.rank,
                added_teams: teamsChange[0],
                removed_teams: teamsChange[1]
            }
            dispatch(editEvaluationsType(editBody));
            handleClose()
        } else if (title == null || title.trim() == '') {
            setTitleClass(styles.rowShadeRed)
        }

    }

    //html

    const header = () => {
        if (onEditType) {
            return (
                <div>
                    <Langs str='EDIT_TYPE' />
                </div>
            )
        }
        return (
            <div>
                <Langs str='ADD_TYPE' />
            </div>
        )
    }

    const body = () => {
        return (
            <div className="p-2">
                <Row className="mt-2">
                    <Row className={`p-2 pt-3 pb-3 border-top`}>
                        <Col lg={3} className="my-auto fw-bold">
                            <Langs str='TITLE' />
                        </Col>
                        <Col lg={9} className="py-2">
                            <input
                                className={`form-control ${titleClass}`}
                                value={title}
                                rows={4}
                                onChange={(e) => { setTitle(e.target.value) }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-2 p-2 pb-3">
                        <Col lg={3} className="my-3 fw-bold">
                            <Langs str='Associated teams' />
                        </Col>
                        <Col lg={9} className="d-flex justify-content-end">
                            <MultiSelect
                                value={associatedTeams}
                                onChange={(e) => setAssociatedTeams(e.value)}
                                options={teams}
                                optionLabel="label"
                                display="chip"
                                filter
                                placeholder="All"
                                className='customMultiselect'
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2 p-2">
                        <Col lg={3} className="py-2 fw-bold">
                            <Langs str='VISIBLTY' />
                        </Col>
                        <Col lg={9} className="d-flex justify-content-start">
                            <ToggleButton
                                onLabel='Actif'
                                offLabel='Inactif'
                                onIcon="pi pi-check"
                                offIcon="pi pi-times"
                                checked={active}
                                onChange={(e) => setActive(e.value)} className="w-9rem" />
                        </Col>
                    </Row>
                </Row>
            </div>
        )
    }
    const footer = () => {
        if (onEditType) {
            return (
                <Row className="d-flex justify-content-center border-top pt-3">
                    <Col lg={3}>
                        <DynamicButton
                            onClick={() => handleEdit()}
                            className=''
                            title={<Langs str="EDIT" />}
                            active={true}
                            color="blue"
                        />
                    </Col>
                </Row>
            )
        }
        if (!onEditType) {
            return (
                <Row className="d-flex justify-content-center border-top pt-3">
                    <Col lg={3}>
                        <DynamicButton
                            onClick={() => handleAdd()}
                            className=''
                            title={<Langs str="ADD" />}
                            active={true}
                            color="green"
                        />
                    </Col>
                </Row>
            )
        }
    }


    return (
        <div>
            <Dialog
                header={header}
                footer={footer}
                visible={visible}
                style={{ width: '50vw' }}
                draggable={false}
                onHide={() => { if (!visible) return; handleClose() }}>
                {body()}
            </Dialog>
        </div>
    )
}