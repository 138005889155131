import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NetworkManager from '../../../app/managers/networkManager';
import { config } from '../../../app/utils/config';
import { generateUniqueId } from "../../../app/utils/helpers";

const initialState = {
    list: [],
    dynamicLeaves: {}
}

export function generateSampleLeave(fromHours, toHours) {
    return {
        "id": generateUniqueId(),
        "type": "",
        "color": "",
        "timeFrom": parseInt(fromHours) * 60,
        "timeTo": parseInt(toHours) * 60
    }
}

export const sampleLeavesSlice = createSlice({
    name: 'sampleLeaves',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteLeaveSamples.fulfilled, (state, action) => {

                console.log('Sample leave successfully deleted')
            })
        builder
            .addCase(addLeaveSample.fulfilled, (state, action) => {

                console.log('Sample leave successfully added')
            })
        builder
            .addCase(getLeaveSamples.fulfilled, (state, action) => {

                if (action.payload.responseData && action.payload.responseData["sampleLeaves"]) {
                    let sampleLeavesList = [], sampleDynamicLeaves = {}
                    for (const key in action.payload.responseData["sampleLeaves"]) {
                        let leave = action.payload.responseData["sampleLeaves"][key]
                        let sampleLeave = {
                            value: leave["id"],
                            label: `${leave["type"]} - ${leave["description"]}`,
                            id: leave['id'],
                            description: leave['description'],
                            roleId: leave['id_role'],
                            color: leave['color'],
                            timeFrom: leave['time_from'],
                            timeTo: leave['time_to'],
                            type: leave['type'],
                            isPaid: leave['is_paid'],
                            visibility: leave['visibility'],
                            createdAt: new Date(leave['created_at']).getTime(),
                            updatedAt: new Date(leave['updated_at']).getTime()
                        }
                        sampleLeavesList.push(sampleLeave)
                        if(sampleLeave.visibility == 1){
                            sampleDynamicLeaves[sampleLeave.type] = sampleLeave
                        }
                    }
                    state.dynamicLeaves = sampleDynamicLeaves
                    state.list = sampleLeavesList
                }
            })
        builder
            .addCase(updateLeaveSample.fulfilled, (state, action) => {

                console.log('Sample shift successfully updated')
            })
    },
});

export const addLeaveSample = createAsyncThunk(
    "planning/addLeaveSample",
    async (body) => {
        try {
            let response = await NetworkManager.getInstance().postRequest(
                config.apiPaths.planning.addLeaveSample,
                body
            );
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getLeaveSamples = createAsyncThunk(
    "planning/getLeaveSamples",
    async () => {
        try {
            let response = await NetworkManager.getInstance().getRequest(
                config.apiPaths.planning.getLeaveSamples
            );
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const deleteLeaveSamples = createAsyncThunk(
    "planning/deleteLeaveSamples",
    async (body) => {
        try {
            let response = await NetworkManager.getInstance().postRequest(
                config.apiPaths.planning.deleteLeaveSamples, body
            );
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const updateLeaveSample = createAsyncThunk(
    "planning/updateLeaveSample",
    async (body) => {
        try {
            let response = await NetworkManager.getInstance().postRequest(
                config.apiPaths.planning.updateLeaveSample, body
            );
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const {

} = sampleLeavesSlice.actions

export default sampleLeavesSlice.reducer