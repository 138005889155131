import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import './VcompCompnents.module.css';
import Langs from '../../app/lang/langs';
import { ProgressBar } from 'primereact/progressbar';
import ImageButton from "../buttons/ImageButton";
import editIcon from "../../assets/images/penGray.png";
import editIconFocus from "../../assets/images/penOrange.png";
import { Checkbox } from "primereact/checkbox";
import { editResponseManager, setProjectProgress, setProjectProgressDetails } from './vcompSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { InputNumber } from 'primereact/inputnumber';
import { FloatLabel } from 'primereact/floatlabel';

const options = [
    { label: "Rien fait", value: 0 },
    { label: "Lu/Expliqué une fois (en partie)", value: 1 },
    { label: "Lu/Expliqué une fois (totalité)", value: 2 },
    { label: "Compris/Connu en partie", value: 3 },
    { label: "Compris/Connu en totalité", value: 4 },
    { label: "Maîtrisé (Autonome)", value: 5 },
];

const getProgressValue = (progress) => {
    if (progress === 100) return 5;
    if (progress === 80) return 4;
    if (progress === 60) return 3;
    if (progress === 40) return 2;
    if (progress === 20) return 1;
    if (progress === 0) return 0;
    return 0;
};

const AptitudeModal = ({ show, handleClose, aptitude, edit, sessionNumber, maxSession, handleSessionNumberChange }) => {
    const dispatch = useDispatch();
    const progressDetails = useSelector((state) => state.vcomp.projectProgressDetails)
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCollab, setSelectedCollab] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [localAptitude, setLocalAptitude] = useState(aptitude);

    useEffect(() => {
        if (selectedCollab && selectedCollab.progress != null) {
            const initialOption = getProgressValue(selectedCollab.progress);
            setSelectedOption(initialOption);
        }
    }, [selectedCollab]);

    useEffect(()=>{setLocalAptitude(aptitude)},[aptitude])

    const handleEditClick = (collab) => {
        setSelectedCollab(collab);
        setShowEditModal(true);
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
        setSelectedCollab(null);
        setSelectedOption(null);
    };

    const handleCheckboxChange = (value) => {
        setSelectedOption(value);
    };

    const handleSave = () => {
        if (selectedCollab && selectedOption !== null) {
            dispatch(editResponseManager({
                id: selectedCollab.id_resp,
                progress: selectedOption
            }));
            updateLocalAptitude(selectedCollab.id_resp, selectedOption * 20);
            dispatch(setProjectProgressDetails(recalculateProjectProgress(progressDetails, selectedCollab.id_resp, selectedOption * 20)))
        }
        handleEditModalClose();
    };

    const updateLocalAptitude = (id_resp, newProgress) => {
        const updatedAptitude = {
            ...localAptitude,
            collabs_answers: localAptitude.collabs_answers.map(answer =>
                answer.id_resp === id_resp ? { ...answer, progress: newProgress } : answer
            )
        };
        setLocalAptitude(updatedAptitude);
    };

    function recalculateProjectProgress(data, idResp, newProgress) {
        const dataClone = JSON.parse(JSON.stringify(data));

        function recurse(elements) {
            return elements.map(element => {
                console.log(element);
                if (element.scope === "aptitude") {
                    const index = element.collabs_answers?.findIndex(ca => ca.id_resp === idResp);
                    if (index !== -1 && index !== undefined) {
                        element.collabs_answers[index] = {
                            ...element.collabs_answers[index],
                            progress: newProgress
                        };
                    }

                    const collabAnswers = element.collabs_answers;
                    const collabNoAnswers = element.collabs_no_answers.length;
                    element.progress = collabAnswers.reduce((acc, curr) => acc + curr.progress, 0) / (collabAnswers.length + collabNoAnswers || 1);
                }
                if (element.children && element.children.length > 0) {
                    element.children = recurse(element.children);
                    const totalCoef = element.children.reduce((acc, child) => acc + child.coef, 0);
                    const weightedSum = element.children.reduce((acc, child) => acc + (child.progress * child.coef), 0);
                    const averageProgress = totalCoef > 0 ? weightedSum / totalCoef : 0;
                    element.progress = averageProgress;
                }
                return element;
            });
        }
        const categories = recurse(dataClone);
        const totalCoef = categories.reduce((acc, cat) => acc + (cat.coef || 1), 0);
        const weightedSum = categories.reduce((acc, cat) => acc + ((cat.progress || 0) * (cat.coef || 1)), 0);
        const averageProgress = totalCoef > 0 ? weightedSum / totalCoef : 0;
        dispatch(setProjectProgress(parseFloat(averageProgress).toFixed(2)));
        return categories;
    }



    const getColor = (num) => {
        if (num < 0 || num > 100) {
            return 'gray';
        }
        if (num < 25) {
            return 'red';
        } else if (num < 50) {
            return 'orange';
        } else if (num < 75) {
            return '#FFBF00';
        } else {
            return 'green';
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={() => { handleClose() }}
                centered
                size="lg"
                dialogClassName="custom-zindex-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {localAptitude?.label} - <Langs str="COLLAB_PROGRESS" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(100vh - 300px)', overflowY: "auto" }}>
                    {localAptitude?.collabs_answers && localAptitude.collabs_answers.length > 0 ? (
                        <div>
                            {localAptitude.collabs_answers.map((answer, index) => (
                                <Row key={index} className="align-items-center mb-4" style={{ textAlign: 'center' }}>
                                    <Col xs={3}>
                                        <span>{answer.prenom} {answer.nom}</span>
                                    </Col>
                                    <Col xs={7}>
                                        <ProgressBar color={getColor(answer.progress)} value={answer.progress} />
                                    </Col>
                                    <Col xs={2}>
                                        {edit &&
                                            <ImageButton
                                                image={editIcon}
                                                imageFocus={editIconFocus}
                                                onClick={() => handleEditClick(answer)}
                                                active={true}
                                                width={32}
                                            />
                                        }
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    ) : (
                        <p><Langs str="EMPTY_PROGRESS" /></p>
                    )}
                    {localAptitude?.collabs_no_answers && localAptitude.collabs_no_answers.length > 0 && (
                        <div>
                            {localAptitude.collabs_no_answers.map((noAnswer, index) => (
                                <Row key={index} className="align-items-center mb-4" style={{ textAlign: 'center' }}>
                                    <Col xs={4}>
                                        {noAnswer.prenom} {noAnswer.nom}
                                    </Col>
                                    <Col xs={8}>
                                        <Langs str="NO_ANSWER" />
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between py-4'>
                        <FloatLabel>
                            <InputNumber
                                inputId="session-number"
                                value={sessionNumber}
                                onValueChange={(e) => handleSessionNumberChange(e)}
                                showButtons
                                buttonLayout="horizontal"
                                step={1}
                                min={1}
                                max={maxSession}
                                allowEmpty={false}
                                inputStyle={{ width: "6rem", textAlign: "center" }}
                                decrementButtonClassName={`p-button-warning `}
                                incrementButtonClassName="p-button-warning"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus" />
                            <label htmlFor="project-delay"><Langs str='Choose Session' /><Langs />
                            </label>
                        </FloatLabel>
                    <div style={{ width: '100px' }}>
                        <PrimaryButton
                            title={<Langs str="CLOSE" />}
                            onClick={() => { handleClose() }}
                            active={true}
                        />
                    </div>
                </Modal.Footer>
            </Modal>

            {selectedCollab && (
                <Modal show={showEditModal} onHide={handleEditModalClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Langs str="EDIT_PROGRESS" /> {selectedCollab.prenom} {selectedCollab.nom}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {options.map((option) => (
                            <div key={option.value} className="flex justify-content-center mb-1">
                                <Checkbox
                                    onChange={() => handleCheckboxChange(option.value)}
                                    checked={selectedOption === option.value}
                                />
                                <label style={{ marginLeft: '0.5rem' }}>{option.label}</label>
                            </div>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className="w-100 d-flex justify-content-between">
                            <Col xs="auto">
                                <PrimaryButton
                                    title={<Langs str="CLOSE" />}
                                    onClick={handleEditModalClose}
                                    active={true}
                                />
                            </Col>
                            <Col xs="auto">
                                <PrimaryButton
                                    title={<Langs str="SAVE" />}
                                    onClick={handleSave}
                                    active={true}
                                />
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default AptitudeModal;
