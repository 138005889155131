
import './App.css'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

//primereact theme dependencies
import 'primereact/resources/themes/lara-light-blue/theme.css';   // theme
import 'primereact/resources/primereact.css';                     // core css
import 'primeicons/primeicons.css';                               // icons

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginPage } from './containers/login/LoginPage'
import { HomePage } from './containers/home/HomePage'
import { NotFoundPage } from './containers/common/NotFoundPage'
import Loader from "./components/loaders/loader.js";
import ProgressBarComponent from "./components/loaders/progressBar/progressBar.js";
import React, { useEffect, useRef } from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { PopupModalCentered } from './components/popups/PopupModalCentered';
import { hidePopup } from './containers/common/commonSlice';
import { ToastContainer, Flip, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogRocket from 'logrocket';
import { config } from "./app/utils/config";
import { setIsMobile } from './containers/settings/settingsSlice.js';

export default function App() {

    const dispatch = useDispatch()
    
    const userToken = useSelector((state) => state.login.userToken)
    const userRoleId = useSelector((state) => state.login.userRoleId)
    const userId = useSelector((state) => state.login.userId)
    const userName = useSelector((state) => state.login.userName)
    const userEmail = useSelector((state) => state.login.userEmail)
    const isLoading = useSelector((state) => state.common.isLoading)
    const isLoadingCra = useSelector((state) => state.collabs.isLoadingCra)
    const popup = useSelector((state) => state.common.popup)
    const progress = useSelector((state) => state.collabs.zipGenerationProgress);

    const mounted = useRef(false)

    if (!localStorage.getItem("lang")) {
        let envLanguage = window.navigator.language.substring(0, 2) === "en" ? "en" : "fr"
        localStorage.setItem("lang", envLanguage)
    }
    const handleResize = () => {
        //1: web, 2: mobile, 3: tablet
        if (window.innerWidth <= 768) {
            dispatch(setIsMobile(2))
        }
        else if (window.innerWidth <= 1000) {
            dispatch(setIsMobile(3))
        } else {
            dispatch(setIsMobile(1))
        }
    }
    if (window.innerWidth <= 768) {
        dispatch(setIsMobile(2))
    }
    else if (window.innerWidth <= 1200) {
        dispatch(setIsMobile(3))
    } else {
        dispatch(setIsMobile(1))
    }

    window.addEventListener('resize', ()=>{handleResize()});

    

    let routes
    if (userToken) {
        if (config.LogRocket){
            LogRocket.identify(userId, {
                name: userName,
                email: userEmail,
                // Add your own custom user variables here, ie:
                subscriptionType: userRoleId
            })
        }
        routes =
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/:view/:selectedTeamId?/:subView?" element={<HomePage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
    }
    else
        routes =
            <Routes>
                <Route path="/*" element={<LoginPage />} />
                <Route path="/activate/:activationToken" element={<LoginPage />} />
            </Routes>

    return <div className="App">
        <ToastContainer position="top-right"
            autoClose={1500}
            closeOnClick
            pauseOnFocusLoss
            draggable
            transition={Flip}
            pauseOnHover
            theme="colored"
        />

        <Router>
            <div>
                {routes}
                {isLoading ? <Loader /> : ""}
                {isLoadingCra ? <ProgressBarComponent progress={progress} /> : ""}
                <PopupModalCentered
                    title={popup.title}
                    message={popup.message}
                    show={popup.show}
                    onHide={() => dispatch(hidePopup())}
                />

            </div>           
        </Router>
    </div>
}

