import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = [
    {
        value: 1,
        label: 'Pilot Système réseaux'
    },
    {
        value: 2,
        label: 'AdminSys'
    },
    {
        value: 3,
        label: 'Developpeur'
    }
]

export const postessSlice = createSlice({
    name: 'postes',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        
    },
    extraReducers: (builder) => {

    },
});

export const { 
} = postessSlice.actions

export default postessSlice.reducer