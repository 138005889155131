import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../../app/lang/langs";
import addUser from '../../../assets/images/add-user-grey.png';
import addUserFocused from '../../../assets/images/add-user.png';
import closeIcon from "../../../assets/images/close.png";
import closeIconFocus from "../../../assets/images/closeFocus.png";
import deleteIcon from "../../../assets/images/delete.png";
import deleteIconFocus from "../../../assets/images/delete-orange.png";
import editIcon from "../../../assets/images/edit.png";
import editIconFocus from "../../../assets/images/editFocus.png";
import { showGroupeModal } from "../../../containers/common/commonSlice";
import { setShowFormPopup } from "../../../containers/planning/planningSlice";
import ImageButton from "../../buttons/ImageButton";
import { getMembers, removeGroupeMembers, setCollabsGroupeId } from "../../collabs/collabsSlice";
import styles from "./Cards.module.css";
import { checkPermission } from "../../../app/utils/helpers";
import { config } from "../../../app/utils/config";


export function GroupeCard(props) {

  const dispatch = useDispatch()
  const postes = useSelector((state) => state.postes)
  const collabs = useSelector((state) => state.collabs.collaboratorsList)
  const teams = useSelector((state) => state.teams)

  let groupeMembers = collabs.filter(collab => collab.groupeIds.includes(props.data.value))
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)


  const showGroupeDetails = () => {
    dispatch(showGroupeModal({ type: "EDIT_GROUPE", data: props.data }))
  }

  const showDeletePopup = () => {
    if (props.onDeleteGroup) {
      props.onDeleteGroup(props.data)
    }
  }

  function generateTableHeader() {
    return (
      <thead>
        <tr>
          <th width={"10%"}>#</th>
          <th width={"10%"}>
            <Langs str="NAME" />
          </th>
          <th width={"10%"}>
            <Langs str="FAMILY_NAME" />
          </th>
          <th width={"35%"}>
            <Langs str="POSTE" />
          </th>
          <th width={"25%"}>
            <Langs str="EMAIL" />
          </th>
          <th width={"10%"}>

          </th>
        </tr>
      </thead>
    )
  }

  function handleAddUser() {
    dispatch(setCollabsGroupeId(props.data.id))
    dispatch(setShowFormPopup({ type: "ADD_USER" }))
  }

  function handleRemoveMember(collab) {
    dispatch(
      removeGroupeMembers({
        id_groupe: props.data.id,
        id_team: collab.id_team,
        collabsIds: [collab.id],
      })
    ).then((response) => {
      dispatch(getMembers({ teamsIds: selectedTeamsIds }))
    })
  }

  function generateTableBody() {
    return (
      <tbody>
        {groupeMembers != null
          ? groupeMembers.map((collab, index) => (
            <tr style={{ backgroundColor: props.data.color + (index % 2 ? "10" : "20") }}
              key={`GROUPE_ITEM_${index}`}>
              <td width={"10%"}>{index + 1}</td>
              <td width={"10%"}>{collab.prenom}</td>
              <td width={"10%"}>{collab.nom}</td>
              <td width={"35%"}>{collab.poste}</td>
              <td width={"35%"}>{collab.email}</td>
              <td>
                {checkPermission(config.scopes.groups, config.permissionTypes.edit, collab.id_team) ? (
                  <ImageButton
                    image={closeIcon}
                    imageFocus={closeIconFocus}
                    onClick={() => {
                      if (checkPermission(config.scopes.groups, config.permissionTypes.edit, collab.id_team)) {
                        handleRemoveMember(collab);
                      }
                    }}
                    active={true}
                    width={16}
                  />
                ) : null}
              </td>
            </tr>
          ))
          : null}
      </tbody>
    );
    
  }

  function getPosteFromId(posteId) {
    let posteList = postes.filter((poste) => poste.value === posteId)
    if (posteList.length) return posteList[0]["label"]
    else return ""
  }

  function getTeamLabelFromId(teamId) {
    let label = ""
    let teamsFiltered = teams.list && teams.list.filter(t => t.id == teamId)
    if (teamsFiltered && teamsFiltered[0]) {
      label = teamsFiltered[0].label
    }
    return label
  }

  return (
    <Card className={styles.groupeCard} key={props.data.value}>
      <Card.Header className={styles.cardHeader} style={{ backgroundColor: props.data.color + "25" }}>
        <Card.Title>
          <Row>
            <Col sm={6}>
              <label style={{ color: props.data.color }}>{props.data.label}</label>
              <span>{getTeamLabelFromId(props.data.id_team)}</span>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
            {checkPermission(config.scopes.groups, config.permissionTypes.edit, props.data.id_team) ? (
              <div>
                <ImageButton
                onClick={() => handleAddUser()}
                image={addUser}
                imageFocus={addUserFocused}
                active={true}
                width={22} />
              <ImageButton
                onClick={showGroupeDetails}
                image={editIcon}
                imageFocus={editIconFocus}
                active={true}
                width={22}
              />
              <ImageButton
                image={deleteIcon}
                imageFocus={deleteIconFocus}
                onClick={showDeletePopup}
                active={true}
                width={22}
              />
              </div>) : null }
            </Col>
          </Row>
        </Card.Title>
      </Card.Header>
      <Card.Body className={styles.cardBody}>
        <Table striped hover>
          {generateTableHeader()}
          {generateTableBody()}
        </Table>
      </Card.Body>
    </Card>
  )
}
