import React, { useState, useEffect } from 'react';
import { setResetPasswordModal, resetPasswordCollab, setResetPasswordResponse, setWarningDetailsDialog, setWarningData, editWarnings, setWarningUpdateBody } from "../../../components/collabs/collabsSlice";
import { useDispatch, useSelector } from "react-redux";
import Langs from '../../../app/lang/langs';
import { checkPermission, getFormattedDate, getVarFormattedDate } from '../../../app/utils/helpers';
import { Dialog } from 'primereact/dialog';
import { DangerButton } from '../../../components/buttons/DangerButton';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { Localize } from '../../../app/lang/langs';
import { config } from '../../../app/utils/config';
import { showSuccessToast, showErrorToast, showInfoToast } from '../../../app/utils/helpers';
import moment from 'moment';
import styles from '../../collabs/CollabsView.module.css'
import { Tag } from 'primereact/tag';
import { SelectInputRefresh } from '../../../components/inputs/SelectRefresh';
import { DynamicButton } from '../../../components/buttons/DynamicButton';

export default function WarningDetailsDialog() {

  const dispatch = useDispatch()

  const visible = useSelector((state) => state.collabs.warningDialog)
  const warningData = useSelector(state => state.collabs.warningData);

  const [tempWarningDate, setTempWarningDate] = useState(null)
  const [tempStatus, setTempStatus] = useState()
  const [tempComments, setTempComments] = useState()
  const [updateBody, setUpdateBody] = useState({})

  useEffect(() => {
    if (visible) {
      if (warningData.warning_date) {
        setTempWarningDate(getVarFormattedDate(warningData.warning_date, "yyyy-mm-dd"));
      }
      if (warningData.status) {
        setTempStatus(warningData.status);
      }
      if (warningData.status) {
        setTempComments(warningData.comments)
      }
      setUpdateBody(current => ({ ...current, warning_id: warningData.id }));
    }
  }, [warningData, visible]);


  function handleWarningModalClose() {
    dispatch(setWarningDetailsDialog(false));
    setUpdateBody({});
  }

  //handeling date change
  const handleDateChange = (e) => {
    e.preventDefault()
    let date = e.target.value
    setTempWarningDate(date)
  }


  useEffect(() => {
    let originalDate = warningData ? getVarFormattedDate(warningData.warning_date, "yyyy-mm-dd") : "Error has occured";
    if (originalDate !== tempWarningDate) {
      if (tempWarningDate) {
        setUpdateBody(current => ({ ...current, warning_date: tempWarningDate }))
      }
    } else if (originalDate === tempWarningDate) {
      if (tempWarningDate) {
        setUpdateBody(current => {
          const newState = { ...current };
          delete newState.warning_date;
          return newState;
        });
      }
    }
  }, [tempWarningDate])

  //handeling status change
  const handleStatusChange = (e) => {
    setTempStatus(e.value)
  }

  useEffect(() => {
    let originalStatus = warningData ? warningData.status : "Error has occured";
    if (originalStatus !== tempStatus) {
      if (tempStatus) {
        setUpdateBody(current => ({ ...current, status: tempStatus }))
      }
    } else if (originalStatus === tempStatus) {
      if (tempStatus) {
        setUpdateBody(current => {
          const newState = { ...current };
          delete newState.status;
          return newState;
        });
      }
    }
  }, [tempStatus])
  // handeling comments change

  const handleCommentsChange = event => {
    setTempComments(event.target.value)
  }
  useEffect(() => {
    let originalComments = warningData ? warningData.comments : "Error has occured";
    if (originalComments !== tempComments) {

      setUpdateBody(current => ({ ...current, comments: tempComments }))

    } else if (originalComments === tempComments) {
      setUpdateBody(current => {
        const newState = { ...current };
        delete newState.comments;
        return newState;
      });

    }
  }, [tempComments])
  useEffect(() => {
    console.log(updateBody)
  }, [updateBody])
  const options = [
    { label: <Langs str="received" />, value: "received", color: "#FFD11A" },
    { label: <Langs str="signed" />, value: "signed", color: "blue" },
    { label: <Langs str="sent" />, value: "sent", color: "green" }
  ];

  const handleSave = () => {
    if (Object.keys(updateBody).length > 1) {
      dispatch(editWarnings(updateBody));
      dispatch(setWarningUpdateBody(updateBody))
      handleWarningModalClose();
    }
  }

  const categoryBodyTemplate = (category) => {
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      let capitalized = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
      return <Langs str={capitalized} />
    }
    let severity
    if (category === 'warning') {
      severity = 'warning';
    } else if (category === 'recall the order') {
      severity = 'danger';
    } else if (category === 'formal notice') {
      severity = 'info';
    }
    return (
      <Tag severity={severity} value={capitalize(category)} />
    )
  }

  const warningStatusBodyTemplate = (status) => {
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      let capitalized = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return <Langs str={capitalized} />
    };

    let color = '';
    switch (status.toLowerCase()) {
      case 'sent':
        color = "green";
        break;
      case 'received':
        color = "#FFD11A";
        break;
      case 'signed':
        color = "blue";
        break;
      default:
        color = "black";
    }
    return (
      <div style={{ color: color }}>
        {capitalize(status)}
      </div>
    )
  };
  const commentsBodyTemplate = (comments) => {
    const commentsArray = comments.split('\n');

    return (
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        {commentsArray.map((comment, index) => (
          comment.trim() !== "" && (
            <li key={index} style={{
              position: 'relative',
              paddingLeft: '20px',
              wordWrap: 'break-word',
              maxWidth: '35ch',
              marginBottom: '8px'
            }}>
              <span style={{
                position: 'absolute',
                left: '0',
                top: '0'
              }}>•</span>
              {comment}
            </li>
          )
        ))}
      </ul>
    );
  }


  const formatDate = (timestamp) => {
    if (!timestamp) return '-';
    const date = new Date(timestamp);
    date.setHours(date.getHours() + 2);
    const isoString = date.toISOString().substring(0, 16);
    const rearranged = isoString.substring(8, 10) + '-' + isoString.substring(5, 7) + '-' + isoString.substring(0, 4) + ' ' + isoString.substring(11, 16);
    return rearranged;
  };


  function renderBody() {
    if (visible) {
      const renderKeyValue = (key, value) => {
        if (['updated_at', 'created_at', 'warning_date'].includes(key)) {
          if (checkPermission(config.scopes.warnings, config.permissionTypes.edit)) {
            if (key === "warning_date") {
              return (
                <input
                  type="date"
                  className="form-control"
                  value={getVarFormattedDate(tempWarningDate, "yyyy-mm-dd")}
                  onChange={handleDateChange}
                />
              );
            }
          }
          if (['updated_at', 'created_at'].includes(key)) {
            return formatDate(value)
          }
          return getFormattedDate(value);
        }
        if (key === 'category') {
          return categoryBodyTemplate(value);
        }
        if (key === 'comments') {
          if (checkPermission(config.scopes.warnings, config.permissionTypes.edit)) {
            return (
              <textarea
                name="description"
                maxLength={200}
                style={{ minHeight: '8rem' }}
                value={tempComments || ""}
                onChange={handleCommentsChange}
              />
            )
          }
          return commentsBodyTemplate(value)
        }
        if (key === 'status') {
          if (checkPermission(config.scopes.warnings, config.permissionTypes.edit)) {
            return (
              <SelectInputRefresh
                values={options.find(option => option.value === tempStatus)}
                options={options}
                onChange={handleStatusChange}
              />
            );
          }
          return warningStatusBodyTemplate(value);
        }
        if (typeof value === 'object' && value !== null) {
          return `${value.prenom} ${value.nom}`;
        }
        return value;
      };
      return (
        <div>
          <div className='container'>
            {Object.entries(warningData).filter(([key, value]) =>
              !['id', 'id_collab', 'collab_nom', 'collab_prenom'].includes(key)
            ).map(([key, value]) => {
              if (key !== 'color')
                return <div className='row py-3 border-top'>
                  <div className='col-4 my-auto'>
                    <Langs str={key} /></div>
                  <div className='col-6'>
                    {renderKeyValue(key, value)}
                  </div>
                </div>
            }
            )}
          </div>
        </div>
      );
    }
  }

  const collabFullName = warningData?.collab_nom && warningData?.collab_prenom ?
    <i>{warningData.collab_nom} {warningData.collab_prenom}</i> : null;

  const headerElement = (
    <div>
      <p style={{ color: 'grey' }}>{<Langs str="WARNING_DETAILS_FOR" />}</p>
      <i>{collabFullName}</i>
    </div>
  )
  function renderSaveBtn() {
    if (checkPermission(config.scopes.warnings, config.permissionTypes.edit) && Object.keys(updateBody).length > 1) {
      return (
        <DynamicButton
          onClick={() => handleSave()}
          title={<Langs str="SAVE" />}
          active={true}
          color={"green"}
        />
      );
    }
  }
  const footerContent = (
    <div className='row'>
      <div className='col-12 row d-flex justify-content-between'>
        <div className='col-3'>
          <PrimaryButton
            onClick={() => { handleWarningModalClose() }}
            title={<Langs str="CLOSE" />}
            active={true}
          />
        </div>
        <div className='col-3'>
          {renderSaveBtn()}
        </div>
      </div>
    </div>
  )

  return (
    <div className="card flex justify-content-center">
      <Dialog
        visible={visible}
        draggable={false}
        position='right'
        modal
        resizable={false}
        header={headerElement}
        footer={footerContent}
        style={{ width: '45rem', height: "100vh", maxHeight: "100%", marginRight: 0 }}
        onHide={() => handleWarningModalClose()}>
        {renderBody()}
      </Dialog>
    </div>
  )
}