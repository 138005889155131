import React, { useState, useEffect, useRef } from 'react'
import {
    setAddWarningDialog,
    addWarning, setAddedWarningBody
} from "../../../components/collabs/collabsSlice"
import { useDispatch, useSelector } from "react-redux"
import Langs from '../../../app/lang/langs'
import { checkPermission } from '../../../app/utils/helpers'
import { Dialog } from 'primereact/dialog'
import { Localize } from '../../../app/lang/langs'
import { config } from '../../../app/utils/config'
import moment from 'moment'
import styles from '../../collabs/CollabsView.module.css'
import Select from 'react-select'
import { selectInputStyles, selectInputErrorStyles } from '../../../components/inputs/inputStyles'
import { Col, Container, Row } from 'react-bootstrap'
import { DynamicButton } from '../../../components/buttons/DynamicButton'

export default function AddWarning() {

    const dispatch = useDispatch()

    const visible = useSelector((state) => state.collabs.addWarningDialog)
    const collabs = useSelector((state) => state.collabs.allCollabsList)
    const editorId = useSelector((state) => state.login.userId)
    const warningCatgories = useSelector((state) => state.collabs.warningCatgories)
    const mounted = useRef(null)

    const [collab, setCollab] = useState(null)
    const [category, setCategory] = useState(null)
    const [status, setStatus] = useState(null)
    const [warningDate, setWarningDate] = useState('')
    const [comments, setComments] = useState(null)

    const [addBody, setAddbody] = useState({})
    const [addedWarning, setAddedWarning] = useState(null)

    const [collabInputStyle, setCollabInputStyle] = useState(selectInputStyles)
    const [dateInputStyle, setDateInputStyle] = useState({ borderRadius: "40px" })
    const [messageStyle, setMessageStyle] = useState({ color: "red", visibility: "hidden" })

    useEffect(() => {
        if (!mounted.current) {
            if (checkPermission(config.scopes.warnings, config.permissionTypes.edit)) {

            }
            mounted.current = true
        }
    }, [])

    const handleClose = () => {
        dispatch(setAddWarningDialog(false))
        setCollab(null)
        setStatus(null)
        setCategory(null)
        setComments(null)
        setWarningDate('')
        setAddbody({})
        setCollabInputStyle(selectInputStyles)
        setDateInputStyle({ borderRadius: "40px" })
        setMessageStyle({ color: "red", visibility: "hidden" })
    }

    const clearInput = () => {
        setCollab(null)
        setStatus(null)
        setCategory(null)
        setWarningDate('')
        setAddbody({});
        setCollabInputStyle(selectInputStyles)
        setDateInputStyle({ borderRadius: "40px" })
        setMessageStyle({ color: "red", visibility: "hidden" })
    };


    const handleNameChange = selectedOption => {
        setAddbody(prevState => ({
            ...prevState,
            id_collab: selectedOption.value
        }));
        setCollab(selectedOption);
    };
    const handleCategoryChange = selectedOption => {
        setAddbody(prevState => ({
            ...prevState,
            category: selectedOption.value
        }));
        setCategory(selectedOption);
    };
    const handleStatusChange = selectedOption => {
        setAddbody(prevState => ({
            ...prevState,
            status: selectedOption.value
        }));
        setStatus(selectedOption);
    };
    const handleCommentsChange = event => {
        setAddbody(prevState => ({
            ...prevState,
            comments: event.target.value
        }));
        setComments(event.target.value)
    }
    const handleWarningDateChange = selectedOption => {
        if (!selectedOption.target.value) {
            setAddbody(prevState => {
                const newState = { ...prevState };
                delete newState.warning_date;
                return newState;
            });
            setWarningDate('')
        } else {
            setWarningDate(selectedOption.target.value);
            setAddbody(prevState => ({
                ...prevState,
                warning_date: selectedOption.target.value
            }));
        }
    };
    // useEffect(()=>{
    //     console.log(addBody)
    // },[addBody])
    const collabNameSearch = (collabs) => {
        return collabs.filter(collab => {
            if (collab.entryDate)
                return {
                    value: collab.id,
                    label: `${collab.nom} ${collab.prenom}`
                }
        })
    }

    useEffect(() => {
        if (addedWarning != null) {
            dispatch(setAddedWarningBody(addedWarning));
        }
    }, [addedWarning])

    const handleAddWarning = () => {
        setCollabInputStyle(selectInputStyles);
        setDateInputStyle({ borderRadius: "40px" });
        setMessageStyle({ color: "red", visibility: "hidden" })

        if (collab === null) {
            setCollabInputStyle(selectInputErrorStyles);
            setMessageStyle({ color: "red" })
        }
        if (warningDate === null || warningDate === '') {
            setDateInputStyle({ borderRadius: "40px", borderColor: "red" });
            setMessageStyle({ color: "red" })
        }
        if (addBody.id_collab && addBody.warning_date) {
            if (checkPermission(config.scopes.warnings, config.permissionTypes.edit)) {
                let createdBy = collabs.find(collab => collab.id == editorId)
                let collabObj = collabs.find(collabe => collabe.id == collab.value)
                let matricule = collabObj?.matricule ?? null
                let collabNom = collabObj?.nom ?? null
                let collabPrenom = collabObj?.prenom ?? null
                let commentsAd = comments ? comments : ""
                let email = collabObj?.email ?? null
                let poste = collabObj?.poste ?? null
                let teamName = collabObj?.team ?? null
                let warningDateTS = new Date(warningDate).getTime()
                let createdAt = new Date().getTime()
                let statusAd = status ? status.value : "sent"
                let categoryAd = category ? category.value : "warning"
                setAddedWarning({
                    id_collab: collab.value,
                    category: categoryAd,
                    status: statusAd,
                    comments: commentsAd,
                    warning_date: warningDateTS,
                    created_at: createdAt,
                    updated_at: createdAt,
                    created_by: createdBy,
                    updated_by: {
                        id: null,
                        nom: null,
                        prenom: null
                    },
                    collab_nom: collabNom,
                    collab_prenom: collabPrenom,
                    collab_matricule: matricule,
                    collab_email: email,
                    poste: poste,
                    team_name: teamName
                })
                dispatch(addWarning(addBody))
                handleClose()
            }
        }
    }

    //html
    const statusOptions = [
        { label: <Langs str="received" />, value: "received", color: "#FFD11A" },
        { label: <Langs str="signed" />, value: "signed", color: "blue" },
        { label: <Langs str="sent" />, value: "sent", color: "green" }
    ]

    const categoryOptions = warningCatgories.map(cat=>{
        return {label: <Langs str={cat.value} />, ...cat}
    })

    function renderBody() {
        return (
            <Container>
                <Row className='my-4'>
                    <Col lg={4} className='my-auto'>
                        Collab
                    </Col>
                    <Col lg={8}>
                        <Select
                            value={collab}
                            onChange={handleNameChange}
                            options={collabNameSearch(collabs)}
                            styles={collabInputStyle}
                        />
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg={4} className='my-auto'>
                        <Langs str="category" />
                    </Col>
                    <Col lg={8}>
                        <Select
                            value={category}
                            onChange={handleCategoryChange}
                            options={categoryOptions}
                            styles={selectInputStyles}
                        />
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg={4} className='my-auto'>
                        <Langs str="status" />
                    </Col>
                    <Col lg={8}>
                        <Select
                            value={status}
                            onChange={handleStatusChange}
                            options={statusOptions}
                            styles={selectInputStyles}
                        />
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg={4} className='my-auto'>
                        <Langs str="warning_date" />
                    </Col>
                    <Col lg={8}>
                        <input
                            type="date"
                            style={dateInputStyle}
                            className="form-control"
                            value={warningDate}
                            onChange={handleWarningDateChange}
                            id="warning-date-picker"
                        />
                    </Col>
                </Row>
                <Row className="my-4 align-items-center">
                    <Col md={4}>
                        <label>
                            <Langs str="DESCRIPTION" />
                        </label>
                    </Col>
                    <Col md={8}>
                        <textarea
                            name="description"
                            maxLength={200}
                            style={{ minHeight: '6rem' }}
                            value={comments || ""}
                            onChange={handleCommentsChange}
                        />
                    </Col>
                </Row>
                <Row
                    style={messageStyle}
                ><Langs str="MANDATORY_FIELDS" /></Row>
            </Container>
        );
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <Langs str="ADD_WARNING" />
        </div>
    )

    const footerContent = (
        <Row  className='d-flex justify-content-around'>
            <Col lg={3} className='mb-2'>
                <DynamicButton
                    onClick={() => clearInput()}
                    className=''
                    title={<Langs str="CLEAR_INPUT" />}
                    active={true}
                    color="blue"
                />
            </Col>
            <Col lg={3} className='mb-2'>
                <DynamicButton
                    onClick={() => handleAddWarning()}
                    className=''
                    title={<Langs str="ADD_WARNING" />}
                    active={true}
                    color="red"
                />
            </Col>
        </Row>
    )

    return (
        <div className="card flex justify-content-center">
            <Dialog
                visible={visible}
                draggable={false}
                position='center'
                modal
                resizable={false}
                header={headerElement}
                footer={footerContent}
                style={{ width: '40rem', height: "fit-content" }}
                onHide={() => handleClose()}
            >
                {renderBody()}
            </Dialog>
        </div>
    )
}