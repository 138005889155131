import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import { formatDate, showErrorToast, showSuccessToast, getDayPeriodFromDate, getLastUpdatedFromDate } from "../../app/utils/helpers";

const initialState = {
  list: [],
  leaveModalType: false,
  selectedLeaveData: false,
  selectedLeaveFormData: {},
  formattedList: []
}

export const leavesSlice = createSlice({
  name: "leaves",
  initialState,
  reducers: {
    showLeaveModal: (state, action) => {
      state.selectedLeaveData = action.payload.data
      state.leaveModalType = action.payload.type
      if (action.payload.data)
        state.selectedLeaveFormData = action.payload.data;
    },
    hideLeaveModal: (state, action) => {
      state.leaveModalType = false
      state.selectedLeaveData = false
    },
    setSelectedLeaveData: (state, action) => {
      state.selectedLeaveData = action.payload;
    },
    setLeaveStatus: (state, action) => {
      let leaveId = action.payload.id
      for (const key in state.list) {
        if (state.list[key].id == leaveId) {
          state.list[key].status = action.payload.status
          if (action.payload.status == "VALIDATED") {
            state.list[key].validatedAt = getLastUpdatedFromDate(new Date().getTime())
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaves.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData["planning"]) {
        const { planning } = action.payload.responseData;

        const createPlanningObject = (planningItem, useFormattedDates = false) => ({
          value: planningItem.id,
          label: planningItem["task"],
          id: planningItem["id"],
          id_silae: planningItem["id_silae"],
          nom: planningItem["nom"] ? planningItem["nom"].trim() : '',
          prenom: planningItem["prenom"] ? planningItem["prenom"].trim() : '',
          email: planningItem["email"],
          company: planningItem["company"],
          id_sample_shift: planningItem["id_sample_shift"],
          id_sample_leave: planningItem["id_sample_leave"],
          id_collab: planningItem["id_collab"],
          id_groupe: planningItem['id_groupe'],
          clientId: planningItem["id_client"],
          id_team: planningItem["id_team"],
          type: planningItem["type"],
          validatedBy: planningItem["validated_by"],
          task: planningItem["task"],
          description: planningItem["description"],
          from: useFormattedDates ? formatDate(new Date(planningItem["from"]), "DD/MM/YYYY") + " " + getDayPeriodFromDate(new Date(planningItem["from"])) : new Date(planningItem['from']).getTime(),
          to: useFormattedDates ? formatDate(new Date(planningItem["to"]), "DD/MM/YYYY") + " " + getDayPeriodFromDate(new Date(planningItem["to"])) : new Date(planningItem['to']).getTime(),
          duration: `${planningItem["duration"]} j`,
          status: planningItem["status"],
          createdAt: new Date(planningItem['created_at']).getTime(),
          updatedAt: new Date(planningItem['updated_at']).getTime(),
          validatedAt: planningItem['validated_at'] ? new Date(planningItem['validated_at']).getTime() : null,
          createdBy: planningItem["createdBy"],
          updatedBy: planningItem["updatedBy"],
          validatedBy: planningItem["validatedBy"],
        });

        let planningList = Object.keys(planning).map(key => createPlanningObject(planning[key], true));
        let planningList2 = Object.keys(planning).map(key => createPlanningObject(planning[key]));

        state.list = planningList;
        state.formattedList = planningList2;
      }
    }).addCase(requestLeave.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        if (action.payload.responseData.status === 1)
          showSuccessToast(action.payload.responseData.message)
        else if (showErrorToast(action.payload.responseData.message)) {
          showErrorToast(action.payload.responseData.message)
        }
      }
    })
      .addCase(requestLeave.rejected, (state, action) => {

      })
      .addCase(submitLeave.fulfilled, (state, action) => {

      })
      .addCase(requestDeleteLeave.fulfilled, (state, action) => {

      })
  },
})

export const getLeaves = createAsyncThunk(
  "planning/getLeaves",
  async (payload) => {
    try {
      let body = {
        teamsIds: payload.selectedTeamsIds,
        collabsIds: payload.selectedCollabsIds,
        type: "leave"
      }
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getPlanning,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const requestLeave = createAsyncThunk(
  "planning/requestLeave",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.requestLeave,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const submitLeave = createAsyncThunk(
  "planning/submitLeave",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.submitLeave,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)


export const deleteLeave = createAsyncThunk(
  "planning/deleteLeave",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.deleteLeave,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const requestDeleteLeave = createAsyncThunk(
  "planning/requestDeleteLeave",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.requestDeleteLeave,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const { showLeaveModal,
  hideLeaveModal,
  setSelectedLeaveData,
  setLeaveStatus
} = leavesSlice.actions;

export default leavesSlice.reducer;
