import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import {
  formatDate,
  checkPermission,
} from "../../app/utils/helpers";
import { StateButton } from "../../components/buttons/StateButton";
import { TransparentDatePicker } from "../../components/buttons/TransparentDatePicker";
import { CollabModal } from "../../components/collabs/CollabModal";
import { AddSelect } from "../../components/inputs/AddSelect";
import { ExportFormModal } from "../../components/scheduler/forms/ExportFormModal";
import { ShiftFormModal } from "../../components/scheduler/forms/ShiftFormModal";
import { GroupesRow } from "../../components/scheduler/rows/GroupesRow";
import { SamplesRow } from "../../components/scheduler/rows/SamplesRow";
import { Scheduler } from "../../components/scheduler/Scheduler";
import { addShifts, deleteShiftLocally, deleteShifts } from "../../components/scheduler/shifts/shiftsSlice";
import styles from "./Planning.module.css";
import { clearSelectedShifts, getPlanning, setSelectedDate, setSelectedGroupe } from "./planningSlice";
import PublishButton from "../../components/buttons/PublishButton";
import { showErrorToast, showSuccessToast } from "../../app/utils/helpers";
import {
  fillPlanningList,
  hideFormPopup,
  setSelectedView

} from "./planningSlice";
import { PrefillModal } from "./prefillModal";
import { hideLeaveModal } from "../leaves/leavesSlice";
import { LeaveFormModal } from "../leaves/LeaveFormModal";
import { PublishModal } from "./publishModal";
import ExcelManager from "../../app/managers/excelManager";
import DeleteButton from "../../components/buttons/DeleteButton";
import { ConfirmDeleteModal } from "../../components/popups/ConfirmDeleteModal";
import { TeamSelector } from "../../components/teams/TeamSelector";
import { getCollabs, getMembers } from "../../components/collabs/collabsSlice";
import { getGroupes } from "../groupes/groupesSlice";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { DuplicateFormModal } from "../../components/scheduler/forms/DuplicateFormModal";
import { DeleteFormModal } from "../../components/scheduler/forms/DeleteFormModal";
import { ImpressCraModal } from "../../components/scheduler/forms/ImpressCraModal";
import moment from "moment";
import { config } from "../../app/utils/config";
import AddEvaluationModal from "../evaluations/modals/AddEvaluationModal";
import { setAddResponse, setEditResponse } from "../../components/evaluations/evaluationsSlice";


export function PlanningView() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const urlParams = useParams()

  const settings = useSelector((state) => state.settings)
  const showFormPopup = useSelector((state) => state.planning.showFormPopup)
  const selectedForm = useSelector((state) => state.planning.selectedForm)

  const selectedView = useSelector((state) => state.planning.selectedView)
  const shifts = useSelector((state) => state.shifts)
  const shiftsList = useSelector((state) => state.shifts.shiftsList)
  const selectedShifts = useSelector((state) => state.planning.selectedShifts)
  const selectedDate = useSelector((state) => state.planning.selectedDate)
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const planningList = useSelector(state => state.planning.planningList)
  const selectedSamplesView = useSelector(
    (state) => state.planning.selectedSamplesView
  )

  const [showExportPopup, setShowExportPopup] = useState(false)
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false)
  const [showDeletePlanningPopup, setShowDeletePlanningPopup] = useState(false)
  const [showImpressCra, setShowImpressCra] = useState(false)
  const [show, setShow] = useState(false)
  const [visible, setVisible] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const handleHide = () => setVisible(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const leaveModalType = useSelector((state) => state.leaves.leaveModalType);
  const selectedLeaveData = useSelector((state) => state.leaves.selectedLeaveData);
  const selectedSchedulerView = useSelector((state) => state.planning.selectedView)
  const collabs = useSelector((state) => state.collabs.list);
  const myTeam = useSelector((state) => state.teams.myTeam);
  const addEvaluationRes = useSelector((state) => state.evaluations.addResponse)
  const editEvaluationRes = useSelector((state) => (state.evaluations.editResponse));
  const [year, setYear] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = useSelector((state) => state.settings.isMobile)

  let planningMenuItems = [{
    label: <Langs str="Options" />,
    items: [
      {
        label: <Langs str="Duplicate" />,
        icon: 'pi pi-clone',
        command: () => setShowDuplicatePopup(true),
      },
      {
        label: <Langs str="Delete" />,
        icon: 'pi pi-trash',
        command: () => setShowDeletePlanningPopup(true),
      },
      {
        label: <Langs str="Export" />,
        icon: 'pi pi-upload',
        command: () => setShowExportPopup(true),
      }
    ]
  }]

  if (userRoleId <= 1) {
    planningMenuItems[0].items.push({
      label: <Langs str="Impress" />,
      icon: 'pi pi-share-alt',
      command: () => setShowImpressCra(true)
    })
  }
  const menuRight = useRef(null)
  const toast = useRef(null)
  const mounted = useRef(null)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }
  })

  useEffect(() => {
    if (addEvaluationRes != null) {
      if (addEvaluationRes.trim().toLowerCase().includes("successfully")) {
        showSuccessToast("Success!")
      } else if (addEvaluationRes.trim().toLowerCase().includes("duplicate")) {
        showErrorToast(<Langs str="DUPICATE_WARNING" />)
      } else {
        showErrorToast(addEvaluationRes)
      }
      dispatch(setAddResponse(null))
    }
  }, [addEvaluationRes])
  useEffect(() => {
    if (editEvaluationRes != null) {
      if (editEvaluationRes.trim().toLowerCase().includes("successfully")) {
        showSuccessToast("Success!")
      } else if (editEvaluationRes.trim().toLowerCase().includes("duplicate")) {
        showErrorToast(<Langs str="DUPICATE_WARNING" />)
      } else {
        showErrorToast(editEvaluationRes)
      }
      dispatch(setEditResponse(null))
    }
  }, [editEvaluationRes])

  useEffect(() => {
    if (mounted.current) {
      if (selectedTeamsIds && selectedTeamsIds.length) {
        dispatch(getCollabs({selectedTeamsIds}))
        dispatch(getGroupes(selectedTeamsIds))
        dispatch(getMembers({ teamsIds: selectedTeamsIds }))
        dispatch(setSelectedGroupe(0))
      }
    }
  }, [selectedDate, selectedSchedulerView, selectedTeamsIds])

  useEffect(() => {
    if (mounted.current) {
        loadPlanning()
    }
  }, [collabs])

  useEffect(() => {
    const date = new Date(selectedDate);
    const newYear = date.getFullYear();
    if (newYear != year) {
      setYear(newYear);
    }
  }, [selectedDate])

  useEffect(() => {
    if (urlParams.subView && urlParams.subView.length >= 14) {
      let currentView = urlParams.subView.toUpperCase().includes("WEEKLY") ? "WEEKLY" : "MONTHLY"
      dispatch(setSelectedView(currentView))
      dispatch(setSelectedDate(parseInt(moment(urlParams.subView.replace(currentView, ""), `DDMMYYYY`).format("x"))))
    }
  }, [urlParams])

  function loadPlanning(){
    if (selectedTeamsIds && selectedTeamsIds.length && collabs.length) {
      let selectedCollabsIds = collabs.reduce((array, current) =>{
        array.push(current.id)
        return array
      }, [])
      dispatch(getPlanning({selectedTeamsIds, selectedCollabsIds}))
    }
  }

  const handleSaveButtonClick = (event) => {
    dispatch(addShifts({ items: shiftsList })).then((response) => {
      let shiftsWithDelegates = shiftsList.filter(s => s.id_team_delegate)
      if (response['payload']['responseData']) {
        if (shiftsWithDelegates.length)
          dispatch(getCollabs({selectedTeamsIds}))
        dispatch(fillPlanningList(response['payload']['body']['items']))
      }
      if (response['payload']['responseError']) {
        showErrorToast()
      }
    })
  }

  const handleDeleteButtonClick = (event) => {
    setShowDeletePopup(true)
  }

  const handleCancelButtonClick = () => {
    dispatch(clearSelectedShifts())
  }

  const handleDeleteSelectedShifts = async () => {
    setShowDeletePopup(false)
    //TODO seperate local shifts from planning server shifts
    let serverShiftsIds = selectedShifts.filter((shiftId) => {
      dispatch(deleteShiftLocally(shiftId))
      return true
    })
    await dispatch(deleteShifts({ ids: serverShiftsIds })).then(
      (response) => {
        dispatch(clearSelectedShifts())
        if (selectedTeamsIds && selectedTeamsIds.length){
          loadPlanning()
        }
      }
    )
  }

  function checkDayOff(date) {
    if (date.getDay() === 0 || date.getDay() === 6) return true;
    let formattedDate = formatDate(date, "DD-MM-YYYY");
    if (settings.holidays[date.getFullYear()])
      for (let i = 0; i < settings.holidays[date.getFullYear()].length; i++) {
        const holiday = settings.holidays[date.getFullYear()][i]
        if (formattedDate === holiday.date) return true
      }
    return false
  }

  function checkIfResourceHasShiftForDate(resourceId, date) {
    let formattedDate = formatDate(date, "DD-MM-YYYY")
    let resourceShifts = shifts.filter(
      (shift) => shift.id_collab === resourceId
    )
    for (let i = 0; i < resourceShifts.length; i++) {
      const shift = resourceShifts[i];
      let formattedShiftDate = formatDate(new Date(shift.from), "DD-MM-YYYY")
      if (formattedDate === formattedShiftDate) return true
    }
    return false
  }

  function setModalShow() {
    dispatch(hideFormPopup())
  }

  const exportExcelReport = async () => {
    await ExcelManager.getInstance().generateAndDownloadComptaReport()
  }

  function renderFloatingButton() {
    if (selectedView == 'MONTHLY') {
      let unpublishedSamples = planningList.filter((planning) => {
        if (isManager(planning.id_team) && moment(selectedDate).month() == moment(planning.from).month()) {
          if (['CREATED', 'UPDATED'].includes(planning.status) || (planning.status != 'PUBLISHED' && planning.id_sample_shift != null))
            return true
        }
        return false
      })

      if (unpublishedSamples.length) {
        return <PublishButton style={{ color: 'white', backgroundColor: '#FA9016', padding: 10 }}
          active={true}
          tooltip="PUBLISH"
          onClick={() => setVisible(true)} />
      }
    }
  }

  function renderFloatingDeleteButton() {
    if (isManager()) {
      if (selectedShifts.length) {
        return <DeleteButton
          style={{ color: 'white', backgroundColor: '#FA9016', padding: 10 }}
          active={true}
          onDelete={handleDeleteButtonClick}
          onCancel={handleCancelButtonClick}
          selected={selectedShifts.length} />
      }
    }
  }

  function getSchedulerHeight() {
    let heightDiff = 122
    if (selectedSamplesView) {
      heightDiff += 75
    }
    return `calc(100vh - ${heightDiff}px)`
  }

  function isManager(teamId) {
    if (!teamId)
      teamId = selectedTeamsIds
    return checkPermission(config.scopes.shifts, config.permissionTypes.edit, teamId)
  }

  const onSelectedDateChange = (date) => {
    //dispatch(setSelectedDate(date))

    let selectedTeamId = selectedTeamsIds[0]
    navigate(`/planning/${selectedTeamId}/${selectedView}${moment(date).format("DDMMYYYY")}`)
  }

  const onSelectedViewChange = (view) => {
    //dispatch(setSelectedDate(date))

    let selectedTeamId = selectedTeamsIds[0]
    navigate(`/planning/${selectedTeamId}/${view}${moment(selectedDate).format("DDMMYYYY")}`)
  }

  return (
    <div className={styles.planningView}>
      <div className="topMenu">
        {isMobile == 2 ? (
          <div>
            <Row>
              <Col xs={8}>
                <span className="headerTitle">{<Langs str={"Planning"} />}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex justify-content-between align-items-center ">
                <TeamSelector includeMyTeam />
                <TransparentDatePicker
                  viewType={selectedSchedulerView}
                  onChange={onSelectedDateChange}
                  value={selectedDate}
                />
              </Col>
            </Row>
            <Row>
              {["WEEKLY", "MONTHLY"].map((view, index) => (
                <Col key={view} xs={3} className="d-flex justify-content-center align-items-center">
                  <StateButton
                    title={view === "WEEKLY" ? "Week" : "Month"}
                    selected={selectedView === view}
                    onClick={() => onSelectedViewChange(view)}
                  />
                </Col>
              ))}
              {isManager() && (
                <Col xs={3} className="d-flex justify-content-center align-items-center  ms-auto">
                  <Button icon="pi pi-align-right"
                    className={styles.topMenuButton}
                    onClick={(event) => menuRight.current.toggle(event)}
                    aria-controls="popup_menu_right"
                    aria-haspopup="true"
                  />
                  <Menu model={planningMenuItems}
                    popup
                    ref={menuRight}
                    id="popup_menu_right"
                    popupAlignment="right"
                  />
                </Col>
              )}
            </Row>
          </div>
        ) : isMobile == 3 ? (
          <Row>
            <Col md={6}>
              <span className="headerTitle">{<Langs str={"Planning"} />}</span>
              <TeamSelector includeMyTeam />
            </Col>
            <Col md={6} className="d-flex justify-content-end align-items-center p-0 m-0">
              <TransparentDatePicker
                viewType={selectedSchedulerView}
                onChange={onSelectedDateChange}
                value={selectedDate}
              />
              <StateButton
                title="Week"
                selected={selectedView === "WEEKLY"}
                onClick={() => onSelectedViewChange("WEEKLY")}
              />
              <StateButton
                title="Month"
                selected={selectedView === "MONTHLY"}
                onClick={() => onSelectedViewChange("MONTHLY")}
              />
              {isManager() ? (
                <div>
                  <Menu model={planningMenuItems}
                    popup
                    ref={menuRight}
                    id="popup_menu_right"
                    popupAlignment="right" />
                  <Button icon="pi pi-align-right"
                    className={styles.topMenuButton}
                    onClick={(event) => menuRight.current.toggle(event)}
                    aria-controls="popup_menu_right"
                    aria-haspopup />
                </div>
              ) : null}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={6}>
              <span className="headerTitle">
                {<Langs str={"Planning"} />}
              </span>
              <TeamSelector includeMyTeam />
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center p-0 m-0">
              <div className="d-flex justify-content-end pe-3">
                <TransparentDatePicker
                  viewType={selectedSchedulerView}
                  onChange={onSelectedDateChange}
                  value={selectedDate} />
              </div>
              <div className="d-flex justify-content-end pe-3">
                <StateButton
                  title="Week"
                  selected={selectedView === "WEEKLY"}
                  onClick={() => onSelectedViewChange("WEEKLY")}
                />
              </div>
              <div className="d-flex justify-content-end pe-3">
                <StateButton
                  title="Month"
                  selected={selectedView === "MONTHLY"}
                  onClick={() => onSelectedViewChange("MONTHLY")}
                />
              </div>
              {isManager() ? <AddSelect /> : null}
              {isManager() ?
                <div className={"mr-4"}>
                  <Menu model={planningMenuItems}
                    popup
                    ref={menuRight}
                    id="popup_menu_right"
                    popupAlignment="right" />
                  <Button icon="pi pi-align-right"
                    className={styles.topMenuButton}
                    onClick={(event) => menuRight.current.toggle(event)}
                    aria-controls="popup_menu_right"
                    aria-haspopup />
                </div> : null}
            </Col>
          </Row>
        )}
      </div>
      <div
        className={
          selectedSamplesView
            ? styles.samplesViewShown
            : styles.samplesViewHidden
        }
      >
        <SamplesRow />
      </div>
      <div className={styles.groupesView}>
        <GroupesRow />
      </div>
      <div
        className={styles.schedulerView} style={{ height: getSchedulerHeight() }}>
        <Scheduler />
      </div>
      {renderFloatingButton()}
      {renderFloatingDeleteButton()}
      <PublishModal show={visible} onHide={handleHide} />
      <PrefillModal show={show} onHide={handleClose} />
      <ShiftFormModal
        show={showFormPopup && selectedForm !== "PROFILE_DETAILS"}
        onHide={() => dispatch(hideFormPopup())}
      />
      <LeaveFormModal
        show={leaveModalType}
        onHide={() => dispatch(hideLeaveModal())}
        data={selectedLeaveData} />
      <ExportFormModal
        show={showExportPopup}
        onHide={() => setShowExportPopup(false)}
      />
      <DuplicateFormModal
        show={showDuplicatePopup}
        onHide={() => setShowDuplicatePopup(false)}
      />
      <DeleteFormModal
        show={showDeletePlanningPopup}
        onHide={() => setShowDeletePlanningPopup(false)}
      />
      <ImpressCraModal
        show={showImpressCra}
        onHide={() => setShowImpressCra(false)}
      />
      <CollabModal
        show={showFormPopup && selectedForm === "PROFILE_DETAILS"}
        onHide={() => setModalShow(false)}
      />
      <ConfirmDeleteModal
        show={showDeletePopup}
        title={<Langs str='DELETE_SHIFTS' />}
        message={<Langs str='DELETE_SHIFTS_MSG' params={{ COUNT: selectedShifts.length }} />}
        onDelete={handleDeleteSelectedShifts}
        onHide={() => setShowDeletePopup(false)} />
      <AddEvaluationModal/>
    </div >
  )
}
