import React, { useState } from "react";
import styles from "./Cells.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setShowFormPopup, toggleSelectedShift } from "../../../containers/planning/planningSlice";
import { showLeaveModal } from "../../../containers/leaves/leavesSlice";
import clock from '../../../assets/images/clock.png';
import correct from '../../../assets/images/correct.png';
import Langs from "../../../app/lang/langs";
import { checkPermission, getDayPeriodFromDate, isAdmin, isOwner } from '../../../app/utils/helpers';
import { config } from "../../../app/utils/config";

export function ShiftCell(props) {

  const dispatch = useDispatch()

  const settings = useSelector((state) => state.settings)
  const allTeams = useSelector((state) => state.teams.allTeams)
  const myTeam = useSelector((state) => state.teams.myTeam)
  const sampleShifts = useSelector((state) => state.sampleShifts.list)
  const sampleLeaves = useSelector((state) => state.sampleLeaves.list)
  const dynamicLeaves = useSelector((state) => state.sampleLeaves.dynamicLeaves)
  const [isFocused, setIsFocused] = useState(false)
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const selectedShifts = useSelector((state) => state.planning.selectedShifts)
  const groupesList = useSelector((state) => state.groupes.list)

  let heureDebut = 9, heureFin = 17, heureMoyenne = 13

  settings.settingsList.map(item => {
    if (item.label === "Heure début de journée") heureDebut = Number(item.settingValue)
    if (item.label === "Heure fin de journée") heureFin = Number(item.settingValue)
    heureMoyenne = (heureFin + heureDebut) / 2
  })

  function getSelectedDayDuration(shiftData) {
    let duration = (shiftData.to - shiftData.from) / (60 * 60 * 1000)
    if (duration <= (heureFin - heureDebut) / 2)
      return "1/2"
    return "1"
  }

  const handleClick = (event) => {
    if ((props.data.id_sample_shift != null || dynamicLeaves[props.data.type] || props.data.type == 'AST') &&
      (event.ctrlKey || event.metaKey) && event.detail === 1) {
      if (isAdmin(userRoleId)) {
        selectShift()
      }
    } else if (event.detail === 2) {
      showShiftPopup()
    }
  }

  let cellType = "SHIFT"
  if (props.data.id_sample_leave) {
    cellType = "LEAVE"
  } else if (props.data.task && props.data.timeFrom != null) {
    cellType = "SAMPLE_SHIFT"
  } else if (props.data.timeFrom != null) {
    cellType = null
  }

  const selectShift = (event) => {
    if (props.data && isManager()) {
      dispatch(toggleSelectedShift(props.data));
    }
  }

  function isManager() {
    return checkPermission(config.scopes.shifts, config.permissionTypes.edit, props.data.id_team)
    //return isAdmin(userRoleId) && (!myTeam || myTeam.userRoleId == 2 || props.data.id_team != myTeam.id)
  }

  const showShiftPopup = (event) => {
    if (props.data.id_sample_leave) {
      dispatch(showLeaveModal({ type: "UPDATE_LEAVE", data: props.data }))
    } else if (cellType && props.data) {
      if (cellType == "SAMPLE_SHIFT" && !isOwner(userRoleId)) 
        return
      dispatch(setShowFormPopup({ type: cellType, data: {...props.data, selectedTeamId: props.resource.id_team} }))
    }
  }

  const handleMouseEnter = (event) => {
    if (props.isInteractive) {
      setIsFocused(true)
    }
  }

  const handleMouseLeave = (event) => {
    if (props.isInteractive) {
      setIsFocused(false)
    }
  }

  function handleContextMenu(event) {
    event.preventDefault()
  }

  function getCellColorFromSampleShiftId(sampleShiftId) {
    let color = "var(--darkGrey)"
    for (const key in sampleShifts) {
      let sampleShift = sampleShifts[key]
      if (sampleShiftId == sampleShift.id && sampleShift["color"]) {
        color = sampleShift["color"]
      }
    }
    return color
  }

  function getCellColorFromSampleLeaveId(sampleLeaveId) {
    let color = "var(--darkGrey)"
    for (const key in sampleLeaves) {
      let sampleLeave = sampleLeaves[key]
      if (sampleLeaveId == sampleLeave.id && sampleLeave["color"]) {
        color = sampleLeave["color"]
      }
    }
    return color
  }

  function getLineColor(shiftData) {
    let groups = groupesList.filter(g => {
      return shiftData.id_groupe == g.id
    })
    if (groups && groups[0])
      return groups[0].color
    return null
  }

  let cellPicto = getIconFromType(props.data.type)
  function getIconFromType(type) {
    for (const key in settings.shiftTypes) {
      let shiftType = settings.shiftTypes[key]
      if (shiftType.value === type && shiftType.icon) {
        return shiftType.icon
      }
    }
  }

  function getColorFromType(type) {
    for (const key in settings.shiftTypes) {
      let shiftType = settings.shiftTypes[key]
      if (shiftType.value === type) {
        return shiftType.color
      }
    }
  }

  function getLabelFromShiftType(type) {
    if (type == 'LEAVE') return 'LEAVE'
    for (const key in settings.shiftTypes) {
      let shiftType = settings.shiftTypes[key]
      if (shiftType.value === type) {
        return shiftType.label
      }
    }
  }

  function getTitle() {

    switch (cellType) {
      case "SHIFT":
        if (props.data.id_team_delegate) {
          let teamsFiltered = allTeams && allTeams.filter(t => t.id == props.data.id_team_delegate)
          if (teamsFiltered && teamsFiltered[0]) {
            return teamsFiltered[0].label.split('.')[0]
          }
        }
        return ''
      case "SAMPLE_SHIFT":
        return props.data.task;
      case "LEAVE":
      case "SAMPLE_LEAVE":
        if (props.data.type == 'LEAVE')
          return <Langs str={props.data.type} />
        return props.data.type
      default:
        break
    }
  }

  function getStatus() {
    switch (cellType) {
      case "LEAVE":
        return props.data.status
      default:
        break
    }
  }

  function getCellIcon() {
    switch (getStatus()) {
      case "VALIDATED":
        return <img className={styles.cellIcon} src={correct} />
      case "PENDING":
      case "DELETE_REQUESTED":
        return <img className={styles.cellIcon} src={clock} />
      default:
        break
    }
  }

  function getStartTime() {
    // check if astreinte or dynamic leave type
    if (['AST','LEAVE'].includes(props.data.type) || dynamicLeaves[props.data.type])
      return ``
    // check if journée standard
    if (props.data.type == 's')
      return getSelectedDayDuration(props.data)
    let minutes, hours
    if (props.data.timeFrom != null) {
      hours = parseInt(props.data.timeFrom / 60)
      minutes = props.data.timeFrom % 60
    } else if (props.data.from) {
      let date = new Date(props.data.from)
      hours = date.getHours()
      minutes = date.getMinutes()
    }
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`
  }

  function getEndTime() {
    // check if astreinte or dynamic leave type
    if (['AST','LEAVE'].includes(props.data.type) || dynamicLeaves[props.data.type])
      return ``
    // check if journée standard
    if (props.data.type == 's')
      return <Langs str='Journée' />
    let minutes, hours
    if (props.data.timeTo != null) {
      hours = parseInt(props.data.timeTo / 60)
      minutes = props.data.timeTo % 60;
    } else if (props.data.to) {
      let date = new Date(props.data.to)
      hours = date.getHours()
      minutes = date.getMinutes()
    }
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""
      }${minutes}`
  }

  function getCellBgColor() {
    if (props.data.type == 'LEAVE') return "#ffffff"
    if (props.data.absent) return "var(--red-400)"
    if (props.data.type == 'R') return "#ffffff"
    if (['AST', 'INT_AST'].includes(props.data.type))
      return getColorFromType('AST')
    else {
      let cellColor = props.data.color
      if (!cellColor || cellColor === undefined) {
        if (props.data.id_sample_leave)
          cellColor = getCellColorFromSampleLeaveId(props.data.id_sample_leave)
        else cellColor = getCellColorFromSampleShiftId(props.data.id_sample_shift)
      }
      return cellColor
    }
  }

  let extraTime = props.extraTime

  let lineColor = getLineColor(props.data)

  let cellStyle = { backgroundColor: getCellBgColor() }
  if (props.data.id_team_delegate && props.data.id_team_delegate != props.originalTeamId) {
    cellStyle = { background: `linear-gradient(-45deg, ${cellStyle.backgroundColor} 12.5%, white 12.5%, white 50%, ${cellStyle.backgroundColor}  50%, ${cellStyle.backgroundColor}  62.5%, white 62.5%, white) 0% 0% / 7px 7px` }
  }

  if (['R', 'LEAVE'].includes(props.data.type)) {
    cellStyle["border"] = "1px solid"
    cellStyle["borderColor"] = "rgba(0, 0, 0, 0.3)"
  }

  return (
    <div
      style={cellStyle}
      className={styles.shiftCell}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span style={{ backgroundColor: lineColor }} className={styles.line} />

      <label className={styles.sampleStartDate}>
        {getStartTime()}
      </label>
      <label className={styles.sampleEndDate}>
        {getEndTime()}
      </label>

      {['AST', 'LEAVE'].includes(props.data.type) ?
        <label className={styles.centerTitle}><Langs str={getLabelFromShiftType(props.data.type)} /></label>
        :
        dynamicLeaves[props.data.type] ?
          <label className={styles.centerTitle}><Langs str={dynamicLeaves[props.data.type].description} /></label>
          :
          <label className={styles.title}>{getTitle()}</label>
      }

      <div className={styles.cellTagsContainer}>
        {cellPicto && <img className={styles.cellPicto} src={cellPicto} />}
        {extraTime != 0 && extraTime &&
          <label
            className={styles.extraTime}
            style={{ backgroundColor: extraTime > 0 ? "green" : "red" }}
          >
            {extraTime > 0 ? `+${extraTime}` : `${extraTime}`}
          </label>}
      </div>
      {getCellIcon()}
      {selectedShifts.includes(props.data.id) ? <div className={styles.selectedCell} /> : <div></div>}
    </div>
  )
}
