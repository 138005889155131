import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../../app/lang/langs";
import {
  addGroupe,
  getGroupes,
  updateGroupe,
  deleteGroupes,
} from "../../../containers/groupes/groupesSlice";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { ThemeButton } from "../../buttons/ThemeButton";
import { TextInput } from "../../inputs/TextInput";
import { CustomColorPicker } from "../../inputs/CustomColorPicker";
import { checkPermission, showErrorToast } from "../../../app/utils/helpers";
import { SelectInput } from "../../inputs/Select";
import { config } from "../../../app/utils/config";
import { SelectInputRefresh } from "../../inputs/SelectRefresh";


export function GroupeModal(props) {

  const dispatch = useDispatch();
  const groupes = useSelector((state) => state.groupes)
  const settings = useSelector((state) => state.settings)
  const confirmationTo = useSelector((state) => state.common.confirmationTo)
  const teams = useSelector((state) => state.teams.allTeams)
  const groupeModalType = useSelector((state) => state.common.groupeModalType)
  const selectedGroupeData = useSelector(
    (state) => state.common.selectedGroupeData
  );
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const groupesList = useSelector((state) => state.groupes.list)
  const [formError, setFormError] = useState(null)
  const [data, setData] = useState({
    name: "",
    color: "",
    id_team: "",
    linked_groups: ""
  })
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }
  }, [dispatch])

  useEffect(() => {
    if (selectedGroupeData) {
      setData(selectedGroupeData)
    }else{
      setData({
        name: "",
        color: "",
        id_team: "",
        linked_groups: ""
      })
    }
  }, [selectedGroupeData])

  function hide() {
    props.onHide()
    setFormError(null)
  }

  function handleAddGroupe() {
    if (!data || !data.id_team) {
      setFormError("MISSING_TEAM_ID")
    }else if (!data || !data.label || !data.label.length) {
      setFormError("MISSING_GROUPE_NAME");
    } 
    else if (data.label && data.id_team) {
      setFormError(null)
      if (groupesList.length < 16) {
        dispatch(addGroupe({
          name: data.label,
          id_team: data.id_team,
          color: data.color,
          linked_groups: data.linked_groups
        })).then(
          (response) => {
            dispatch(getGroupes(selectedTeamsIds))
          }
        )
        hide()
      } else {
        showErrorToast(<Langs str='LIMIT_REACHED' />)
      }
    }
  }

  function handleDeleteGroupe() {
    setFormError(null)
    dispatch(deleteGroupes({ ids: [data.id] })).then(
      (response) => {
        dispatch(getGroupes(selectedTeamsIds))
      }
    )
    hide()
  }

  function handleSaveGroupe() {
    if (!data || !data.label.length) {
      setFormError("MISSING_GROUPE_NAME")
    }
    else {
      setFormError(null)
      dispatch(
        updateGroupe({
          id: data.id,
          name: data.label,
          color: data.color,
          id_team: data.id_team,
          linked_groups: data.linked_groups
        })
      ).then((response) => {
        dispatch(getGroupes(selectedTeamsIds))
      })
      hide()
    }
  }

  const handleGroupeNameChange = (event) => {
      setData({ ...data, label: event.target.value })
  }

  const handleTeamChange = (option) => {
      setData({ ...data, id_team: option.value, linked_groups: ""})
  }

  const handleLinkedGroupsChange = (selectedList) => {
    if (data) {
      let linkedGroups = selectedList.reduce((array, current) => {
        array.push(current.value)
        return array
      }, []).join(",")
      setData({ ...data, linked_groups: linkedGroups })
    }
  }

  const handleGroupeColorChange = (hexColor) => {
    if (data) {
      setData({ ...data, color: hexColor })
    }
  }

  useEffect(() => {
    if (confirmationTo === "CONFIRM_DELETE_GROUPE") {
    }
  }, [dispatch, confirmationTo])

  function getTitle() {
    if (
      (groupeModalType === "EDIT_GROUPE" ||
        groupeModalType === "CONFIRM_DELETE_GROUPE") &&
      data
    ) {
      return data.label;
    } else if (groupeModalType) {
      return <Langs str={groupeModalType} />
    }
  }

  function renderBodyView() {
    
    return (
      <Modal.Body>
        {groupeModalType !== "EDIT_GROUPE" && <Row className="d-flex justify-content-center align-items-center p-1 mb-3">
          <Col md={3}>
            <label>
              <Langs str="TEAM" />
            </label>
          </Col>
          <Col md={9}
            className={formError === "MISSING_TEAM_ID" && "inputError"}>
            <SelectInput
              value={data.id_team}
              onChange={handleTeamChange}
              error={formError === "MISSING_TEAM_ID"}
              options={teams && teams.filter(t => 
                selectedTeamsIds.includes(t.value) && checkPermission(config.scopes.groups, config.permissionTypes.edit, t.value))}
            /></Col>
        </Row>}
        <Row className="d-flex justify-content-center align-items-center p-1 mb-3">
          <Col md={3}>
            <label>
              <Langs str="GROUPE_NAME" />
            </label>
          </Col>
          <Col
            md={9}
            className={formError === "MISSING_GROUPE_NAME" && "inputError"}
          >
            <TextInput
              value={data.label}
              maxLength={15}
              onChange={handleGroupeNameChange}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center p-1 mb-3">
          <Col md={3}>
            <label>
              <Langs str="LINKED_GROUPES" />
            </label>
          </Col>
          <Col
            md={9}
          >
            <SelectInputRefresh
              values={data.linked_groups}
              onChange={handleLinkedGroupsChange}
              options={groupesList && groupesList.filter(g => g.id !== data.id && g.id_team === data.id_team)}
              isMulti
            />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center align-items-center p-1'>
          <Col md={3}>
            <label>
              <Langs str="GROUPE_COLOR" />
            </label>
          </Col>
          <Col md={9}>
            <CustomColorPicker
              onChange={handleGroupeColorChange}
              color={data.color}
              colors={settings.groupesColors} />
          </Col>
        </Row>
      </Modal.Body>
    )
  }

  function renderHeaderView() {
    return (
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {getTitle()}
        </Modal.Title>
      </Modal.Header>
    )
  }

  function renderFooterView() {
    return (
      <Modal.Footer className="d-flex justify-content-between">
        <Col md={3}>
          <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
        </Col>
        <Col md={3}>
          {groupeModalType === "EDIT_GROUPE" ? (
            <ThemeButton
              title="SAVE"
              active={true}
              onClick={() => handleSaveGroupe()}
            />
          ) : groupeModalType === "CONFIRM_DELETE_GROUPE" ? (
            <ThemeButton
              title="DELETE"
              active={true}
              onClick={() => handleDeleteGroupe()}
            />
          ) : (
            <ThemeButton
              title="ADD"
              active={true}
              onClick={() => handleAddGroupe()}
            />
          )}
        </Col>
      </Modal.Footer>
    )
  }
  return (
    <Modal
      {...props}
      onHide={() => hide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {renderHeaderView()}
      {groupeModalType !== "CONFIRM_DELETE_GROUPE" ? (
        renderBodyView()
      ) : (
        <div style={{ padding: 20 }}><Langs str="ARE_YOU_SURE"></Langs></div>
      )}
      {renderFooterView()}
    </Modal>
  )
}
