import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFieldModalVisibilty, setOnEditField, editEvaluationField } from "../../../components/evaluations/evaluationsSlice";
import Langs from "../../../app/lang/langs";
import { Col, Row } from "react-bootstrap";
import { ToggleButton } from "primereact/togglebutton";
import { DynamicButton } from "../../../components/buttons/DynamicButton";
import styles from "../Settings.module.css";


export default function EvaluationFieldModal() {

    const dispatch = useDispatch();

    const visible = useSelector((state) => state.evaluations.fieldModal);
    const onEditField = useSelector((state) => state.evaluations.onEditField)
    const fields = useSelector((state) => state.evaluations.fieldsIgnoreActive)

    const [title, setTitle] = useState('')
    const [active, setActive] = useState(false)
    const [currentField, setCurrentField] = useState(null)
    const [titleClass, setTitleClass] = useState(styles.rowShadeGreen)

    useEffect(() => {
        const field = fields.find(item => item.id == onEditField);
        if (field) {
            setCurrentField(field)
            setTitle(field.title)
            setActive(field.active)
        }
    }, [onEditField])

    const handleClose = () => {
        dispatch(setFieldModalVisibilty(false));
        dispatch(setOnEditField(null));
        setCurrentField(null);
        setTitle('');
        setActive(null);
        setTitleClass(styles.rowShadeGreen);
    }
    const handleSave = () => {
        if (title.trim() != '' && title != null) {
            if (currentField) {
                const body = {
                    id: currentField.id,
                    title: title,
                    active: active
                }
                dispatch(editEvaluationField(body))
                handleClose()
            }
        } else {
            setTitleClass(styles.rowShadeRed)
        }
    }

    //html
    const header = () => {
        return (
            <div>
                <Langs str='EDIT_QUESTION' />
            </div>
        )
    }

    const body = () => {
        return (
            <div>
                <Row className="p-2">
                    <Row className='p-2 pt-4 pb-4 border-top'>
                        <Col lg={3} className="my-auto fw-bold">
                            <Langs str='TITLE' />
                        </Col>
                        <Col lg={9}>
                            <input
                                className={`form-control ${titleClass}`}
                                value={title}
                                rows={4}
                                onChange={(e) => { setTitle(e.target.value) }}
                            />
                        </Col>
                    </Row>
                    <Row className="p-2 pb-4 border-bottom">
                        <Col lg={3} className="py-2 fw-bold">
                            <Langs str='VISIBLTY' />
                        </Col>
                        <Col lg={9} className="d-flex justify-content-start">
                            <ToggleButton
                                onLabel="Actif"
                                offLabel="Inactif"
                                onIcon="pi pi-check"
                                offIcon="pi pi-times"
                                checked={active}
                                onChange={(e) => setActive(e.value)} className="w-9rem" />
                        </Col>
                    </Row>
                </Row>
            </div>
        )
    }

    const footer = () => {
        return (
            <div>
                <Row className="d-flex justify-content-center">
                    <Col lg={3}>
                        <DynamicButton
                            onClick={() => handleSave()}
                            className='me-auto'
                            title={<Langs str="SAVE" />}
                            active={true}
                            color="green"
                        />
                    </Col>
                </Row>
            </div>
        )
    }


    return (
        <div>
            <Dialog
                header={header}
                footer={footer}
                visible={visible}
                style={{ width: '30vw' }}
                draggable={false}
                onHide={() => { if (!visible) return; handleClose() }}>
                {body()}
            </Dialog>
        </div>
    )
}