import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { DangerButton } from "../buttons/DangerButton";

export function ConfirmDeleteModal(props) {

    function hide() {
        props.onHide();
    }

    function renderBodyView() {
        return (
            <Modal.Body>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <label>{props.message}</label>
                </Row>
            </Modal.Body>
        )
    }

    function renderHeaderView() {
        return (
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <label>{props.title}</label>
                </Modal.Title>
            </Modal.Header>
        )
    }

    function renderFooterView() {
        return (
            <Modal.Footer className="d-flex justify-content-between">
                <Col md={3}>
                    <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
                </Col>
                <Col md={3}>
                    <DangerButton
                        title="DELETE"
                        active={true}
                        onClick={props.onDelete}
                    />
                </Col>
            </Modal.Footer>
        )
    }
    return (
        <Modal
            {...props}
            onHide={() => hide()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {renderHeaderView()}
            {renderBodyView()}
            {renderFooterView()}
        </Modal>
    )
}
