import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Langs from "../../app/lang/langs";

export function DynamicButton(props) {
    const [buttonColor, setButtonColor] = useState('orange');
    useEffect(() => {
        if (props.color) {
            setButtonColor(props.color);
        } else {
            setButtonColor('orange');
        }
    }, [props.color]);

    const [isHovered, setIsHovered] = useState(false);
    const hoverStyles = isHovered ? { backgroundColor: `var(--${buttonColor})`, color: 'white' } : {};
    const buttonStyle = {
        borderRadius: '20px',
        height: '40px',
        width: '100%',
        minWidth: '100px',
        backgroundColor: '#fff',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'black',
        border: `solid 2px var(--${buttonColor})`,
        ...hoverStyles,
    };

    return (
        <Button 
            className={props.active ? "" : " opacity-50 disabled not-allowed"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={props.onClick}
            style={buttonStyle}>
            <Langs str={props.title}/>
        </Button>
    );
}
