import React, { useEffect, useRef, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { importProjectFromExcel, setRespExcelUpload, getProjectAptitudes } from "./vcompSlice";
import { useDispatch, useSelector } from "react-redux";
import { DynamicButton } from "../buttons/DynamicButton";
import Langs from "../../app/lang/langs";
import { Col, Row } from "react-bootstrap";
import { showErrorToast, showSuccessToast } from "../../app/utils/helpers";
import vcompTemplate from '../../assets/excel-template/vcomp-template.xlsx';
import styles from './VcompCompnents.module.css'

const ExcelInputModal = ({ visibility, setVisibility }) => {

    const dispatch = useDispatch()

    const project = useSelector((state) => state.vcomp.onCreateProject);
    const responseImport = useSelector((state) => state.vcomp.respExcelUpload);

    const [excelFile, setExcelFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [idProject, setIdProject] = useState(null)



    useEffect(() => {
        if (project && project.id) {
            setIdProject(project.id)
        } else {
            setIdProject(null)
        }
    }, [project])

    useEffect(() => {
        if (responseImport != null) {
            if (responseImport.trim().toLowerCase().includes("successfully")) {
                if (idProject) {
                    dispatch(getProjectAptitudes({ id_project: idProject }))
                }
                showSuccessToast(responseImport);
                dispatch(setRespExcelUpload(null));
                handleClose()
            } else {
                showErrorToast(responseImport);
                setExcelFile(null)
                setFileName('')
            }
            dispatch(setRespExcelUpload(null));
        }
    }, [responseImport]);


    const handleFileChange = (event) => {
        console.log(event)
        event.preventDefault();
        let files;
        if (event.type === "drop" && event.dataTransfer) {
            files = event.dataTransfer.files;
        } else {
            files = event.target.files;
        } if (files?.length > 0) {
            const file = files[0];
            if (file.size > 90 * 1024) {
                showErrorToast('File size should not exceed 90 KB');
                return;
            }
            setExcelFile(file);
            setFileName(file.name);
        }
    };

    const handleUploadExcel = async (idProject, file) => {
        const fileToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };
        if (idProject && file) {
            try {
                const base64 = await fileToBase64(file);

                dispatch(importProjectFromExcel({
                    file: base64,
                    id_project: idProject
                }));
            } catch (error) {
                console.error('Error converting file to Base64:', error);
            }
        }
    };


    const handleClearFile = (event) => {
        event.stopPropagation();
        setExcelFile(null);
        setFileName('');
        setIdProject(null)
    };
    const handleClearFile2 = (event) => {
        event.stopPropagation();
        setExcelFile(null);
        setFileName('');
    };
    const handleClose = () => {
        setVisibility(false);
        setExcelFile(null);
        setFileName('');
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const footer = () => {
        return (
            <Row className="d-flex justify-content-center" >
                <Col lg={2} xs={2}>
                    <DynamicButton
                        onClick={() => handleUploadExcel(idProject, excelFile)}
                        className='me-auto'
                        title={<Langs str="UPLOAD" />}
                        active={excelFile}
                        color="orange"

                    />
                </Col>
            </Row>
        )
    }
    const header = () => {
        return (
            <div>
                Upload Excel File
            </div>
        )
    }
    return (
        <Dialog
            header={header}
            draggable={false}
            visible={visibility}
            style={{ width: '50vw' }}
            onHide={handleClose}
            footer={footer}
        >
            <a href={vcompTemplate} download="vcomp-template.xlsx">
                Download Excel template
            </a>
            <div className="p-fluid" style={{ textAlign: 'center' }}>
                {!fileName &&
                    <div
                        onDrop={handleFileChange}
                        onDragOver={handleDragOver}
                        style={{
                            border: '2px dashed gray',
                            padding: '20px',
                            margin: '20px auto',
                            width: '80%',
                            position: 'relative'
                        }}
                    >
                        Drag and drop your Excel file here or click to select.
                        <input
                            type="file"
                            accept=".xls,.xlsx"
                            onChange={handleFileChange}
                            style={{
                                opacity: 0,
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                cursor: 'pointer'
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                event.currentTarget.value = null;
                            }}
                        />
                    </div>
                }
                {fileName && (
                    <div style={{ marginTop: '10px' }}>
                        <span>File: <b>{fileName}</b></span>
                        <button onClick={handleClearFile2} className={styles.clearButton}>Clear</button>
                    </div>
                )}
            </div>
        </Dialog>
    );
}

export default ExcelInputModal;
