import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import { DangerButton } from "../../components/buttons/DangerButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { SelectInput } from "../../components/inputs/Select";
import { TransparentDatePicker } from "../../components/buttons/TransparentDatePicker";
import { generateReport } from "./reportsSlice";
import { generateUniqueId } from "../../app/utils/helpers";
import moment from "moment";

export function ReportFormModal(props) {

    const dispatch = useDispatch()

    const settings = useSelector((state) => state.settings)
    const teams = useSelector((state) => state.teams.allTeams)
    const selectedDate = useSelector((state) => state.reports.selectedDate)

    const [formError, setFormError] = useState(null)
    const [formData, setFormData] = useState({
        date: selectedDate ? new Date(selectedDate) : new Date()
    })

    const mounted = useRef(false)

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        }
    })

    useEffect(() => {
        if (selectedDate) {
            setFormData({ ...formData, date: selectedDate })
        }
    }, [selectedDate])

    function hide() {
        props.onHide()
    }

    const handleCompanyChange = (option) => {
        setFormData({ ...formData, id_company: option.value })
    }

    const handleClientChange = (option) => {
        setFormData({ ...formData, id_team: option.value })
    }

    const handleDescriptionChange = (e) => {
        setFormData({ ...formData, description: e.target.value })
    }

    const handleDateChange = (date) => {
        setFormData({ ...formData, date: date })
    }

    function handleCreate() {
        if (!formData.date) {
            setFormError("date")
            return
        }
        setFormError(null)
        let id = generateUniqueId()
        let date = moment(formData.date).format("MM-YYYY")
        let filename = `EXPORT_${date}_${id}.xlsx`
        if(formData.id_company){
            filename = `${formData.id_company}_${date}_${id}.xlsx`
        }
        let reportData = {
            id: id,
            date: date,
            description: formData.description,
            status: "CREATED",
            is_silae: props.isSilae,
            filename: filename
        }
        if(formData.id_team) reportData.id_team = formData.id_team
        if(formData.id_company) reportData.id_company = formData.id_company
        dispatch(generateReport(reportData)).then(() => {
            props.onSubmit(formData.date)
        })
        hide()
    }

    function renderBodyView() {
        return <Modal.Body>
            <Row className="d-flex justify-content-center align-items-center p-2">
                <Col md={3}>
                    <span>
                        <Langs str="Month" />
                    </span>
                </Col>
                <Col md={9}>
                    <TransparentDatePicker
                        viewType="MONTHLY"
                        onChange={handleDateChange}
                        value={formData.date} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center p-2">
                <Col md={3}>
                    <label>
                        <Langs str="COMPANY" />
                    </label>
                </Col>
                <Col md={9}>
                    <SelectInput
                        value={formData.id_company}
                        onChange={handleCompanyChange}
                        error={formError === "id_company"}
                        options={[{label: "All", value: null}, ...settings.companies]}
                    />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center p-2">
                <Col md={3}>
                    <label>
                        <Langs str="CLIENT" />
                    </label>
                </Col>
                <Col md={9}>
                    <SelectInput
                        value={formData.id_team}
                        onChange={handleClientChange}
                        options={[{label: "All", value: null}, ...teams]}
                    />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center p-2">
                <Col md={3}>
                    <label>
                        <Langs str="DESCRIPTION" />
                    </label>
                </Col>
                <Col md={9}>
                    <textarea
                        name="description"
                        maxLength={200}
                        value={formData.description}
                        onChange={handleDescriptionChange}
                    />
                </Col>
            </Row>
        </Modal.Body>
    }

    function renderHeaderView() {
        return (
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <Langs str={props.type} />
                </Modal.Title>
            </Modal.Header>
        )
    }

    function renderFooterView() {
        return <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
                <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
            </Col>
            <Col md={3}>
                <DangerButton
                    title="CREATE"
                    active={true}
                    onClick={() => handleCreate()}
                />
            </Col>
        </Modal.Footer>
    }

    return (
        <Modal
            {...props}
            show={props.type}
            onHide={() => hide()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {renderHeaderView()}
            {renderBodyView()}
            {renderFooterView()}
        </Modal>
    )
}
