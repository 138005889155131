import React, { useState } from 'react';
import styles from './Cells.module.css';
import { useDispatch, useSelector } from "react-redux";
import { setShowFormPopup } from '../../../containers/planning/planningSlice'

export function ShiftPlaceholder(props) {

    const dispatch = useDispatch()

    const handleClick = event => {
        if (event.detail === 2) {
            showShiftPopup()
        }
    }

    const showShiftPopup = event => {
        dispatch(setShowFormPopup({ type: props.data.timeFrom ? "SAMPLE_SHIFT" : "SHIFT", data: props.data }))
    }

    function handleContextMenu(event) {
        event.preventDefault()

    }

    return <div
        className={styles.shiftPlaceholder}
        onClick={handleClick} 
        onContextMenu={handleContextMenu}
    >
    </div>
}