import Select from 'react-select';
import { useEffect, useState } from 'react';
import { selectInputStyles, selectInputErrorStyles } from './inputStyles'

export function SelectInputRefresh(props) {
    const [selectedOption, setSelectedOption] = useState(() => getInitialSelectedOption());
    function getInitialSelectedOption() {
        if (props.values) {
            if (props.isMulti)
                return props.options.filter(o => props.values.includes(o.value))
            return props.options.find(o => o.value === props.values.value) || null;
        }
    }

    useEffect(() => {
        setSelectedOption(getInitialSelectedOption());
    }, [props.options]);

    return <Select
        value={selectedOption}
        onChange={(newValue) => {
            setSelectedOption(newValue);
            props.onChange(newValue);
        }}
        options={props.options}
        isMulti={props.isMulti}
        styles={props.error ? selectInputErrorStyles : selectInputStyles}
    />
}
