import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, OverlayTrigger, Popover } from "react-bootstrap";
import styles from './Common.module.css';
import { ThemeButton } from '../../components/buttons/ThemeButton';
import logo from "../../assets/images/logo_main.png";
import planning from "../../assets/images/planning.png";
import groupes from "../../assets/images/groupes.png";
import collabs from "../../assets/images/collabs.png";
import settings from "../../assets/images/setting-lines.png";
import leaves from "../../assets/images/sunbed.png";
import timesheet from "../../assets/images/routine.png";
import reports from "../../assets/images/reports_icon.png";
import evaluation from '../../assets/images/evaluationMenu.png';
import vcomp from '../../assets/images/vcompIcon.png'
import ventr from '../../assets/images/ventrIcon.png'

import {
    onMenuItemClick, setDefaultPage
} from './commonSlice'
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, onPopupHandle, showPopup } from "./commonSlice";
import { logoutUser } from "../login/loginSlice";
import { useMsal } from "@azure/msal-react";
import { checkPermission, isAdmin, isOwner } from '../../app/utils/helpers';
import Langs from '../../app/lang/langs';
import { setSelectedTeams } from "../../components/teams/teamsSlice";
import { config } from '../../app/utils/config';
import { isMoment } from 'moment';
import { Sidebar } from 'primereact/sidebar';
import { Button as PrimeButton } from 'primereact/button';

export function MenuBar() {

    const { instance } = useMsal();
    const popoverRef = useRef()
    const buttonRef = useRef()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const loginType = useSelector((state) => state.login.loginType)
    const permissions = useSelector((state) => state.login.permissions)
    const confirmationTo = useSelector((state) => state.common.confirmationTo)
    const email = useSelector((state) => state.login.userEmail)
    const username = useSelector((state) => state.login.userName)
    const userRoleId = useSelector((state) => state.login.userRoleId)
    const auto = useSelector((state) => state.login.auto)
    const myTeam = useSelector((state) => state.teams.myTeam)
    const teams = useSelector((state) => state.teams.teams)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const defaultPage = useSelector((state) => state.common.defaultPage)
    const isMobile = useSelector((state)=> state.settings.isMobile)


    const [showPopover, setShowPopover] = useState(false)
    const [menuItems, setMenuItems] = useState(getMenuButtons())

    const [visible, setVisible] = useState(false);

    function getMenuButtons() {
        return [
            {
                value: 'planning',
                label: 'Planning',
                image: planning,
                imageFocus: planning,
                visible: true,
                focused: false,
                subView: 0
            },
            {
                value: 'groupes',
                label: 'Groupes & Collabs',
                image: groupes,
                imageFocus: groupes,
                visible: checkPermission(config.scopes.groups, config.permissionTypes.view, selectedTeamsIds),
                focused: false,
                subView: 0
            },
            {
                value: 'collabs',
                label: 'Collabs',
                image: collabs,
                imageFocus: collabs,
                visible: checkPermission(config.scopes.collabs, config.permissionTypes.view) || checkPermission(config.scopes.warnings, config.permissionTypes.view),
                focused: false,
                subView: 0
            },
            {
                value: 'reports',
                label: 'Reports',
                image: reports,
                imageFocus: reports,
                visible: isOwner(userRoleId),
                focused: false,
                subView: 0
            },
            {
                value: 'evaluations',
                label: 'Evaluations',
                image: evaluation,
                imageFocus: evaluation,
                visible: checkPermission(config.scopes.evaluations, config.permissionTypes.view, selectedTeamsIds),
                focused: false,
                subView: 0
            },
            {
                value: 'timesheet',
                label: 'Timesheet',
                image: timesheet,
                imageFocus: timesheet,
                visible: auto && myTeam != null,
                focused: false,
                subView: 0
            },
            {
                value: 'leaves',
                label: 'Leaves',
                image: leaves,
                imageFocus: leaves,
                visible: true,
                focused: false,
                subView: 0
            },
            {
                value: 'vcomp',
                label: 'V-Comp',
                image: vcomp,
                imageFocus: vcomp,
                visible: checkPermission(config.scopes.vcomp, config.permissionTypes.view),
                focused: false,
                subView: 0
            },
            {
                value: 'ventr',
                label: 'V-Entr',
                image: ventr,
                imageFocus: ventr,
                visible: false,
                focused: false,
                subView: 0
            },
            {
                value: 'settings',
                label: 'Settings',
                image: settings,
                imageFocus: settings,
                visible: true,
                focused: false,
                subView: 0
            }
        ]
    }

    useEffect(() => {
        setMenuItems(getMenuButtons())
    }, [teams, myTeam, userRoleId, auto, permissions, selectedTeamsIds])

    useEffect(() => {
        if (confirmationTo === "CONFIRM_LOGOUT") {
            dispatch(onPopupHandle())
            dispatch(setIsLoading(true))
            if (loginType === "MS") {
                const logoutRequest = {
                    account: instance.getAccountByUsername(email)
                }
                instance.logout(logoutRequest)
            }
            dispatch(logoutUser({ doReload: true }))
        }
    }, [confirmationTo])

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (popoverRef.current && !popoverRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setShowPopover(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [popoverRef])

    useEffect(() => {
        if (urlParams.view) {
            menuItems.map(item => {
                if (item.value === urlParams.view) {
                    item.subView = urlParams.subView
                }
            })
            setMenuItems([...menuItems])
        }
    }, [urlParams])

    const handleShowPopover = () => {
        setShowPopover(!showPopover)
    }

    function handleLogout() {
        dispatch(showPopup({ show: true, title: "CONFIRM_LOGOUT", message: "CONFIRM_LOGOUT_MSG" }))
    }

    function setFocusedMenu(value, isFocused) {
        menuItems.map(item => {
            if (item.value === value) {
                item.focused = isFocused
            }
        })
        setMenuItems([...menuItems])
    }

    function setSelectedMenu(value) {
        let subview = 0
        menuItems.map(item => {
            if (item.value === value) {
                subview = item.subView
            }
        })
        let selectedTeamId = selectedTeamsIds[0]
        if (!selectedTeamId) {
            if (myTeam) selectedTeamId = myTeam.id
            else if (teams && teams[0]) selectedTeamId = teams[0].id
            if (selectedTeamId)
                dispatch(setSelectedTeams([selectedTeamId]))
        }
        navigate(`/${value}/${selectedTeamId}/${subview}`)
    }

    function getUsernameInitials() {
        if (username && username.length && username !== "null") {
            let array = username.split(" ")
            let initials = ""
            while (initials.length < 2 && array.length > 0) {
                let word = array.pop()
                initials += word.slice(0, 1)
            }
            return initials.toUpperCase()
        } else if (email) {
            return email.slice(0, 1).toUpperCase()
        }
    }

    function getRoleType() {
        if (typeof userRoleId != 'undefined')
            switch (parseInt(userRoleId)) {
                case 1:
                    return "Admin"
                case 2:
                    return "Manager"
                case 3:
                    return "Collab"
                default:
                    break;
            }
    }
    if (isMobile == 2) {
        return (
            <div>
                <PrimeButton
                    icon="pi pi-bars"
                    onClick={() => setVisible(true)}
                    className="menu-button-mobile"
                    aria-label="Open Menu"
                    severity='warning'
                    style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000, backgroundColor: "#FF9015" }}
                />
                <Sidebar
                    visible={visible}
                    onHide={() => setVisible(false)}
                    position="right"
                    className="mobile-sidebar sidebar">
                    <h1>Menu</h1>
                    <ul>
                        {menuItems.map(item => {
                            if (item.visible) {
                                return <Row className={styles.menuItem}
                                    key={"menu_" + item.value}>
                                    <button className={styles.menuButton + " " + ((urlParams.view == item.value) ?
                                        styles.selectedMenuButton : "")}
                                        onMouseEnter={() => setFocusedMenu(item.value, true)}
                                        onMouseLeave={() => setFocusedMenu(item.value, false)}
                                        onClick={() => {
                                            setSelectedMenu(item.value);
                                            setVisible(false); 
                                        }}>
                                        <img src={(urlParams.view && urlParams.view.toLowerCase() === item.value.toLowerCase()) ? item.imageFocus : item.image}
                                            alt={item.value}
                                            width="30" />
                                        <span className={styles.label}>
                                            <Langs str={item.label} />
                                        </span>
                                    </button>
                                </Row>
                            }
                        })}
                    </ul>
                    <div className={styles.profileMenuFooter}>
                        <OverlayTrigger
                            show={showPopover}
                            key={'top'}
                            placement={'top'}
                            overlay={
                                <Popover id='popover-positioned-top'  style={{ zIndex: 1200 }}>
                                    <Popover.Body ref={popoverRef}>
                                        <label className='pb-2 fw-bold text-decoration-underline' style={{ color: "var(--vital-blue)" }}>{email}</label><br />
                                        <label className='pb-3 text-muted fw-bold'>{getRoleType()}</label>
                                        <ThemeButton
                                            title="LOGOUT"
                                            width={"38"}
                                            active={true}
                                            onClick={() => {
                                                handleLogout();
                                                setVisible(false);
                                            }}>
                                        </ThemeButton>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <button
                                className={styles.userButton}
                                ref={buttonRef}
                                onClick={handleShowPopover}
                                width={"38"}>
                                {getUsernameInitials()}
                            </button>
                        </OverlayTrigger>
                    </div>
                </Sidebar>
            </div>
        )
    }
    return <Col className={styles.menuBar}>
        <img className={styles.headerLogo}
            src={logo}
            alt={"logo"}
            onClick={() => setSelectedMenu(defaultPage)} />
        <div className={styles.menuList}>
            {
                menuItems.map(item => {
                    if (item.visible) {
                        return <Row className={styles.menuItem}
                            key={"menu_" + item.value}>
                            <button className={styles.menuButton + " " + ((urlParams.view == item.value) ?
                                styles.selectedMenuButton : "")}
                                onMouseEnter={() => setFocusedMenu(item.value, true)}
                                onMouseLeave={() => setFocusedMenu(item.value, false)}
                                onClick={() => setSelectedMenu(item.value)}>
                                <img src={(urlParams.view && urlParams.view.toLowerCase() === item.value.toLowerCase()) ? item.imageFocus : item.image}
                                    alt={item.value}
                                    width="30" />
                                <span className={styles.label}>
                                    <Langs str={item.label} />
                                </span>
                            </button>
                        </Row>
                    }
                })
            }
        </div>
        <div className={styles.profileMenuFooter}>
            <OverlayTrigger
                show={showPopover}
                key={'top'}
                placement={'top'}
                overlay={
                    <Popover id='popover-positioned-top'  style={{ zIndex: 1200 }}>
                        <Popover.Body ref={popoverRef}>
                            <label className='pb-2 fw-bold text-decoration-underline' style={{ color: "var(--vital-blue)" }}>{email}</label><br />
                            <label className='pb-3 text-muted fw-bold'>{getRoleType()}</label>
                            <ThemeButton
                                title="LOGOUT"
                                width={"38"}
                                active={true}
                                onClick={() => handleLogout()}>
                            </ThemeButton>
                        </Popover.Body>
                    </Popover>
                }
            >
                <button
                    className={styles.userButton}
                    ref={buttonRef}
                    onClick={handleShowPopover}
                    width={"38"}>
                    {getUsernameInitials()}
                </button>
            </OverlayTrigger>
        </div>
    </Col>
}