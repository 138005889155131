import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Langs from '../../../app/lang/langs';
import { showGroupeModal } from '../../../containers/common/commonSlice';
import { GroupeCell } from '../cells/GroupeCell';
import styles from './Rows.module.css';
import { checkPermission, isAdmin } from '../../../app/utils/helpers';
import { config } from '../../../app/utils/config';

export function GroupesRow(props) {

    const dispatch = useDispatch()
    // const groupes = useSelector((state) => state.groupes)
    // const selectedGroupe = useSelector((state) => state.planning.selectedGroupe)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const groupesList = useSelector((state) => state.groupes.list)
    const myTeam = useSelector((state) => state.teams.myTeam)

    let teamId = myTeam && myTeam.id

    const handleAddGroupe = () => {
        dispatch(showGroupeModal({ type: "ADD_GROUPE" }))
    }

    return <div className={styles.container}>
        <div className={styles.groupeList}>
            <GroupeCell data={{
                value: 0,
                label: 'All'
            }} key={`GroupeCell_0}`} />
            {groupesList.filter(groupe => selectedTeamsIds.includes(groupe.id_team)).map(groupe => {
                return <GroupeCell data={groupe} key={`GroupeCell_${groupe.id}`} />
            })
            }
        </div>

        {checkPermission(config.scopes.groups, config.permissionTypes.edit, teamId) ?
            <button className={styles.manageGroupesButton} onClick={handleAddGroupe}>
                <Langs str='ADD_GROUPE' />
            </button> : null
        }
    </div>
}