import React, { useState } from 'react';
import styles from './Buttons.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import deleteImage from '../../assets/images/delete.png';
import exitImage from '../../assets/images/close.png';
import Langs from '../../app/lang/langs';


export default function DeleteButton(props) {

    return <div className={styles.floatingDeleteContainer}>
        <button className={styles.floatingDeleteButton + (props.active ? "" : " opacity-50 disabled not-allowed")}
            style={props.style}
            onClick={props.onDelete}>
            <Langs str="Delete" /> {props.selected}
        </button>
        <button className={styles.floatingCancelButton + (props.active ? "" : " opacity-50 disabled not-allowed")}
            style={props.style}
            onClick={props.onCancel}>
        </button>
    </div>
}