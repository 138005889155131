import dateFormat from "dateformat";
import moment from "moment";
import 'moment/locale/fr'
import { store } from '../store/store';

import { toast } from 'react-toastify'
import { config } from "./config";
const { customAlphabet } = require("nanoid")
const nanoid = customAlphabet("1234567890", 14)

/**
 * 
 * @param {*} date 
 * @param {*} format 
 * @returns 
 */
export const formatDate = (date, format) => {
  moment.locale(localStorage.getItem("lang"))
  return moment(date).format(format)
}

/**
 * 
 * @param {*} date 
 * @returns 
 */
export const getDayPeriodFromDate = (date) => {
  if (date) {
    let hour = date.getHours()
    if (hour < 12) return "matin"
    else if (hour == 12) return "midi"
    return "soir"
  }
  return ""
}

/**
 * 
 * @param {*} date 
 * @returns 
 */
export const getFormattedDate = (date) => {
  return dateFormat(date, "dd-mm-yyyy");
}

/**
 * 
 * @param {*} date 
 * @param {*} format 
 * @returns 
 */
export const getVarFormattedDate = (date, format) => {
  return dateFormat(date, format);
}

/**
 * 
 * @param {*} seconds 
 * @returns 
 */
export async function wait(seconds) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

/**
 * 
 * @param {*} email 
 * @returns 
 */
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

/**
 * 
 * @param {*} input_str 
 * @returns 
 */
export function validatePhoneNumber(input_str) {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  return re.test(input_str);
}

/**
 * 
 * @param {*} date 
 * @returns 
 */
export function getWeek(date) {
  if (!date) return ""
  var now = new moment(date)
  return now.week()
}

/**
 * 
 * @param {*} date 
 * @returns 
 */
export function getWeekYear(date) {
  if (!date) return ""
  var now = new moment(date)
  return now.endOf("week").year()
}

/**
 * 
 * @param {*} roleId 
 * @returns 
 */
export function isAdmin(roleId) {
  if (parseInt(roleId) > 2) {
    return false
  }
  return true
}

/**
 * 
 * @param {*} roleId 
 * @returns 
 */
export function isOwner(roleId, localization) {
  let minRole = 1
  if (localization && ['BEYROUTH'].includes(localization)) {
    minRole = 2
  }
  if (parseInt(roleId) > minRole) {
    return false
  }
  return true
}

/**
 * Helper function to define if its a working shift or not
 * @param {*} shift 
 * @returns 
 */
export function isWorkingShift(shift) {
  return !shift.absent &&
    !['R', 'AST', 'INT_AST', 'WE'].includes(shift.type) &&
    typeof shift.id_sample_shift != undefined &&
    shift.id_sample_shift != null
}

/**
 * 
 * @param {*} message 
 */
export function showSuccessToast(message) {
  toast.success(message)
}

/**
 * 
 * @param {*} message 
 */
export function showInfoToast(message) {
  toast.info(message)
}

/**
 * 
 * @param {*} message 
 */
export function showErrorToast(message) {
  toast.error((message) ? message : "An error has occured")
}

/**
 * 
 * @returns 
 */
export function generateUniqueId() {
  return parseInt(nanoid())
}

/**
 * 
 * @param {*} status 
 * @returns 
 */
export const getStatusTagClass = (status) => {
  switch (status) {
    case 'SUBMITTED':
    case 'PUBLISHED':
    case 'VALIDATED':
    case 'Présent':
    case 'Actif':
      return 'success'
    case 'PENDING':
    case 'CREATED':
    case 'DELETE_REQUESTED':
      return 'warning'
    case 'REJECTED':
    case 'DELETED':
    case 'Parti':
    case 'Inactif':
      return 'danger'
    default:
      return null
  }
}

/**
 * 
 * @param {*} property 
 * @returns 
 */
export const dynamicSort = (property) => {
  let sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }

  if (!property || property === 'null') property = "id"

  return function (a, b) {

    let itemA = a[property]
    let itemB = b[property]
    if (typeof itemA === "string" && typeof itemB === "string") {
      itemA = itemA.toLowerCase()
      itemB = itemB.toLowerCase()
    }

    let result = (itemA < itemB) ? -1 : (itemA > itemB) ? 1 : 0
    return result * sortOrder
  }
}

/**
 * 
 * @param {*} dateTime 
 * @returns 
 */
export function getMinutesFromDate(dateTime) {
  let time = 0;
  let date = new Date(dateTime);
  if (date.getHours()) time += date.getHours() * 60;
  if (date.getMinutes()) time += date.getMinutes();
  return time;
}

/**
 * 
 * @param {*} value 
 * @returns 
 */
export function isEmpty(value) {
  return typeof value === 'undefined' || value === null
}

/**
 * 
 * @param {*} formattedDate 
 * @param {*} timeMinutes 
 * @returns 
 */
export function setAndReturnTimeForDate(formattedDate, timeMinutes) {
  let date = moment(formattedDate, 'DD/MM/YYYY')
  date.set({ hour: parseInt(timeMinutes / 60), minute: parseInt(timeMinutes % 60), second: 0, millisecond: 0 })
  return parseInt(date.format('x'))
}

/**
 * A recursive function to compare two items
 * returns true if equal and false if not
 * @param {*} itemA 
 * @param {*} itemB 
 * @returns 
 */
export function checkIfEqual(itemA, itemB) {

  let equal = true
  function checkEquality(a, b, key) {
    if (typeof a == 'object' && typeof b == 'object' && a != null && b != null) {
      if (Object.keys(a).length !== Object.keys(b).length) {
        equal = false
      } else {
        for (const key in a) {
          if (equal) checkEquality(a[key], b[key], key)
        }
      }
    } else if (a !== b) {
      equal = false
    }
  }
  checkEquality(itemA, itemB)
  return equal
}

/**
 * Function to return the calendar days difference between two dates
 * @param {Date} fromDate 
 * @param {Date} toDate 
 * @returns 
 */
export function getCalendarDaysDifference(fromDate, toDate) {
  let daysDiff = 0
  while (formatDate(fromDate, "DD-MM-YYYY") !== formatDate(toDate, "DD-MM-YYYY")) {
    if (fromDate > toDate) {
      fromDate.setDate(fromDate.getDate() - 1)
    } else if (toDate > fromDate) {
      toDate.setDate(toDate.getDate() - 1)
    }
    daysDiff++
  }
  return daysDiff
}

/**
 * 
 * @param {*} userId 
 * @param {*} key 
 * @returns 
 */
export function getUserPreferences(userId, key) {
  let userPreferences = JSON.parse(localStorage.getItem("USER_PREFERENCES"))
  if (!userPreferences || !userPreferences[userId]) return null
  return userPreferences[userId][key]
}

/**
 * 
 * @param {*} userId 
 * @param {*} key 
 * @param {*} value 
 */
export function setUserPreferences(userId, key, value) {
  let userPreferences = JSON.parse(localStorage.getItem("USER_PREFERENCES"))
  if (!userPreferences) userPreferences = {}
  if (!userPreferences[userId] || userPreferences[userId] == null) userPreferences[userId] = {}
  userPreferences[userId][key] = value
  localStorage.setItem("USER_PREFERENCES", JSON.stringify(userPreferences))
}

/**
 * 
 * @param {date} date 
 */
export function getLastUpdatedFromDate(date) {
  if (date && ["number", "string"].includes(typeof date)) {
    return moment(date).format("DD MMM YYYY HH:mm")
  }
  return null
}

/**
 * 
 * @param {*} list 
 * @param {*} key 
 * @returns 
 */
export function generateMapByKey(list, key) {
  let mapList = {}
  if (list && Array.isArray(list)) {
    for (let i = 0; i < list.length; i++) {
      let item = list[i]
      const keyValue = item[key]
      if (!isEmpty(keyValue) && !mapList[keyValue])
        mapList[keyValue] = item
    }
  }
  return mapList
}

/**
 * Function to get user permission to a scope with type
 * @param {string} scope mandatory
 * @param {string} type optional | default = "view"
 * @param {Array|string} idTeam optional | default is null
 */
export function checkPermission(scope, type = config.permissionTypes.view, idTeam = null) {
  if (!scope) return false
  let teamIds
  if(idTeam){
    if (Array.isArray(idTeam)) {
      teamIds = idTeam
    } else {
      teamIds = [idTeam]
    }
  }
  if (store.getState().login.userRoleId <= 1)
    return true
  let userPermissions = store.getState().login.permissions
  if (userPermissions && Array.isArray(userPermissions))
    userPermissions = userPermissions.filter(p => {
      let allowedTypes = [config.permissionTypes.view]
      if (p.type == config.permissionTypes.edit) {
        allowedTypes.push(p.type)
      }

      if (scope && p.scope == scope && allowedTypes.includes(type) && (!teamIds || teamIds.filter(id => id == p.id_team).length)) {
        return true
      }
      return false
    })
  if (userPermissions && userPermissions.length)
    return true
  return false
}
export function noForFuture(month, year) {
  const currentDate = new Date();
  const inputDate = new Date(year, month - 1);

  if (inputDate.getTime() > currentDate.getTime()) {
    return false;
  } else {
    return true;
  }
}
export function pastYearConstrain(date) {
  const today = moment()
  const oneYearAgo = moment().subtract(1, 'years')
  const inputDate = moment(date)
  return inputDate.isSameOrAfter(oneYearAgo) && inputDate.isSameOrBefore(today)
}

export function blockageDaytoFutureMonth(from) {
  let now = moment()
  let blockageDay = store.getState().settings.blockageDay
  let monthEnd = moment(from, 'x').endOf('month').add(blockageDay, 'day')
  let monthStart = moment(from, 'x').startOf('month')
  let isEvaluationBlocked = now.isAfter(monthEnd) || now.isBefore(monthStart)
  //Enable 2024 as feature is getting launched
  isEvaluationBlocked = now.isBefore(monthStart) || monthStart.year() < 2024
  return !isEvaluationBlocked
}