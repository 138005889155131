import React from "react";
import styles from './Buttons.module.css';
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../app/utils/authConfig";
import Button from "react-bootstrap/Button";
import mslogo from "../../assets/images/microsoft.png";
import Langs from "../../app/lang/langs";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
    checkMSToken
} from '../../containers/login/loginSlice'

export const SignInButton = () => {
    const { instance, accounts, inProgress } = useMsal();
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated()

    function handleLogin(instance) {
        if (isAuthenticated) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                dispatch(checkMSToken(response))
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    dispatch(checkMSToken(response))
                });
            });
        } else {
            instance.loginRedirect(loginRequest).catch(e => {
                console.error(e);
            })
            /* instance.loginPopup(loginRequest).catch(e => {
                console.error(e);
            }) */
        }

    }

    return (
        <Button variant="secondary" className={styles.themeButton + " " + styles.loginButton}
            onClick={() => handleLogin(instance)}>
            <img src={mslogo} />
            <Langs str="CONNECT_WITH_MICROSOFT" />
        </Button>
    );
}