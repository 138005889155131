import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from './Buttons.module.css';
import Langs from "../../app/lang/langs";
import { SelectButton } from 'primereact/selectbutton';
import { setSelectedUserView } from '../../containers/common/commonSlice';

export function ViewSelectButton(props) {

    const dispatch = useDispatch()

    const handleViewChange = (event) => {
        if(props.onViewChange){
            props.onViewChange(event.value)
        }else{
            dispatch(setSelectedUserView(event.value))
        }
    }

    return <SelectButton
        value={props.selectedView }
        className="customSelectButton"
        height={30}
        onChange={handleViewChange}
        options={props.options} />
}