import React from 'react';
import Row from 'react-bootstrap/Row';
import styles from './Common.module.css';
const pjson = require('../../../package.json');

export class Footer extends React.Component {
    render() {
        return <Row className={styles.footer + " d-flex justify-content-center align-items-center text-center"}>
            <span>Groupe Vital 2024 - All rights reserved - {pjson.version}</span>
        </Row>
    }
}