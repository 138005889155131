import React, { useEffect, useRef, useState } from "react";
import { Col, OverlayTrigger, Popover } from "react-bootstrap";
import arrow from "../../assets/images/down-arrow.png";
import styles from "./Inputs.module.css";
import Langs from "../../app/lang/langs";

export function TransparentSelect(props) {

  const popoverRef = useRef()
  const buttonRef = useRef()

  const [showPopover, setShowPopover] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [isMulti, setIsMulti] = useState(props.value.length > 1 ? true : false)
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState(props.options)

  useEffect(()=>{
    if(search.trim()==''){
      setOptions(props.options)
    } else {
      setOptions(props.options.filter(option => option.label.toLowerCase().includes(search.toLowerCase())));
    }
  },[props.options])

  

  let timer

  const handleKeyPress = (event) => {
    if (event.key && event.key.length == 1) {
      let newSearchText = searchText + event.key
      if (props.options) {
        let foundOptions = props.options.filter((option) => {
          return option.label.toLowerCase().startsWith(newSearchText.toLocaleLowerCase())
        })
        if (foundOptions[0])
          document.getElementById(foundOptions[0].value)?.scrollIntoView({
            block: "center"
          })
      }
      setSearchText(newSearchText)
      if (timer) timer.invalidate()
      timer = setTimeout(() => {
        setSearchText("")
      }, 1000)
    }
  }

  const getSelectedValue = (event) => {
    let selectedId = props.value
    if (isMulti) {
      if (props.value && props.value.length) {
        if (props.value.length === 1)
          selectedId = props.value[0]
        else {
          return <Langs str="ITEMS_SELECTED" params={{ COUNT: props.value.length }} />
        }
      }
    }
    for (const key in props.options) {
      let option = props.options[key]
      if (selectedId == option.value) return option.label
    }
    return <Langs str="Select" />
  }
  
  function handleOptionsSearch(search, options){
    setSearch(search)
    if(search.trim()==''){
      setOptions(options);
    } else {
      setOptions(options.filter(option => option.label.toLowerCase().includes(search.toLowerCase())));
    }
  }

  function handleOptionsSearch(search, options){
    setSearch(search)
    if(search.trim()==''){
      setOptions(options);
    } else {
      setOptions(options.filter(option => option.label.toLowerCase().includes(search.toLowerCase())));
    }
  }

  function multiSelectHandler(value) {
    let selectedList = []
    if (isMulti) {
      let addValue = true
      if (props.value) {
        selectedList = props.value.filter(v => {
          if (v == value) {
            addValue = false
            return false
          }
          return true
        })
      }
      if (addValue) {
        selectedList.push(value)
      }
      if (!props.max || (props.max >= selectedList.length && props.min <= selectedList.length)) {
        props.onChange(selectedList, value)
      }
    } else {
      setShowPopover(false)
      selectedList = [value]
      props.onChange(selectedList, value)
    }
  }



  const isChecked = (option) => {
    if (props.value) {
      if (props.value.filter(v => v == option.value).length)
        return true
    }
    return false
  }

  const handleMultiClick = () => {
    if (isMulti && props.value.length > 1) {
      props.onChange([props.value[0]])
    }
    setIsMulti(!isMulti)
  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowPopover(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef])

  let uniqueCatgories = []

  return (
    <OverlayTrigger
      show={showPopover}
      key={"bottom"}
      placement={"bottom"}
      overlay={
        <Popover id="popover-positioned-bottom" className={styles.popover}>
          <Popover.Body
            ref={popoverRef}
            className={styles.transparentSelectPopover}
          >
            <Col className="m-0">
              <div className={styles.multiSelectOption}
                onClick={handleMultiClick}>
                <input type="checkbox"
                  checked={isMulti}
                  onChange={() => { }} />
                <label><Langs str={"MULTI_SELECTION"} /></label>
              </div>
            </Col>
            <Col className={`row my-2 align-items-center ps-2 pe-0 ${styles.searchBarTeams}`}>
              <Col lg={12} className="pe-0">
                <input 
                  className={`form-control mx-auto`}
                  value={search}
                  onChange={(e)=>{handleOptionsSearch(e.target.value, props.options)}}
                />
              </Col>
            </Col>
            {options &&
              options.map((option) => {
                let displayCategory = false
                if (option.category && !uniqueCatgories.includes(option.category)) {
                  displayCategory = true
                  uniqueCatgories.push(option.category)
                }
                return (
                  <Col key={"_" + option.value} className="m-0">
                    {displayCategory && <strong className="p-2"><Langs str={option.category} /></strong>}
                    {isMulti ?
                      <div className={styles.customSelectOption}
                        style={{
                          backgroundColor:
                            (searchText && option.label.toLowerCase().startsWith(searchText.toLocaleLowerCase()) ?
                              "var(--orange-50)"
                              : "transparent")
                        }}
                        id={option.value}
                        onClick={() => multiSelectHandler(option.value)}>
                        {isChecked(option) || !props.max || props.max > props.value.length ?
                          <input type="checkbox"
                            checked={isChecked(option)}
                            onChange={() => {
                            }} />
                          :
                          <div></div>
                        }
                        <label >
                          {option.label}
                        </label>
                      </div>
                      :
                      <button
                        id={option.value}
                        style={{
                          backgroundColor:
                            (searchText && option.label.toLowerCase().startsWith(searchText.toLocaleLowerCase()) ?
                              "var(--orange-50)"
                              :
                              props.value && props.value.includes(option.value)
                                ? "var(--lightGrey)"
                                : "transparent")
                        }}
                        className={styles.optionButton}
                        onClick={() => multiSelectHandler(option.value)}>
                        {option.label}
                      </button>
                    }
                  </Col>
                )
              })}
          </Popover.Body>
        </Popover>
      }
    >
      <button
        className={styles.transparentSelect}
        ref={buttonRef}
        onKeyDown={handleKeyPress}
        onClick={() => setShowPopover(!showPopover)}
      >
        {getSelectedValue()} <img src={arrow} width={15} height={15} />
      </button>
    </OverlayTrigger>
  )
}
 