import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import NetworkManager from "../../app/managers/networkManager"
import { config } from "../../app/utils/config"
import teletravailIcon from "../../assets/images/teletravail.png"
import deplacementIcon from "../../assets/images/deplacement.png"
import voitureIcon from "../../assets/images/voiture.png"
import { locale } from 'primereact/api'
import moment from "moment"

const initialState = {
  lang: localStorage.getItem("lang"),
  holidays: {},
  companies: [],
  settingsList: [],
  localSettingsList: [],
  changedSettingsValuesList: [],
  updatedSettingsParams: {},
  selectedSettingsList: [],
  blockageDay: null,
  isMobile: 1,
  parameters: {
    base_horaire_hebdo: 35,
    base_horaire_mensuelle: 151.67,
    heure_debut_journee: 6,
    heure_fin_journee: 21,
    majoration_heures_nuit: 0.5,
    taux_heures_supp: 1.25,
    taux_heures_samedi: 1.25,
    taux_heures_dimanche: 2,
    taux_heures_ferie: 1,
    taux_heures_samedi_ferie: 2,
    taux_heures_dimanche_ferie: 2,
    taux_heures_1mai: 2,
    taux_heures_samedi_1mai: 2,
    taux_heures_dimanche_1mai: 2,
  },
  selectedYear: new Date().getFullYear(),
  leavesColors: [
    "#BBFDBB",
    "#CCFDE9",
    "#B9E1FC",
    "#BADEEE",
    "#BBAFF9",
    "#F3B2EE",
    "#F3B2D5",
    "#F4BCB2",
    "#F9D8B4",
  ],
  shiftColors: [
    "#d6ebff",
    "#B9E1FC",
    "#BADEEE",
    "#d6d7ff",
    "#BBAFF9",
    "#bcbdf9",
    "#F3B2EE",
    "#ffd6eb",
    "#F3B2D5",
    "#fff5d6",
    "#ffe6cc",
    "#feddc7",
    "#fcc39b",
    "#f6de95",
    "#caf1d8",
    "#e4ffd9",
    "#BBFDBB",
    "#ADE6BA",
    "#d6fff5",
    "#CCFDE9",
    "#c7eeea",
    "#9ae0d9",
    "#6dd3c8",
    "#94e0ed"
  ],
  groupesColors: [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffc107",
    "#ff5722",
    "#795548",
    "#607d8b"
  ],
  shiftTypes: [
    {
      value: "p",
      label: "inSitu",
      // color: "#D9D9D9",
      roleId: 3
    },
    {
      value: "t",
      label: "Télétravail",
      color: "#f76808",
      icon: teletravailIcon,
      roleId: 3
    },
    {
      value: "v",
      label: "Déplacement",
      color: "#8e4ec6",
      icon: deplacementIcon,
      roleId: 3
    },
    {
      value: "f",
      label: "Déplacement avec Voiture Groupe Vital",
      color: "#a5326b",
      icon: voitureIcon,
      roleId: 3
    },
    {
      value: "R",
      label: "Repos",
      roleId: 2
    },
    {
      value: "AST",
      label: "Astreinte",
      color: "var(--blue-400)",
      roleId: 2
    },
    {
      value: "INT_AST",
      label: "Intervention Astreinte",
      color: "var(--blue-400)",
      roleId: 3
    }
  ],
}

export const getHolidays = createAsyncThunk(
  "planning/getHolidays",
  async (year) => {
    try {
      let response = await NetworkManager.getInstance().getRequest(
        config.apiPaths.planning.getHolidays + `?year=${year}`
      );
      return response
    } catch (error) {
      return error
    }
  }
)

export const getSettings = createAsyncThunk(
  "planning/getSettings",
  async () => {
    try {
      let response = await NetworkManager.getInstance().getRequest(
        config.apiPaths.planning.getSettings
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const updateSettings = createAsyncThunk(
  "planning/updateSettings",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.updateSettings,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const updateTeamSettings = createAsyncThunk(
  "planning/updateTeamSettings",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.updateTeamSettings,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLanguage: (state, action) => {
      state.lang = action.payload;
      localStorage.setItem("lang", action.payload)
      locale(state.lang)
      moment.locale(state.lang)
    },
    setSelectedYear: (state, action) => {
      state.selectedYear = action.payload;
    },
    setParameters: (state, action) => {
      state.parameters = action.payload;
    },
    setLocalSettings: (state, action) => {
      state.localSettingsList[action.payload["key"]].settingValue = action.payload["value"]
    },
    setChangedSettingsList: (state, action) => {
      if (state.changedSettingsValuesList.length > 0) {
        for (const key in state.changedSettingsValuesList) {
          if (state.changedSettingsValuesList[key].id == action.payload["id"]) {
            state.changedSettingsValuesList[key].value =
              action.payload["value"]
          } else if (
            state.selectedSettingsList.includes(action.payload["id"])
          ) {
            continue
          } else {
            state.changedSettingsValuesList.push({
              id: action.payload["id"],
              value: action.payload["value"],
            })
            state.selectedSettingsList.push(action.payload["id"])
          }
        }
      } else {
        state.changedSettingsValuesList.push({
          id: action.payload["id"],
          value: action.payload["value"],
        })
      }
      state.updatedSettingsParams = {
        fields: state.changedSettingsValuesList,
      }
      //Before Loop
      //   state.changedSettingsValuesList.push({id:action.payload['id'],value:action.payload['value']})
      //   state.updatedSettingsParams = {
      //       fields:state.changedSettingsValuesList
      //   }
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getHolidays.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.year) {
        state.holidays[action.payload.responseData.year] = []
        if (!state.holidays) state.holidays = {}
        state.holidays[action.payload.responseData.year] =
          action.payload.responseData.holidays
      }
    })
    builder.addCase(getSettings.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        if (action.payload.responseData["settings"]) {
          let settingsList = []
          for (const key in action.payload.responseData["settings"]) {
            let setting = action.payload.responseData["settings"][key]
            if (setting["label"] == "Jour du mois de blocage") {
              state.blockageDay = setting["value"]
            }
            settingsList.push({
              value: key,
              id: setting["id"],
              id_role: setting["id_role"],
              label: setting["label"],
              settingValue: setting["value"],
              type: setting["type"],
              min: setting["min"],
              max: setting["max"]
            })
          }
          state.settingsList = settingsList
          state.localSettingsList = settingsList
          state.changedSettingsValuesList = []
          state.selectedSettingsList = []
        }
        if (action.payload.responseData["companies"]) {
          let companiesList = []
          for (const key in action.payload.responseData["companies"]) {
            let company = action.payload.responseData["companies"][key]
            companiesList.push({
              value: company["id"],
              label: `${company["name"]}, ${company["localization"]}`
            })
          }
          state.companies = companiesList
        }
      }
    })
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      console.log("Settings updated successfully");
    })
    builder.addCase(updateTeamSettings.fulfilled, (state, action) => {
      console.log("Team Settings updated successfully");
    })
  },
})

export const {
  setLanguage,
  setSelectedYear,
  setParameters,
  setLocalSettings,
  setChangedSettingsList,
  setIsMobile,

} = settingsSlice.actions

export default settingsSlice.reducer
