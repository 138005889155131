import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import { Col, Row } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import '../../containers/settings/Modals/SettingsModal.css';
import { DynamicButton } from "../../components/buttons/DynamicButton";
import { setAddMembersModalVisibility, addRemoveProjectCollabsManagers, setAddMembersMode } from "./vcompSlice";

export default function AddMembersModal() {

    const dispatch = useDispatch();

    const visible = useSelector((state) => state.vcomp.addMembersModalVisibility);
    const collabsNames = useSelector((state) => state.vcomp.collabsNames);
    const collabsIds = useSelector((state) => state.vcomp.projectCollabs);
    const managersIds = useSelector((state) => state.vcomp.projectManagers);
    const onCreateProject = useSelector((state) => state.vcomp.onCreateProject);
    const isMobile = useSelector((state) => state.settings.isMobile);
    const mode = useSelector((state) => state.vcomp.addMembersMode);



    const [collabsList, setCollabsList] = useState([])
    const [mangersList, setMangersList] = useState([])

    const [associatedMembers, setAssociatedMembers] = useState([])

    const handleClose = () => {
        dispatch(setAddMembersModalVisibility(false));
        dispatch(setAddMembersMode(null))
        setAssociatedMembers([])
    }

    useEffect(() => {
        setCollabsList(collabsNames.filter(collab => !collabsIds.includes(collab.id)).map(({ id, nom, prenom }) => ({
            id,
            fullName: `${nom} ${prenom}`
        })));
        setMangersList(collabsNames.filter(collab => !managersIds.includes(collab.id)).map(({ id, nom, prenom }) => ({
            id,
            fullName: `${nom} ${prenom}`
        })))
    }, [managersIds, collabsIds])



    const handleAdd = () => {
        const addedCollabsIds = associatedMembers.map( m => m.id);
        if(!mode){
            dispatch(addRemoveProjectCollabsManagers({id_project: onCreateProject.id, added_collabs: addedCollabsIds }))
        }else{
            dispatch(addRemoveProjectCollabsManagers({id_project: onCreateProject.id, added_managers: addedCollabsIds }))
        }
        handleClose()
    }



    //html

    const header = () => {
        if(!mode){
            return (
                <div>
                    <Langs str='ADD_MEMBERS' />
                </div>
            )
        }else{
            return (
                <div>
                    <Langs str='ADD_MANAGERS' />
                </div>
            )
        }
    }

    const body = () => {
        return (
            <div className="p-2">
                <Row className="mt-2">
                    <Row className="mt-2 p-2 pb-3">
                        <Col lg={3} className="my-3 fw-bold">
                            <Langs str='Associated Members' />
                        </Col>
                        <Col lg={9} className="d-flex justify-content-end">
                            <MultiSelect
                                value={associatedMembers}
                                onChange={(e) => setAssociatedMembers(e.value)}
                                options={mode ? mangersList : collabsList}
                                optionLabel="fullName"
                                display="chip"
                                filter
                                placeholder="Select to add..."
                                className='customMultiselect'
                            />
                        </Col>
                    </Row>
                </Row>
            </div>
        )
    }
    const footer = () => {
        return (
            <Row className="d-flex justify-content-center border-top pt-3">
                <Col lg={3} xs={3}>
                    <DynamicButton
                        onClick={() => handleAdd()}
                        className=''
                        title={<Langs str="ADD" />}
                        active={true}
                        color="green"
                    />
                </Col>
            </Row>
        )
    }


    return (
        <div>
            <Dialog
                header={header}
                footer={footer}
                visible={visible}
                style={isMobile !== 2 ? {width: '50vw'} : {width: '100vw'} }
                draggable={false}
                onHide={() => { if (!visible) return; handleClose() }}>
                {body()}
            </Dialog>
        </div>
    )
}