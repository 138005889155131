import React from 'react';
import styles from './Inputs.module.css';

export function NumberInput(props) {

    return <input
        id={props.id}
        min={props.min}
        max={props.max}
        // step=".05"
        type="number"
        className={styles.inputNumber}
        name={props.name}
        placeholder=""
        onChange={props.onChange}
        // onKeyDown={(props.disableKeyboard == true) ?(event) => {
        //      event.preventDefault() 
        //   }: () =>{}}
        value={props.value} />
}