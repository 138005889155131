import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import styles from './Collabs.module.css';
import { Dialog } from 'primereact/dialog';
import Langs from "../../app/lang/langs";
import { getFormattedDate, isAdmin, isOwner } from '../../app/utils/helpers';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { setCollabReport, updateCollabReport } from '../../containers/reports/reportsSlice';
import { InputWithSubmit } from '../inputs/InputWithSubmit';
import { insertPrimeCollab } from '../../containers/planning/planningSlice';
import { Button } from 'primereact/button';
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/images/logo_main.png";
import logoVitalGrp from "../../assets/images/logo-VITAL_GRP.png";
import logoVitalInf from "../../assets/images/logo-VITAL_INF.png";
import logoVitalIt from "../../assets/images/logo-VITAL_IT.png";
import logoVitalIng from "../../assets/images/logo-VITAL_ING.png";

import { generateCraPdf } from './collabsSlice';

const logos = {
    "VITAL_GRP": logoVitalGrp,
    "VITAL_GRP_2": logoVitalGrp,
    "VITAL_ING": logoVitalIng,
    "VITAL_INF": logoVitalInf,
    "VITAL_IT": logoVitalIt
}

export function CollabModal(props) {

    const dispatch = useDispatch()

    const teams = useSelector((state) => state.teams)
    const settings = useSelector((state) => state.settings)
    const collabReport = useSelector((state) => state.reports.collabReport)
    const selectedDate = useSelector((state) => state.planning.selectedDate)
    const userRoleId = useSelector((state) => state.login.userRoleId)
    const isMobile = useSelector((state) => state.settings.isMobile)
    const selectedFormData = useSelector((state) => state.planning.selectedFormData)
    const [maximized, setMaximized] = useState(false)
    const mounted = useRef(null)
    

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        }
    })

    useEffect(() => {

    }, [collabReport])

    const handleMaximize = (e, dialog) => {
        setMaximized(e.maximized)
    }

    function onHide() {
        setMaximized(false)
        if (props.onHide) props.onHide()
        dispatch(setCollabReport(null))
    }

    function handleImpressCra(selectedType) {
        const monthDate = moment(selectedDate, "x").format("MM-YYYY");
        const fileName = `CRA_${monthDate}.pdf`
        let type = "COMPTA"
        if (selectedType) type = selectedType
        // Appel de l'action pour générer le pdf avec selectedFormData et CollabReport
        dispatch(generateCraPdf({
            id_collab: selectedFormData.id,
            id_team: selectedFormData.id_team,
            monthDate: monthDate,
            selectedDate: selectedDate,
            fileName: fileName,
            type: type
        }))
    }

    function generateCollabData() {
        let data = []
        let startDate = moment(selectedDate, "x").startOf("month")
        let endDate = moment(selectedDate, "x").endOf("month")
        while (endDate.isAfter(startDate)) {
            let rowData = {
                dateTime: startDate.format("x"),
                day: startDate.format("dddd"),
                date: startDate.format("D-MMM"),
                planning: "",
                vacation: "",
                intervention: "",
                description: "",
                astreinte: "",
                text: ""
            }
            if (collabReport && collabReport.planning) {
                let planningData = collabReport.planning[startDate.format("DD-MM-YYYY")]
                if (planningData) {
                    rowData.planning = planningData.type
                    rowData.vacation = planningData.vacation || ""
                    rowData.intervention = planningData.intervention || ""
                    rowData.astreinte = planningData.astreinte
                    rowData.color = planningData.color || ""
                    rowData.description = planningData.description || ""
                    rowData.text = planningData.text || ""
                }
            }
            data.push(rowData)
            startDate.add(1, "day")
        }
        return data
    }


    let collabData = generateCollabData()

    const rowClass = (data) => {
        let className = {}
        let date = moment(data.dateTime, "x")

        let weekDay = date.day()
        if (weekDay == 0) className = {
            'bg-sunday': true
        }
        else if (weekDay == 6) className = {
            'bg-saturday': true
        }
        else {
            let formattedDate = date.format("DD-MM-YYYY")
            if (settings.holidays[date.year()])
                for (let i = 0; i < settings.holidays[date.year()].length; i++) {
                    const holiday = settings.holidays[date.year()][i]
                    if (formattedDate === holiday.date)
                        className = {
                            'bg-holiday': true
                        }
                }
        }
        return className
    }

    function getTeamLabelFromId(teamId) {
        let label = ""
        let teamsFiltered = teams.list && teams.list.filter(t => t.id == teamId)
        if (teamsFiltered && teamsFiltered[0]) {
            label = teamsFiltered[0].label
        }
        return label
    }

    const astreinteCellStyle = (data) => {
        if (data.color) {
            return <Col style={{ backgroundColor: data.color, color: "white", textAlign: 'center', fontSize: '14px' }}>
                <Langs str={data.text || "-"} />
            </Col>
        }
    }

    const handlePrimeSubmit = (event) => {

        let value = Number(event.target.value)
        let month = moment(selectedDate).format("MM-YYYY")
        if (typeof value != 'number' || !month) return

        let primeParams = {
            id_collab: selectedFormData.id,
            type: event.target.id,
            value: value,
            month: month
        }

        dispatch(insertPrimeCollab(primeParams))
        let primes = Object.assign({}, collabReport.primes)
        primes[event.target.id] = value
        dispatch(updateCollabReport({
            key: "primes",
            value: primes
        }))
    }

    const generatePdf = () => {

        console.log("props data " + JSON.stringify(selectedFormData))
        let format = [250, 340]
        let orientation = 'portrait'

        if (isOwner(userRoleId)) {
            console.log("Owner");
            format = [350, 400]
            orientation = 'landscape'
        }

        console.log("Generating PDF...");
        const doc = new jsPDF({
            unit: 'mm',
            orientation: orientation,
            format: format
        });

        // Création d'une nouvelle image avec le chemin du logo
        const logoImg = new Image();
        // Spécification du chemin de l'image
        logoImg.src = logos[selectedFormData.id_company] || logo;
        // Une fois l'image chargée, l'ajouter au PDF aux coordonnées spécifiées avec une taille de 50x50
        doc.addImage(logoImg, 'PNG', 10, 5, 45, 15);

        // Ajouter un en-tête
        const headerText = moment(selectedDate, "x").format("MMMM YYYY") + " - " + selectedFormData.label
        doc.text(headerText, 60, 15);

        const headerHeight = doc.getTextDimensions(headerText).h;

        const table1 = document.querySelector("#collab-table-1 .p-datatable-table");
        const table2 = document.getElementById("collab-table-2");

        // Cloner les tableau
        const table1Copy = table1.cloneNode(true);
        const table2Copy = table2.cloneNode(true);

        // Parcourir les cellules de la copie et remplacer les "-" par "OUI"
        const tableCellsCopy = table1Copy.querySelectorAll('td');
        tableCellsCopy.forEach(cell => {
            if (cell.textContent.trim() === "-") {
                cell.textContent = "OUI";
            }
        });

        const availableWidth = doc.internal.pageSize.width - 20; // Marge de 10 unités à gauche et à droite
        let tableWidth = availableWidth // Largeur de chaque tableau

        const highlightedRows = [];
        let alternateColorIndex = 0;

        if (isOwner(userRoleId)) {
            tableWidth = availableWidth / 2; // Largeur de chaque tableau
        }
        // Premier tableau
        doc.autoTable({
            html: table1Copy,
            startY: headerHeight + 17, // Démarre à 10 unités du haut de la page
            margin: { top: 10, right: 5 }, // Marge supplémentaire en haut et à droite pour laisser de l'espace
            theme: 'grid', // Thème avec des bordures
            styles: { overflow: 'linebreak' }, // Gestion du débordement de texte
            columnStyles: { 4: { cellWidth: 'auto' } }, // Ajustement automatique de la largeur de la colonne
            tableWidth: tableWidth - 10, // Définir la largeur du tableau
            didParseCell: function (data) {
                if (data.cell && data.cell.raw.tagName === 'TH' && data.cell.section === 'head') {
                    data.cell.styles.fillColor = [248, 249, 250];
                    data.cell.styles.textColor = 'black';
                    const columnIndex = data.column.index;
                    const totalColumns = data.table.columns.length;
                    if (columnIndex === totalColumns - 1) {
                        // Appliquer le background-color à la cellule
                        data.cell.styles.fillColor = '#feddc7';
                    }
                }
                // Vérifier si c'est la dernière colonne de la ligne
                if (data.section === 'body') {
                    const columnIndex = data.column.index;
                    const totalColumns = data.table.columns.length;
                    if (columnIndex === totalColumns - 1) {
                        // Appliquer le background-color à la cellule
                        data.cell.styles.fillColor = '#feddc7';
                    }
                }
                // Vérifier si le texte de la cellule contient "samedi"
                if (data.cell.text && data.cell.text[0].includes("samedi")) {
                    // Appliquer la couleur spécifiée à la cellule
                    data.cell.styles.fillColor = '#ffd0ce';
                    console.log("Index de la ligne contenant samedi ou dimanche :", data.row.index); // Stocker l'index de la ligne
                }

                // Vérifier si le texte de la cellule contient "dimanche"
                if (data.cell.text && data.cell.text[0].includes("dimanche")) {
                    // Appliquer la couleur spécifiée à la cellule
                    data.cell.styles.fillColor = '#ffaca7';
                    console.log("Index de la ligne contenant samedi ou dimanche :", data.row.index); // Stocker l'index de la ligne
                }
            }

        })

        // Récupérer et afficher les valeurs des éléments input dans la copie du tableau
        table2Copy.querySelectorAll('input').forEach(input => {
            const id = input.id;
            const value = input.value;
            // Trouver le th correspondant à l'ID de l'input dans la copie et mettre la valeur à la place de l'input
            const correspondingTh = table2Copy.querySelector(`th#${id}`);
            if (correspondingTh) {
                correspondingTh.innerHTML = value;
            }
            // Remplacer l'input par une cellule td contenant la valeur
            const td = document.createElement('td');
            td.textContent = value;
            input.parentNode.replaceChild(td, input);
        });

        if (isOwner(userRoleId)) {

            doc.setPage(1);

            // Créer une nouvelle instance de jsPDF autoTable pour générer le tableau dans le PDF
            doc.autoTable({
                html: table2Copy,
                startY: headerHeight + 17, // Démarre à 10 unités du haut de la page
                margin: { top: 10, left: tableWidth + 15 }, // Marge supplémentaire en haut et à gauche pour placer le tableau à côté du premier
                theme: 'grid', // Thème avec des bordures
                styles: { overflow: 'linebreak' }, // Gestion du débordement de texte
                columnStyles: { 4: { cellWidth: 'auto' } }, // Ajustement automatique de la largeur de la colonne
                tableWidth: tableWidth - 10, // Définir la largeur du tableau
                didParseCell: function (data) {
                    if (data.cell && data.cell.raw.tagName === 'TH' || data.cell.section === 'head') {
                        // Changer la couleur du texte des en-têtes de colonnes en bleu
                        data.cell.styles.fillColor = '#d3d3d3';
                        data.cell.styles.textColor = 'black';
                    }
                }
            });

        } else {
            doc.setFontSize(14);
            doc.text(`Total jours : ${collabReport && collabReport.presence}`, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 50);
        }

        // Ajouter le jours d'export
        doc.setFontSize(12);
        doc.text(`Le ${moment().format("DD/MM/YYYY")}`, 15, doc.internal.pageSize.height - 20);

        // Ajout de la zone de signature pour le client
        // Ajout de la zone de signature pour le client
        doc.rect(doc.internal.pageSize.width - 90, doc.internal.pageSize.height - 35, 75, 25, 'S');
        doc.setFontSize(10); // Définir la taille de la police
        doc.text('Client:', doc.internal.pageSize.width - 88, doc.internal.pageSize.height - 30);

        // Ajout de la zone de signature pour le collaborateur
        doc.rect(doc.internal.pageSize.width - 170, doc.internal.pageSize.height - 35, 75, 25, 'S');
        doc.setFontSize(10); // Définir la taille de la police
        doc.text('Collaborateur:', doc.internal.pageSize.width - 168, doc.internal.pageSize.height - 30);

        const selectedDateObj = moment(selectedDate, "x");
        const month = selectedDateObj.format("MM");
        const year = selectedDateObj.format("YYYY");

        const fileName = `CRA-${month}-${year}.pdf`;
        doc.save(fileName);

    }


    function getMaximizedView() {
        return <div><Row className='d-flex justify-content-center align-items-top' id='CRA'>
            <Col lg={7}>
                <DataTable id="collab-table-1" value={collabData} size={"small"} showGridlines rowClassName={rowClass}>
                    <Column header="Jour" field="day" style={{ minWidth: '5rem', overflow: 'hidden' }}></Column>
                    <Column header="Date" field="date" style={{ minWidth: '5rem', overflow: 'hidden' }}></Column>
                    <Column header="Vacation" field="vacation" style={{ minWidth: '5rem', overflow: 'hidden' }}></Column>
                    <Column header="Intervention" field="intervention" style={{ minWidth: '6rem', overflow: 'hidden' }}></Column>
                    <Column header="Commentaires" field="description" style={{ minWidth: '9rem', maxWidth: '12rem', overflow: 'hidden' }}></Column>
                    <Column header="Astreinte" body={astreinteCellStyle} style={{ minWidth: '5rem', backgroundColor: "var(--orange-100)", padding: "0 0", overflow: 'hidden' }}></Column>
                </DataTable>
            </Col>
            <Col lg={5}>
                <Row className='d-flex justify-content-center align-items-center p-0 m-0'>
                    <table className={styles.ctable} id="collab-table-2" >
                        <thead>
                            <tr>
                                <th><Langs str='NAME' /></th>
                                <td colSpan="3">
                                    {selectedFormData.label}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='COMPANY' /></th>
                                <td colSpan="3">
                                    {selectedFormData.company}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='MATRICULE' /></th>
                                <td colSpan="3">
                                    {selectedFormData.matricule}
                                </td>
                            </tr>
                            {selectedFormData.ancienneteeDate ? <tr>
                                <th><Langs str='DATE_ANCIENNETE' /></th>
                                <td colSpan="3">
                                    {getFormattedDate(selectedFormData.ancienneteeDate)}
                                </td>
                            </tr>
                                :
                                selectedFormData.entryDate && <tr>
                                    <th><Langs str='ENTRY_DATE' /></th>
                                    <td colSpan="3">
                                        {getFormattedDate(selectedFormData.entryDate)}
                                    </td>
                                </tr>}
                            {selectedFormData.exitDate && <tr>
                                <th><Langs str='EXIT_DATE' /></th>
                                <td colSpan="3" className='text-danger'>
                                    {getFormattedDate(selectedFormData.exitDate)}
                                </td>
                            </tr>}
                            <tr>
                                <th><Langs str='CLIENT' /></th>
                                <td colSpan="3">
                                    {collabReport.clientTeamName}
                                </td>
                            </tr>
                            <tr className={styles.tableSeperator} />
                        </thead>
                        <tbody>
                            <tr>
                                <th colSpan={4}>
                                    <Langs str='RECAPITULATIF DES HEURES' />
                                </th>
                            </tr>
                            <tr>
                                <th><Langs str='Normales' /></th>
                                <td>
                                    {collabReport && collabReport.normal}
                                </td>
                                <td>
                                    {collabReport && collabReport.normal}
                                </td>
                                <th rowSpan="7" colSpan={1}>
                                    {collabReport && collabReport.total}
                                </th>
                            </tr>
                            <tr>
                                <th><Langs str='Nuit' /></th>
                                <td>
                                    {collabReport && collabReport.nuit}
                                </td>
                                <td>
                                    {collabReport && collabReport.nuit_}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Samedi' /></th>
                                <td>
                                    {collabReport && collabReport.saturday}
                                </td>
                                <td>
                                    {collabReport && collabReport.saturday_}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Dimanche' /></th>
                                <td>
                                    {collabReport && collabReport.hs100}
                                </td>
                                <td>
                                    {collabReport && collabReport.hs100_}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Supp' /></th>
                                <td>
                                    {collabReport && collabReport.supp}
                                </td>
                                <td>
                                    {collabReport && collabReport.supp_}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Fériées 100%' /></th>
                                <td >
                                    {collabReport && collabReport.hs00}
                                </td>
                                <td>
                                    {collabReport && collabReport.hs00}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Fériées 200%' /></th>
                                <td>
                                    {collabReport && collabReport.hs200}
                                </td>
                                <td>
                                    {collabReport && collabReport.hs200_}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Repos' /></th>
                                <th colSpan={3}>{collabReport && collabReport.repos}</th>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell1}><Langs str='Prime Astreinte' /></th>
                                <td className={styles.primaryCell1} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['ASTREINTE'] || 0}
                                        type='Number'
                                        id='ASTREINTE'
                                        min={0}
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell2}><Langs str='Prime Week-end' /></th>
                                <td className={styles.primaryCell2} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['WEEKEND'] || 0}
                                        type='Number'
                                        id='WEEKEND'
                                        min={0}
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell3}><Langs str='Prime Mission' /></th>
                                <td className={styles.primaryCell3} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['MISSION'] || 0}
                                        type='Number'
                                        id='MISSION'
                                        min={0}
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell1}><Langs str='Prime Qualité' /></th>
                                <td className={styles.primaryCell1} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['QUALITE'] || 0}
                                        type='Number'
                                        min={0}
                                        id='QUALITE'
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell1}><Langs str='Prime Objectif' /></th>
                                <td className={styles.primaryCell1} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['OBJECTIF'] || 0}
                                        type='Number'
                                        min={0}
                                        id='OBJECTIF'
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell2}><Langs str='Prime Quadri' /></th>
                                <td className={styles.primaryCell2} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['QUADRI'] || 0}
                                        type='Number'
                                        min={0}
                                        id='QUADRI'
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell3}><Langs str='Prime Noel' /></th>
                                <td className={styles.primaryCell3} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['NOEL'] || 0}
                                        type='Number'
                                        min={0}
                                        id='NOEL'
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.primaryCell1}><Langs str='Frais Téléphonique' /></th>
                                <td className={styles.primaryCell1} colSpan="3">
                                    <InputWithSubmit
                                        value={collabReport.primes['FRAIS_TEL'] || 0}
                                        type='Number'
                                        id='FRAIS_TEL'
                                        min={0}
                                        onSubmit={handlePrimeSubmit}
                                        disabled={!isOwner(userRoleId)} />
                                </td>
                            </tr>
                            <tr>
                                <th colSpan="1" rowSpan="2"><Langs str='PAIE AVANT ABSENCES' /></th>
                                <th colSpan="1" rowSpan="1"><Langs str='Salaire Base' /></th>
                                <th colSpan="1" rowSpan="1"><Langs str='Taux horaire' /></th>
                                <th colSpan="1" rowSpan="1"><Langs str='Salaire Brut' /></th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <th><Langs str='Maladie' /></th>
                                <td>{collabReport && collabReport.maladie}</td>
                                <th rowSpan={2} colSpan={2}></th>
                            </tr>
                            <tr>
                                <th><Langs str='Congés Payés' /></th>
                                <td>{collabReport && collabReport.cp}</td>
                            </tr>
                            <tr>
                                <th><Langs str='Maternity' /></th>
                                <td>{collabReport && collabReport.maternity}</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <th><Langs str='Absence' /></th>
                                <td>{collabReport && collabReport.ai}</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <th><Langs str='Congés ss Solde' /></th>
                                <td>{collabReport && collabReport.css}</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <th><Langs str='Heures de retard' /></th>
                                <td>{collabReport && collabReport.missing}</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <th rowSpan={2}>
                                    <Langs str='CALCUL PAIE' />
                                </th>
                                <th><Langs str='Salaire Base' /></th>
                                <th><Langs str='Taux horaire' /></th>
                                <th><Langs str='Salaire Brut' /></th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr className={styles.tableSeperator} />
                            <tr className='m-4'>
                                <th><Langs str='Nb Jours travaillés' /></th>
                                <td colSpan={3}>
                                    {collabReport && collabReport.presence}
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={4}><Langs str='FRAIS' /></th>
                            </tr>
                            <tr>
                                <th><Langs str='Frais de Repas' /></th>
                                <td colSpan={3}>
                                    {collabReport && collabReport.repas}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Frais Transport' /></th>
                                <td colSpan={3}>
                                    {collabReport && collabReport.transport}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Forfait Déplacement' /></th>
                                <td colSpan={3}>
                                    {collabReport && collabReport.deplacement}
                                </td>
                            </tr>
                            <tr>
                                <th><Langs str='Télétravail' /></th>
                                <td colSpan={3}>
                                    {collabReport && collabReport.telework}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <br></br>
                <Button label={<Langs str="Générer CRA PDF" />}
                    icon="pi pi-file-pdf"
                    onClick={() => handleImpressCra("CRA")} />
                <span> </span>
                {isOwner(userRoleId) && <Button label={<Langs str="Générer Compta PDF" />}
                    icon="pi pi-file-pdf"
                    onClick={() => handleImpressCra()} />}
            </Col >
        </Row>
        </div>
    }

    function getMinimizedView() {
        return <div>
            <Row className='d-flex justify-content-center align-items-center p-2'>
                <Col lg={6}>
                    <label><Langs str='FAMILY_NAME' /></label>
                </Col>
                <Col lg={6}>
                    <div>{selectedFormData.nom}</div>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center align-items-center p-2'>
                <Col lg={6}>
                    <label><Langs str='NAME' /></label>
                </Col>
                <Col lg={6}>
                    <div>{selectedFormData.prenom}</div>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center align-items-center p-2'>
                <Col lg={6}>
                    <label><Langs str='POSTE' /></label>
                </Col>
                <Col lg={6}>
                    <div>{selectedFormData.poste}</div>
                </Col>
            </Row>
            {/*<Row className='d-flex justify-content-center align-items-center p-2'>
                <Col lg={6}>
                    <label><Langs str='MOBILE' /></label>
                </Col>
                <Col lg={6}>
                    <div>{selectedFormData.mobileNumber}</div>
                </Col>
            </Row>*/}
            {selectedFormData.entryDate && <Row className='d-flex justify-content-center align-items-center p-2'>
                <Col lg={6}>
                    <label><Langs str='ENTRY_DATE' /></label>
                </Col>
                <Col lg={6}>
                    <div>{getFormattedDate(selectedFormData.entryDate)}</div>
                </Col>
            </Row>}
            <Row className='d-flex justify-content-center align-items-center p-2'>
                <Col lg={6}>
                    <label><Langs str='STATUS' /></label>
                </Col>
                <Col lg={6}>
                    <div style={{ color: (["Présent", "Actif"].includes(selectedFormData.statut) ? "green" : "red") }}>
                        {selectedFormData.statut}
                    </div>
                </Col>
            </Row>
            {selectedFormData.exitDate && <Row className='d-flex justify-content-center align-items-center p-2'>
                <Col lg={6}>
                    <label><Langs str='EXIT_DATE' /></label>
                </Col>
                <Col lg={6} className='text-danger'>
                    <div>{getFormattedDate(selectedFormData.exitDate)}</div>
                </Col>
            </Row>}
        </div>
    }

    let dialogWidth;
    if (isMobile === 2) {
        dialogWidth = '80%';
    } else if (isMobile == 3) {
        dialogWidth = '40%'; // Adjust as necessary for tablet
    } else {
        dialogWidth = '40vh';
    }

    return <Dialog
        onHide={() => onHide()}
        header={maximized ? moment(selectedDate, "x").format("MMMM YYYY") : selectedFormData.label}
        visible={props.show}
        maximizable={collabReport != null}
        maximized={maximized}
        onMaximize={handleMaximize}
        style={{ width: dialogWidth }}>
        {maximized ? getMaximizedView() : getMinimizedView()}
    </Dialog>
}