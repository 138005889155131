import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import moment from "moment";
import { setIsLoading } from "../common/commonSlice";
import { store } from "../../app/store/store";

const initialState = {
  list: [],
  selectedView: 0,
  selectedDate: null,
  publications: [],
  collabReport: null
}

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedView: (state, action) => {
      state.selectedView = action.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    setCollabReport: (state, action) => {
      state.collabReport = action.payload
    },
    updateCollabReport: (state, action) => {
      if (action.payload.key) {
        state.collabReport[action.payload.key] = action.payload.value
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReports.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        if (action.payload.responseData["reports"]) {
          let reportsList = []
          for (const key in action.payload.responseData["reports"]) {
            let report = action.payload.responseData["reports"][key]
            reportsList.push({
              id: report["id"],
              date: report["date"],
              filename: report["filename"],
              company: report["company"] || "All",
              team: report["team"] || "All",
              status: report["status"],
              isSilae: report["is_silae"],
              description: report["description"],
              createdBy: report["createdBy"],
              createdAt: report['created_at'] ? moment(report['created_at']).format("HH:mm DD-MM-YYYY") : "-"
            })
          }
          state.list = reportsList
        }
        if (action.payload.responseData["publications"]) {
          let publications = []
          for (const key in action.payload.responseData["publications"]) {
            let publication = action.payload.responseData["publications"][key]
            publications.push({
              id: publication["id"],
              name: publication["name"],
              createdBy: publication["createdBy"] || "-",
              createdAt: publication.created_at ? moment(publication.created_at).format("HH:mm DD-MM-YYYY") : "-",
              status: publication['status'] || "NONE"
            })
          }
          state.publications = publications
        }
      }
    })
    builder.addCase(generateReport.fulfilled, (state, action) => {

    })
    builder.addCase(getCollabReport.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData["data"]) {
        state.collabReport = action.payload.responseData["data"]
      } else {
        state.collabReport = null
      }
    })
  },
})

export const getReports = createAsyncThunk(
  "planning/getReports",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getReports, body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const generateReport = createAsyncThunk(
  "planning/generateReport",
  async (body) => {
    try {
      store.dispatch(setIsLoading(true))
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.generateReport,
        body
      )
      store.dispatch(setIsLoading(false))
      return response
    } catch (error) {
      return error
    }
  }
)

export const getCollabReport = createAsyncThunk(
  "planning/getCollabReport",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getCollabReport,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const {
  setSelectedView,
  setSelectedDate,
  setCollabReport,
  updateCollabReport
} = reportsSlice.actions

export default reportsSlice.reducer
