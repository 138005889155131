import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from './EvaluationsView.module.css';
import { Col, Row } from "react-bootstrap";
import Langs from "../../app/lang/langs";
import { TeamSelector } from "../../components/teams/TeamSelector";
import { DataTable } from "primereact/datatable";
import { config } from "../../app/utils/config";
import { Column } from "primereact/column";
import { checkPermission, showErrorToast, showSuccessToast } from "../../app/utils/helpers";
import {
    getEvaluationsReport,
    setEvaluationAddModal,
    setOnEvaluateCollab,
    setOnEvaluateDate,
    setOnEvaluateTeamId,
    setAddResponse,
    setEditResponse,
    setSelectedTypeGeneric,
    setSelectedType,
    getEvaluationsTypes,
    setAllowedToEdit
} from "../../components/evaluations/evaluationsSlice";
import { Calendar } from "primereact/calendar";
import AddEvaluationModal from "./modals/AddEvaluationModal";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ImageButton from "../../components/buttons/ImageButton";
import exportIcon from "../../assets/images/export.png";
import exportIconFocus from "../../assets/images/exportFocus.png";
import viewOnlyIcon from "../../assets/images/viewOnly.png"
import viewOnlyIconFocus from "../../assets/images/viewOnlyFocus.png"
import editOnlyIcon from "../../assets/images/editOnly.png"
import editOnlyIconFocus from "../../assets/images/editOnlyFocus.png"
import ExcelManager from "../../app/managers/excelManager";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";


export default function EvaluationsView() {

    const dispatch = useDispatch()

    const selectedTeams = useSelector((state) => state.teams.selectedTeamsIds)
    const reports = useSelector((state) => state.evaluations.report)
    const myTeam = useSelector((state) => state.teams.myTeam)
    const addEvaluationRes = useSelector((state) => state.evaluations.addResponse)
    const editEvaluationRes = useSelector((state) => (state.evaluations.editResponse))
    const types = useSelector((state) => state.evaluations.genericTypes)
    const selectedType = useSelector((state) => state.evaluations.selectedTypeGeneric)
    const isMobile = useSelector((state) => state.settings.isMobile)

    const dtEvaluations = useRef(null)

    const [groupedReports, setGroupedReports] = useState([])
    const [processedReports, setProcessedReports] = useState([])
    const [expandedRowsCollabs, setExpandedRowsCollabs] = useState([])
    const [date, setDate] = useState(new Date(Date.now()))
    const [year, setYear] = useState(null)
    const [allowedTeams, setAllowedTeams] = useState([])
    const [onViewTeam, setOnViewTeam] = useState([])
    const [summarizedReport, setSummarizedReport] = useState()
    const [selectedTeamId, setSelectedTeamId] = useState()

    //getting reports on year and team change
    function allowedTeamsFunction(ids) {
        const allowedIds = []
        ids.forEach(id => {
            if (checkPermission(config.scopes.evaluations, config.permissionTypes.view, id)) {
                allowedIds.push(id)
            }
        })
        return allowedIds
    }

    function summarizeReports(processedReports) {
        const teamSummaries = {};
        processedReports.forEach(report => {
            const teamName = report.team_name;
            const teamId = report.id_team;

            if (!teamSummaries[teamName]) {
                teamSummaries[teamName] = {
                    id_team: teamId,
                    data: {}
                };
            }
            Object.keys(report).forEach(key => {
                if (!['nom', 'prenom', 'id_team', 'team_name', 'id_collab'].includes(key)) {
                    if (!teamSummaries[teamName].data[key]) {
                        teamSummaries[teamName].data[key] = { evaluated: 0, total: 0 }
                    }
                    teamSummaries[teamName].data[key].total++;
                    if (report[key] !== "no evaluation") {
                        teamSummaries[teamName].data[key].evaluated++
                    }
                }
            })
        })

        const output = []
        Object.keys(teamSummaries).forEach(team => {
            const teamInfo = teamSummaries[team]
            const teamData = {
                team_name: team,
                id_team: teamInfo.id_team,
            };
            Object.keys(teamInfo.data).forEach(month => {
                const data = teamInfo.data[month];
                teamData[month] = `${data.evaluated}/${data.total}`
            })
            output.push(teamData)
        })
        return output
    }

    useEffect(() => {
        if (selectedTeams && selectedTeams.length) {
            dispatch(getEvaluationsTypes({ idTeam: selectedTeams }))
            setAllowedTeams(allowedTeamsFunction(selectedTeams))
        }
    }, [selectedTeams])

    useEffect(() => {
        if (year && selectedType && allowedTeams && allowedTeams.length) {
            dispatch(getEvaluationsReport({
                idTeams: allowedTeams,
                year: year,
                idType: selectedType.id
            }))
        }
    }, [allowedTeams, year, selectedType])

    useEffect(() => {
        if (addEvaluationRes != null) {
            if (addEvaluationRes.trim().toLowerCase().includes("successfully")) {
                showSuccessToast("Success!")
            } else if (addEvaluationRes.trim().toLowerCase().includes("duplicate")) {
                showErrorToast(<Langs str="DUPICATE_WARNING" />)
            } else {
                showErrorToast(addEvaluationRes)
            }
            dispatch(setAddResponse(null))
        }
    }, [addEvaluationRes])

    useEffect(() => {
        if (editEvaluationRes != null) {
            if (editEvaluationRes.trim().toLowerCase().includes("successfully")) {
                showSuccessToast("Success!")
            } else if (editEvaluationRes.trim().toLowerCase().includes("duplicate")) {
                showErrorToast(<Langs str="DUPICATE_WARNING" />)
            } else {
                showErrorToast(editEvaluationRes)
            }
            dispatch(setEditResponse(null))
        }
    }, [editEvaluationRes])

    useEffect(() => {
        setGroupedReports(convertReportsToArray(reports))
    }, [reports])

    useEffect(() => {
        setProcessedReports(processReports(groupedReports))
    }, [groupedReports])

    useEffect(() => {
        setSummarizedReport(summarizeReports(processedReports))
    }, [onViewTeam, processedReports])

    // useEffect(()=>{
    //     console.log(processReports(groupedReports))
    // },[groupedReports])

    useEffect(() => {
        setYear(date.getFullYear())
    }, [date])

    useEffect(() => {
        if (selectedTeams.length == 1) {
            setOnViewTeam(selectedTeams[0])
        } else if (selectedTeams.length > 1) {
            setOnViewTeam(null)
        }
    }, [selectedTeams])


    function getTeamMonthData(summaryData, teamId, month) {
        if (teamId) {
            if (summaryData) {
                for (const teamData of summaryData) {
                    if (teamData.id_team === teamId) {
                        return teamData[month] || "No data available"
                    }
                }
            }
        }
    }

    function processReports(reports) {
        return reports.map(report => {
            const processedReport = {
                nom: report.nom,
                prenom: report.prenom,
                id_team: report.id_team,
                team_name: report.team_name,
                id_collab: report.id_collab,
                matricule: report.matricule,
                flaggedMonths: []

            }

            //const dateIndex = report.sortie_date ? moment(report.sortie_date).month() + 1 : 12
            Object.keys(report.data).forEach(month => {
                const yesCount = report.data[month]['yes'] || 0
                const noCount = report.data[month]['no'] || 0
                let ratio;
                if (yesCount + noCount === 0) {
                    ratio = "no evaluation"
                } else {
                    ratio = ((yesCount / (yesCount + noCount)) * 100).toFixed(2);
                }
                if (report.data[month].flag) {
                    processedReport.flaggedMonths.push(month);
                }
                const dateRun = (month) => {
                    return moment({ year: year, month: month - 1, day: 1 }).endOf('month')
                }
                const monthEnd = dateRun(month);
                const entreeDate = report.entree_date ? moment(report.entree_date) : moment('1900-01-01');
                const exitDate = report.sortie_date ? moment(report.sortie_date) : moment('2200-01-01');
                if (monthEnd.isAfter(entreeDate) && monthEnd.isBefore(exitDate)) {
                    processedReport[month] = ratio;
                }
            });
            return processedReport;
        });
    }

    const exportExcel = () => {
        let collabHashMap = {};
        let exportData = [];
    
        const monthNames = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
    
        processedReports.forEach(current => {
            let reportsData = {
                "Matricule": current.matricule || "",
                "Nom": current.nom || "",
                "Prénom": current.prenom || ""
            };
    
            monthNames.forEach((month, index) => {
                const monthKey = (index + 1).toString();
                // Check if this month is flagged
                const isFlagged = current.flaggedMonths.includes(monthKey);
                // Set the value for the month, append '(Flagged)' if it's flagged
                reportsData[month] = current[monthKey] ? current[monthKey] + (isFlagged ? " (Violation du contrat)" : "") : "";
            });
    
            if (collabHashMap.hasOwnProperty(reportsData.Matricule)) {
                let existingData = collabHashMap[reportsData.Matricule];
                monthNames.forEach(month => {
                    if (reportsData[month] !== "") {
                        existingData[month] = reportsData[month];
                    }
                });
            } else {
                exportData.push(reportsData);
                collabHashMap[reportsData.Matricule] = reportsData;
            }
        });
    
        // Call your Excel export functionality
        ExcelManager.getInstance().generateAndDownloadExcelFromData(exportData, "evaluations reports");
    }
    


    function convertReportsToArray(reports) {
        const reportsArray = [];
        Object.entries(reports).forEach(([idCollab, report]) => {
            const modifiedReport = {
                ...report,
                id_collab: parseInt(idCollab, 10)
            }
            let exitDate = moment(modifiedReport.sortie_date).format("YYYY-MM-DD")
            let nowDate = moment([year - 1, 11, 30]).format("YYYY-MM-DD")
            if (modifiedReport.sortie_date) {
                if (moment(nowDate).isBefore(exitDate, 'year')) {
                    reportsArray.push(modifiedReport);
                }
            } else if (!modifiedReport.sortie_date) {
                reportsArray.push(modifiedReport);
            }
        });
        return reportsArray;
    }

    const handleTypeChange = (e) => {
        dispatch(setSelectedTypeGeneric(e.value));
    }

    function listSelectedTeam() {
        let teamIds = allowedTeams.filter(id => !myTeam || checkPermission(config.scopes.evaluations, config.permissionTypes.view, myTeam.id) || myTeam.id != id)
        return teamIds
    }

    const handleExpandRowsCollabs = (e) => {
        if (e.data.length) {
            setExpandedRowsCollabs([e.data[e.data.length - 1]])
            setOnViewTeam(e.data[e.data.length - 1].id_team)
        }
        else {
            setExpandedRowsCollabs([])
            setOnViewTeam(null)
        }
    }

    const headerTemplate = (data) => {

        return (
            <span className="vertical-align-middle ml-2 font-bold line-height-3">
                {data.team_name}
            </span>
        )
    }

    const showModalEditAdd = (idCollab, date, entreeDate, sortieDate, id_team) => {
        if ((entreeDate ? date.isAfter(entreeDate) : true) && (sortieDate ? date.isBefore(moment(sortieDate)) : true)) {
            dispatch(setAllowedToEdit(true))
        } else {
            dispatch(setAllowedToEdit(false))
        }

        dispatch(setOnEvaluateTeamId(id_team))
        dispatch(setOnEvaluateCollab(idCollab))
        dispatch(setOnEvaluateDate(date))
        dispatch(setEvaluationAddModal(true))
        dispatch(setSelectedType(selectedType))

    }

    const renderColor = (yesCount, noCount) => {
        if (yesCount + noCount === 0) {
            return '#bcbcbc'
        }
        let ratio = (yesCount / (yesCount + noCount)) * 100
        if (ratio <= 25) {
            return '#FF0000'
        } else if (ratio <= 50) {
            return '#ED7D31'
        } else if (ratio < 100) {
            return '#FFFF00'
        } else if (ratio === 100) {
            return '#70AD47'
        }
        return '#bcbcbc'
    }

    const ratioButton = (yesCount, noCount, idCollab, month, entreeDate, sortieDate, id_team) => {
        let date = moment({ year: year, month: month - 1, day: 1 }).endOf("month")
        let locked = true
        if ((entreeDate ? date.isAfter(entreeDate) : true) && (sortieDate ? date.isBefore(moment(sortieDate)) : true)) {
            locked = false
        } else {
            locked = true
        }
        let ratio;
        if (yesCount + noCount != 0) {
            ratio = ((yesCount / (yesCount + noCount)) * 100).toFixed(2);
        } else if (yesCount + noCount == 0) {
            ratio = "non evaluation"
        }

        const renderTooltip = (props) => (
            <div className={styles.tooltip} {...props} >
                <div>{ratio} {typeof ratio != 'string' ? <Langs str="YES" /> : ""}</div>
            </div>
        )
        if (!locked) {
            // return (
            //     <div className="d-flex justify-content-center">
            //         <OverlayTrigger
            //         placement="bottom"
            //         delay={{ show: 500, hide: 100 }}
            //         overlay={renderTooltip}>
            //         <button
            //             style={{ backgroundColor: renderColor(yesCount, noCount) || "#000000" }}
            //             className={styles.colorRatioButton}
            //             onClick={() => showModalEditAdd(idCollab, date, entreeDate, sortieDate)}
            //             disabled={date.isAfter(moment().endOf("month"))}>
            //         </button>
            //     </OverlayTrigger>
            //     </div>
            // )
            return (
                <div className="d-flex justify-content-center">
                    <ImageButton
                        tooltip={ratio}
                        image={editOnlyIcon}
                        style={{ backgroundColor: renderColor(yesCount, noCount) || "#000000", borderRadius: "50%", margin: "auto", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)" }}
                        imageFocus={editOnlyIconFocus}
                        onClick={() => showModalEditAdd(idCollab, date, entreeDate, sortieDate, id_team)}
                        active={!(date.isAfter(moment().endOf("month")))}
                        width={25}
                        height={35}
                        imageStyle={{ padding: "3px", paddingBlock: "6px" }}
                    />
                </div>
            )

        } else if (locked) {
            return (
                <div className="d-flex justify-content-center">
                    <ImageButton
                        tooltip={ratio}
                        image={isMobile != 1 ? viewOnlyIconFocus : viewOnlyIcon}
                        style={{ backgroundColor: renderColor(yesCount, noCount) || "#000000", borderRadius: "50%", margin: "auto", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)" }}
                        imageFocus={viewOnlyIconFocus}
                        onClick={() => showModalEditAdd(idCollab, date, entreeDate, sortieDate)}
                        active={!(date.isAfter(moment().endOf("month")))}
                        width={25}
                        height={35}
                        imageStyle={{ padding: "3px", paddingBlock: "6px" }}
                    />
                </div>
            )
        }
    }


    const monthTemplate = (rowData, monthNumber) => {
        if (rowData && rowData.data) {
            // if (rowData.sortie_date != null) {
            //     let exitDate = moment(rowData.sortie_date);
            //     let nowDate = moment({ year: year, month: monthNumber - 1, day: 1 }).endOf('month');
            //     if (nowDate.isAfter(exitDate)) {
            //         return
            //     }
            // }
            // if (rowData.entree_date != null) {
            //     let entreeDate = moment(rowData.entree_date);
            //     let nowDate = moment({ year: year, month: monthNumber - 1, day: 1 }).endOf('month');
            //     if(nowDate.isBefore(entreeDate)){
            //         return
            //     }
            // }
            let month = rowData.data[monthNumber.toString()]
            let yesCount = month['yes'] || 0;
            let noCount = month['no'] || 0;
            let idCollab = rowData.id_collab;
            return (
                <div>
                    {ratioButton(yesCount, noCount, idCollab, monthNumber, rowData.entree_date, rowData.sortie_date, rowData.id_team)}
                </div>
            )
        }
    }

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td colSpan={100}>
                    <div className="d-flex justify-content-start fw-bold w-100">
                        <Langs str={"Total"} /> : {groupedReports.filter(c => c.id_team == onViewTeam).length}
                    </div>
                </td>
            </React.Fragment>
        )
    }

    function renderBody() {
        return (
            <div className={`${styles.collabsCard} ${isMobile === 2 ? styles.mobileBody : ''}`}>
                {isMobile == 3 ? (
                    <div className="row mb-1">
                        <Col lg={2} xs={6} className="mb-2  d-flex justify-content-center align-items-center">
                            <Calendar value={date} onChange={(e) => setDate(e.value)} view="year" dateFormat="yy" />
                        </Col>
                        <Col lg={3} xs={6} className="mb-2 px-4 d-flex justify-content-center align-items-center">
                            <Dropdown
                                value={selectedType}
                                onChange={handleTypeChange}
                                options={types}
                                optionLabel="title"
                                placeholder="Type"
                                className="w-full fw-semibold text-black"
                                style={{ maxWidth: "100%" }}
                            />
                        </Col>
                    </div>
                ) : (
                    <div className="flex justify-content-start row mb-4">
                        <Col lg={2} className="flex justify-content-start row mb-4">
                            <Calendar value={date} onChange={(e) => setDate(e.value)} view="year" dateFormat="yy" />
                        </Col>
                        <Col lg={3} className="flex justify-content-start row mb-4 px-4">
                            <Dropdown
                                value={selectedType}
                                onChange={handleTypeChange}
                                options={types}
                                optionLabel="title"
                                placeholder="Type"
                                className="w-full fw-semibold text-black"
                                style={{ maxWidth: "100%" }}
                            />
                        </Col>
                    </div>
                )}

                {isMobile == 1 &&
                    <DataTable ref={dtEvaluations}
                        value={groupedReports}
                        dataKey="id"
                        stripedRows={true}
                        scrollable scrollHeight="calc(100vh - 250px)"
                        rowGroupMode={"subheader"}
                        groupRowsBy={"id_team"}
                        expandableRowGroups={listSelectedTeam().length > 1}
                        expandedRows={expandedRowsCollabs}
                        onRowToggle={handleExpandRowsCollabs}
                        sortMode="single"
                        sortField="id_team"
                        rowGroupHeaderTemplate={headerTemplate}
                        rowGroupFooterTemplate={footerTemplate}
                    >
                        <Column field="matricule" header={<Langs str="Matricule" />} sortable style={{ minWidth: '4rem', maxWidth: '4rem' }} />
                        <Column field="nom" header={<Langs str="Family Name" />} sortable style={{ minWidth: '3rem', maxWidth: '3rem' }} />
                        <Column field="prenom" header={<Langs str="Name" />} sortable style={{ minWidth: '3rem', maxWidth: '3rem' }} />
                        {[...Array(12)].map((_, index) => (
                            <Column
                                key={`${index}_month`}
                                header={<div>
                                    <Langs str={new Date(2000, index).toLocaleString('default', { month: 'short' })} /> <br />
                                    {getTeamMonthData(summarizedReport, onViewTeam, index + 1)}
                                </div>}
                                style={{ minWidth: '2rem', maxWidth: '2rem' }}
                                body={(rowData) => monthTemplate(rowData, index + 1)}
                            />
                        ))}
                    </DataTable>
                }
                {(isMobile == 2 || isMobile == 3) &&
                    <DataTable ref={dtEvaluations}
                        value={groupedReports}
                        dataKey="id"
                        stripedRows={true}
                        scrollable scrollHeight={isMobile == 3 ? "calc(100vh - 165px)" : "calc(100vh - 350px)"}
                        // rowGroupMode={"subheader"}
                        groupRowsBy={"id_team"}
                        expandableRowGroups={listSelectedTeam().length > 1}
                        expandedRows={expandedRowsCollabs}
                        onRowToggle={handleExpandRowsCollabs}
                        sortMode="single"
                        sortField="id_team"
                        rowGroupHeaderTemplate={headerTemplate}
                        rowGroupFooterTemplate={footerTemplate}
                    >
                        <Column field="matricule" header={<Langs str="Matricule" />} sortable style={{ minWidth: '6rem' }} />
                        <Column field="nom" header={<Langs str="Family Name" />} sortable style={{ minWidth: '6rem' }} />
                        <Column field="prenom" header={<Langs str="Name" />} sortable style={{ minWidth: '6rem' }} />
                        {[...Array(12)].map((_, index) => (
                            <Column
                                key={`${index}_month`}
                                header={<div>
                                    <Langs str={new Date(2000, index).toLocaleString('default', { month: 'short' })} /> <br />
                                    {getTeamMonthData(summarizedReport, onViewTeam, index + 1)}
                                </div>}
                                style={{ width: '2rem' }}
                                body={(rowData) => monthTemplate(rowData, index + 1)}
                            />
                        ))}
                    </DataTable>
                }
            </div>
        )
    }

    return (
        <div className={styles.containerView}>
            <div className="topMenu" style={{ height: 'auto' }}>
                {isMobile != 3 ? (
                    <Row>
                        <Col lg={5}>
                            <span className="headerTitle">
                                {<Langs str="Evaluations" />}
                            </span>
                            {(isMobile == 1 || isMobile == 3) && <TeamSelector includeMyTeam />}
                        </Col>
                        <Col lg={5} className="d-flex justify-content-start pe-4 align-items-center p-0 m-0"></Col>
                        <Col lg={2} xs={12} className={(isMobile == 1 || isMobile == 3) ? "d-flex justify-content-end pe-4 align-items-center p-0 m-0" : "d-flex justify-content-between px-2 align-items-center p-0 m-0 mt-2"}>
                            {isMobile == 2 && <TeamSelector includeMyTeam />}
                            <ImageButton
                                image={exportIcon}
                                imageFocus={exportIconFocus}
                                onClick={exportExcel}
                                active={true}
                                tooltip="Export"
                                width={32}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col md={4} xs={12} className="d-flex align-items-center">
                            <span className="headerTitle">
                                {<Langs str="Evaluations" />}
                            </span>
                        </Col>
                        <Col md={5} xs={12} className="d-flex align-items-center">
                            <TeamSelector includeMyTeam />
                        </Col>
                        <Col md={3} xs={12} className="d-flex justify-content-end pe-4 align-items-center p-0 m-0">
                            <ImageButton
                                image={exportIcon}
                                imageFocus={exportIconFocus}
                                onClick={exportExcel}
                                active={true}
                                tooltip="Export"
                                width={32}
                            />
                        </Col>
                    </Row>
                )}
            </div>
            {renderBody()}
            <AddEvaluationModal selectedDate={date} selectedTeamId={selectedTeamId} />
        </div>
    )
}