import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import styles from './Forms.module.css';
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Modal from 'react-bootstrap/Modal';
import Langs from "../../../app/lang/langs";
import { ThemeButton } from '../../buttons/ThemeButton'
import ExcelManager from '../../../app/managers/excelManager';
import { generateMapByKey } from '../../../app/utils/helpers';
import moment from 'moment';

export function ExportFormModal(props) {

    const dispatch = useDispatch()
    const planningList = useSelector((state) => state.planning.planningList)
    const collabs = useSelector((state) => state.collabs.list)
    const teams = useSelector((state) => state.teams)
    const selectedView = useSelector((state) => state.planning.selectedView)
    const selectedDate = useSelector((state) => state.planning.selectedDate)
    const sampleShifts = useSelector((state) => state.sampleShifts.list)
    const sampleLeaves = useSelector((state) => state.sampleLeaves.list)

    function hide() {
        props.onHide()
    }

    function getTeamLabelFromId(teamId) {
        let label = ""
        let teamsFiltered = teams.list && teams.list.filter(t => t.id == teamId)
        if (teamsFiltered && teamsFiltered[0]) {
            label = teamsFiltered[0].label
        }
        return label
    }

    function handleExportPlanning() {
        let samplesTypesMap = sampleLeaves.concat(sampleShifts).reduce((obj, current) =>{
            if(!obj[current.type] && current.task){
                obj[current.type] = current.task
            }else if(!obj[current.type] && current.description){
                obj[current.type] = current.description
            }
            return obj
        }, {})
        let collabsMap = generateMapByKey(collabs, "id")
        let exportData = planningList.reduce((array, current) => {
            let collab = collabsMap[current.id_collab]
            if(collab){
                let shiftData = {
                    "Nom": collab.nom || "",
                    "Prénom": collab.prenom || "",
                    "Email": collab.email || "",
                    "Type": current.type,
                    "Type valeur": current.absent ? "Abs Inj" : (samplesTypesMap[current.type] || current.type),
                    "Planning": current.task || "",
                    "Statut": current.status || "",
                    "Client": current.id_team_delegate ? getTeamLabelFromId(current.id_team_delegate) : getTeamLabelFromId(current.id_team),
                    "Début": moment(current.from, 'x').format("HH:mm DD-MM-YYYY") || "",
                    "Fin": moment(current.to, 'x').format("HH:mm DD-MM-YYYY") || "",
                    "Créé": current.createdAt ? moment(current.createdAt, "x").format("HH:mm DD-MM-YYYY") : "",
                    "Créé par": current.createdBy || "",
                    "Validé par": current.validatedBy || "",
                    "Commentaires": current.description || "",
                }
                array.push(shiftData)
            }
            return array
        }, [])

        let formattedDate = `mois_${moment(selectedDate).format("MM_YYYY")}`
        if(selectedView == "WEEKLY"){
            formattedDate = `semaine_${moment(selectedDate).format("WW_YYYY")}`
        }
        ExcelManager.getInstance().generateAndDownloadExcelFromData(exportData, `Planning_${formattedDate}`)
        hide()
    }

    function renderBodyView() {
        return <Modal.Body>
            <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
            <Langs str='EXPORT_VALIDATION_MSG' />
            </Row>
        </Modal.Body>
    }

    function renderHeaderView() {
        return <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <Langs str='Export planning' />
            </Modal.Title>
        </Modal.Header>
    }

    function renderFooterView() {
        return <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
                <PrimaryButton
                    title="CANCEL"
                    active={true}
                    onClick={() => hide()} />
            </Col>
            <Col md={3}>
                <ThemeButton
                    title="Export"
                    active={true}
                    onClick={() => handleExportPlanning()} />
            </Col>
        </Modal.Footer>
    }
    return <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >

        {renderHeaderView()}
        {renderBodyView()}
        {renderFooterView()}

    </Modal>
}