import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectQuestionsBank, setCurrentQuestion, setAddQuestionModalVisibility, deleteQuestion, setQuestionsBank } from './vcompSlice';
import ImageButton from '../buttons/ImageButton';
import addIcon from '../../assets/images/addIconGray.png';
import addIconFocus from '../../assets/images/addIconOrange.png';
import deleteIcon from '../../assets/images/delete.png';
import deleteIconFocus from '../../assets/images/delete-orange.png';
import editIcon from '../../assets/images/penGray.png';
import editIconFocus from '../../assets/images/penOrange.png';
import AddQuestionsModal from './AddQuestionModal';
import './QuestionsTreeCompnentsStyle.css';
import { Dialog } from 'primereact/dialog';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { showErrorToast } from '../../app/utils/helpers';

const QuestionsTree = ({ isMobile, mode, setActiveIndex, handleAddDefaultProjectForm, id_project }) => {
    const dispatch = useDispatch();
    const questionsBank = useSelector((state) => state.vcomp.questionsBank);
    const questionDialogVisible = useSelector((state) => state.vcomp.addQuestionModalVisibility);
    const [confirmDeleteDialogVisible, setConfirmDeleteDialogVisible] = useState(false);
    const [selectedQuestionIdToDelete, setSelectedQuestionIdToDelete] = useState(null);


    const handleEditQuestion = (question) => {
        dispatch(setCurrentQuestion(question));
        dispatch(setAddQuestionModalVisibility(true));
    };

    const confirmDeleteQuestion = (id) => {
        setSelectedQuestionIdToDelete(id);
        setConfirmDeleteDialogVisible(true);
    };


    const handleDeleteFromProject = async (id) => {
        try {
            await dispatch(deleteQuestion({ id })).unwrap();
            dispatch(setQuestionsBank(questionsBank.filter((q) => q.id !== id)));
        } catch (error) {
            console.error("Échec de la suppression de la question du projet", error);
        } finally {
            setConfirmDeleteDialogVisible(false);
        }
    };

    const nodeTemplate = (node) => {
        return (
            <div className="node-container">
                <span>{node.data.question}</span>&nbsp;

                <div className="node-button">
                    <Row>
                        {isMobile === 1 && (
                            <ImageButton
                                image={editIcon}
                                imageFocus={editIconFocus}
                                onClick={() => handleEditQuestion(node.data)}
                                active={true}
                                width={32}
                            />
                        )}
                        {isMobile === 1 && (
                            <ImageButton
                                image={deleteIcon}
                                imageFocus={deleteIconFocus}
                                onClick={() => confirmDeleteQuestion(node.key)}
                                active={true}
                                width={32}
                            />
                        )}
                    </Row>
                </div>
            </div>
        );
    };

    const transformQuestionsToTreeNodes = (questions) => {
        return questions.map((question) => ({
            key: question.id,
            label: question,
            data: question,
            className: 'question',
            children: question.options.map((option) => ({
                key: option.id,
                label: option.value,
                data: option,
                className: 'option',
                selectable: false
            }))
        }));
    };

    const nodes = transformQuestionsToTreeNodes(questionsBank);

    return (
        <div>
            <div
                className={`card p-3 m-4 d-flex align-content-center border-0`}
                style={isMobile === 2 ? { height: '85%', display: 'flex', flexDirection: 'column' } : {}}
            >
                <Tree
                    value={nodes}
                    className="w-full md:w-30rem custom-questions-tree border-0"
                    nodeTemplate={nodeTemplate}
                    expandedKeys={{}} 
                    onToggle={() => { }} 
                />
            </div>
            {
                isMobile !== 2 && mode !== "edit" && (
                    <div>
                        <div style={{ position: 'fixed', bottom: '1.5rem', left: '21rem', width: '7%' }}>
                            <Button label="Previous" onClick={() => setActiveIndex(0)} style={{ width: '100%' }} />
                        </div>
                        <div style={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem', width: '7%' }}>
                            <Button label="Next" onClick={handleAddDefaultProjectForm} style={{ width: '100%' }} />
                        </div>
                    </div>
                )
            }
            {questionDialogVisible && <AddQuestionsModal />}
            <Dialog
                header="Confirmation de suppression"
                visible={confirmDeleteDialogVisible}
                style={{ width: '50vw', zIndex: 10000 }}
                modal
                onHide={() => setConfirmDeleteDialogVisible(false)}
            >
                <p>Êtes-vous sûr de vouloir supprimer cette question du projet ?</p>
                <Row className="align-items-center d-flex justify-content-between mt-4">
                    <Col xs={3}>
                        <PrimaryButton
                            title="Oui"
                            onClick={() => {
                                handleDeleteFromProject(selectedQuestionIdToDelete);
                                setConfirmDeleteDialogVisible(false);
                            }}
                            active={true}
                        />
                    </Col>
                    <Col xs={3}>
                        <PrimaryButton
                            title="Non"
                            onClick={() => setConfirmDeleteDialogVisible(false)}
                            active={true}
                            style={{ marginLeft: '1rem' }}
                        />
                    </Col>
                </Row>
            </Dialog>
        </div>
    );
};

export default QuestionsTree;
