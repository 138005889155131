import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./Forms.module.css";
import {
  deleteItemFromPlanningList,
  hideFormPopup,
  setSelectedFormData,
  updateShiftProperties,
} from "../../../containers/planning/planningSlice";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Modal from "react-bootstrap/Modal";
import { SelectInput } from "../../inputs/Select";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../../app/lang/langs";
import { ThemeButton } from "../../buttons/ThemeButton";
import { TransparentDatePicker } from "../../buttons/TransparentDatePicker";
import { TransparentTimePicker } from "../../buttons/TranparentTimePicker";
import { DangerButton } from "../../buttons/DangerButton";
import { isAdmin, getMinutesFromDate, getStatusTagClass, isWorkingShift, getLastUpdatedFromDate, isOwner, checkPermission } from "../../../app/utils/helpers";
import {
  showPopup,
  onPopupHandle,
} from "../../../containers/common/commonSlice";
import { deleteShiftLocally, updateShiftLocally, updateShifts, deleteShifts } from "../shifts/shiftsSlice";
import {
  addShiftSample,
  deleteShiftSamples,
  getShiftSamples,
  updateShiftSample,
} from "../shifts/sampleShiftsSlice";
import { TextInput } from "../../inputs/TextInput";
import {
  addLeaveSample,
  deleteLeaveSamples,
  updateLeaveSample,
} from "../leave/leaveSampleSlice";
import { formatDate } from "../../../app/utils/helpers";
import { TransparentSelect } from "../../inputs/TransparentSelect";
import {
  addCollabToGroupe,
  addGroupeMembers,
  getMembers,
} from "../../collabs/collabsSlice";
import { CustomColorPicker } from "../../inputs/CustomColorPicker";
import { RadioButton } from "../../buttons/RadioButton";
import { Tag } from "primereact/tag";
import moment from "moment";
import { config } from "../../../app/utils/config";

export function ShiftFormModal(props) {

  const dispatch = useDispatch()
  const settings = useSelector((state) => state.settings)
  const clients = useSelector((state) => state.clients.list)
  const teams = useSelector((state) => state.teams.allTeams)
  const myTeam = useSelector((state) => state.teams.myTeam)
  const resources = useSelector((state) => state.collabs.collaboratorsList)
  const selectedForm = useSelector((state) => state.planning.selectedForm)
  const selectedGroupe = useSelector((state) => state.planning.selectedGroupe)
  const selectedDate = useSelector((state) => state.planning.selectedDate)
  const collabs = useSelector((state) => state.collabs)
  const currentGroupeId = useSelector((state) => state.collabs.id_groupe)
  const groupesList = useSelector((state) => state.groupes.list)
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const collabsList = useSelector((state) => state.collabs.list)
  const sampleLeaves = useSelector((state) => state.sampleLeaves.list)
  const selectedFormData = useSelector(
    (state) => state.planning.selectedFormData
  )
  const confirmationTo = useSelector((state) => state.common.confirmationTo)
  const shiftsList = useSelector((state) => state.shifts.shiftsList)
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const dynamicLeaves = useSelector((state) => state.sampleLeaves.dynamicLeaves)
  const [formError, setFormError] = useState(null)
  const [selectedCollabs, setSelectedCollabs] = useState([])

  function getFromDayPeriodOptions() {
    let options = [
      { value: settings.parameters["heure_debut_journee"], label: "Matin" },
      { value: 12, label: "Midi" },
    ]
    return options
  }

  function getToDayPeriodOptions() {
    let options = [
      { value: 12, label: "Midi" },
      { value: settings.parameters["heure_fin_journee"], label: "Soir" },
    ]
    return options
  }

  let heureDebut = 9, heureFin = 17, heureMoyenne = 13

  settings.settingsList.map(item => {
    if (item.label === "Heure début de journée") heureDebut = Number(item.settingValue)
    if (item.label === "Heure fin de journée") heureFin = Number(item.settingValue)
    heureMoyenne = (heureFin + heureDebut) / 2
  })

  function getFromDayPeriodOptions() {
    let options = [
      { value: heureDebut, label: <Langs str="Matin" /> },
      { value: heureMoyenne, label: <Langs str="Midi" /> },
    ]
    return options
  }

  function getToDayPeriodOptions() {
    let options = [
      { value: heureMoyenne, label: <Langs str="Midi" /> },
      { value: heureFin, label: <Langs str="Soir" /> },
    ]
    return options
  }

  function getSelectedDayPeriodName(dateTime) {
    let date = new Date(dateTime)
    let hour = date.getHours()
    if (hour < heureMoyenne) return "Matin"
    else if (hour > heureMoyenne) return "Soir"
    return "Midi"
  }

  function hide() {
    dispatch(hideFormPopup())
  }

  function handleSave() {
    if (validateForm()) {
      switch (selectedForm) {
        case "SHIFT":
        case "LEAVE":
          //FIX API BEING CALLED WITH LOCAL LIST
          if (shiftsList.length != 0) {
            for (const key in shiftsList) {
              if (shiftsList[key].id == selectedFormData.id) {
                dispatch(updateShiftLocally(selectedFormData))
              }
            }
          }
          else {
            dispatch(updateShifts({ items: [selectedFormData] })).then((response) => {
              if (response["payload"]["responseData"]) {
                dispatch(updateShiftProperties(selectedFormData))
              }
            })
          }
          break
        case "SAMPLE_SHIFT":
          // dispatch(updateSampleShift(selectedFormData))
          dispatch(
            updateShiftSample({
              id: selectedFormData.id,
              type: selectedFormData.type,
              color: selectedFormData.color,
              task: selectedFormData.task,
              time_from: selectedFormData.timeFrom,
              time_to: selectedFormData.timeTo,
            })
          ).then((response) => {
            dispatch(getShiftSamples())
          })
          break
        default:
          break
      }
      dispatch(hideFormPopup())
    }
  }

  function checkDayOff(data) {
    if (data.weekDay === 0 || data.weekDay === 6) return true;
    let date = new Date(data.dateTime);
    let formattedDate = formatDate(date, "DD-MM-YYYY");
    if (settings.holidays[date.getFullYear()])
      for (let i = 0; i < settings.holidays[date.getFullYear()].length; i++) {
        const holiday = settings.holidays[date.getFullYear()][i]
        if (formattedDate === holiday.date) return true
      }
    return false
  }

  function handleAdd() {
    if (validateForm()) {
      switch (selectedForm) {
        case "ADD_SAMPLE_SHIFT":
          // dispatch(addSampleShift(selectedFormData))
          dispatch(
            addShiftSample({
              type: selectedFormData["type"],
              color: selectedFormData["color"],
              task: selectedFormData["task"],
              time_from: selectedFormData["timeFrom"],
              time_to: selectedFormData["timeTo"],
            })
          ).then((response) => {
            dispatch(getShiftSamples())
          })
          dispatch(hideFormPopup())
          break
        case "ADD_SAMPLE_LEAVE":
          dispatch(addLeaveSample(selectedFormData))
          dispatch(hideFormPopup())
          break;
        case "ADD_USER":
          let members = []
          selectedCollabs.map(collab => {
            members.push({
              id_groupe: currentGroupeId,
              id_team: collab.id_team,
              id_collab: collab.id,
            })
            dispatch(
              addCollabToGroupe({ id: collab.id, id_groupe: selectedGroupe })
            )
          })
          if (members.length) {
            dispatch(
              addGroupeMembers({ items: members })
            ).then((response) => {
              dispatch(getMembers({ teamsIds: selectedTeamsIds }))
            })
            setSelectedCollabs([])
            setFormError(null)
            dispatch(hideFormPopup());
          } else {
            setFormError("MISSING_COLLABS")
          }
          break
        default:
          break
      }
    }
  }

  function validateForm() {
    let error = null;
    switch (selectedForm) {
      case "SHIFT":
        if (isWorkingShift(selectedFormData)) {
          let hoursDiff = (selectedFormData.to - selectedFormData.from) / (60 * 60 * 1000)
          if (hoursDiff >= 24) {
            error = "HOURS_EXCEED_LIMIT"
          }
        }
        break
      case "SAMPLE_SHIFT":
      case "ADD_SAMPLE_SHIFT":
        if (!selectedFormData.task || selectedFormData.task == "")
          error = "MISSING_TASK"
        break;
      case "LEAVE":
      case "ADD_SAMPLE_LEAVE":
        if (!selectedFormData.type || selectedFormData.type == "")
          error = "MISSING_TYPE"
        break
      default:
        break
    }
    setFormError(error)
    return error == null
  }

  const handleColorChange = (color) => {
    dispatch(
      setSelectedFormData({ ...selectedFormData, color: color })
    )
  }

  function handleDelete() {
    dispatch(hideFormPopup());
    dispatch(
      showPopup({
        show: true,
        title: "CONFIRM_DELETE_SHIFT",
        message: "CONFIRM_DELETE_SHIFT_MSG",
      })
    )
  }

  const handleShiftTypeChange = (option) => {
    let sampleLeaveId = selectedFormData["id_sample_leave"]
    if (option.value == 'R') {
      sampleLeaveId = sampleLeaves.filter(s => s.type == option.value)[0].id
    } else {
      sampleLeaveId = null
    }
    let newData = {
      type: option.value,
      id_sample_leave: sampleLeaveId
    }
    dispatch(setSelectedFormData({
      ...selectedFormData,
      ...newData
    }))
  }

  const handleClientChange = option => {
    dispatch(
      setSelectedFormData({ ...selectedFormData, id_team_delegate: option.value })
    )
  }

  const handleLeaveTypeChange = (event) => {
    dispatch(
      setSelectedFormData({ ...selectedFormData, type: event.target.value })
    )
  }

  const handleGroupeChange = (option) => {
    dispatch(
      setSelectedFormData({ ...selectedFormData, id_groupe: option.id })
    )
  }

  function getCollabGroupes() {
    let collab = collabs.collaboratorsList.filter((c) => c.id === selectedFormData.id_collab)[0]
    return groupesList.filter((groupe) => {
      return collab && collab.groupeIds.includes(groupe.id)
    })
  }

  const handleResourceChange = (option) => {
    dispatch(
      setSelectedFormData({ ...selectedFormData, id_collab: option.value })
    )
  }

  const handleTaskNameChange = (event) => {
    dispatch(
      setSelectedFormData({ ...selectedFormData, task: event.target.value })
    )
  }

  const handleNotesChange = (event) => {
    dispatch(
      setSelectedFormData({
        ...selectedFormData,
        description: event.target.value,
      })
    )
  }

  function handleSetAbsent(absent) {
    dispatch(
      setSelectedFormData({ ...selectedFormData, absent: absent })
    )
  }

  const handleStartDateChange = (time) => {
    let duration = selectedFormData.to - selectedFormData.from
    const data = { ...selectedFormData, from: time }
    if (!['AST'].includes(selectedFormData.type) && !dynamicLeaves[selectedFormData.type]) {
      data.to = time + duration
    }
    dispatch(setSelectedFormData(data))
  }

  const handleEndDateChange = (time) => {
    if (time > selectedFormData.from) {
      dispatch(setSelectedFormData({ ...selectedFormData, to: time }))
    }
  }

  const handleCollabsChange = (selectedList) => {
    setSelectedCollabs(selectedList)
    setFormError(null)
  }

  function getSelectedDayPeriod(hour) {
    if (hour < 12) return settings.parameters["heure_debut_journee"]
    else if (hour > 12) return settings.parameters["heure_fin_journee"]
    return hour
  }

  function getSelectedStandardDayPeriod(dateTime) {
        let date = new Date(dateTime)
        let hour = date.getHours()
        if (hour < heureMoyenne) return heureDebut
        else if (hour > heureMoyenne) return heureFin
        return heureMoyenne
    }

  const onFromDayPeriodChange = (hour) => {
    switch (selectedForm) {
      case "LEAVE":
      case "ADD_SAMPLE_LEAVE":
        dispatch(
          setSelectedFormData({ ...selectedFormData, timeFrom: hour * 60 })
        )
        break
      default:
        break
    }
  }

  const onToDayPeriodChange = (hour) => {
    switch (selectedForm) {
      case "LEAVE":
      case "ADD_SAMPLE_LEAVE":
        dispatch(
          setSelectedFormData({ ...selectedFormData, timeTo: hour * 60 })
        )
        break
      default:
        break
    }
  }

  const onTimeFromChange = (time) => {
    switch (selectedForm) {
      case "SHIFT":
      case "LEAVE":
        let fromDate = new Date(selectedFormData.from)
        let toDate = new Date(selectedFormData.to)
        fromDate.setHours(parseInt(time / 60))
        fromDate.setMinutes(parseInt(time % 60))
        if (fromDate < toDate) {
          dispatch(
            setSelectedFormData({ ...selectedFormData, from: fromDate.getTime() })
          )
        }
        break
      case "SAMPLE_SHIFT":
      case "ADD_SAMPLE_SHIFT":
      case "ADD_SAMPLE_LEAVE":
        dispatch(setSelectedFormData({ ...selectedFormData, timeFrom: time }))
        break
      default:
        break
    }
  }

  const onTimeToChange = (time) => {
    console.log(time)
    switch (selectedForm) {
      case "SHIFT":
      case "LEAVE":
        let toDate = new Date(selectedFormData.to)
        let fromDate = new Date(selectedFormData.from)
        toDate.setHours(parseInt(time / 60))
        toDate.setMinutes(parseInt(time % 60))

        if (!['AST'].includes(selectedFormData.type) && !dynamicLeaves[selectedFormData.type]) {
          toDate.setDate(fromDate.getDate())
          if (toDate <= fromDate) {
            toDate.setDate(fromDate.getDate() + 1)
          }
        }
        dispatch(
          setSelectedFormData({ ...selectedFormData, to: toDate.getTime() })
        )
        break
      case "SAMPLE_SHIFT":
      case "ADD_SAMPLE_SHIFT":
      case "ADD_SAMPLE_LEAVE":
        dispatch(setSelectedFormData({ ...selectedFormData, timeTo: time }))
        break
      default:
        break
    }
  }

  function getClientsOptions() {
    let options = [{ value: null, label: <Langs str="None" /> }]
    if (teams && teams.length) {
      options = options.concat(teams.filter(t => t.id !== selectedFormData.selectedTeamId))
    }
    return options
  }

  function getTimeFromDate(dateTime) {
    return getMinutesFromDate(dateTime)
  }

  function getAvailableCollabs() {
    let groupMemberIds = []
    collabs.collaboratorsList
      .filter((resource) => {
        if (props.isFromGroupesView)
          return resource.groupeIds.includes(currentGroupeId);
        return resource.groupeIds.includes(selectedGroupe);
      })
      .map((resource) => groupMemberIds.push(resource.id));

    let groupes = groupesList.filter(g => {
      if (props.isFromGroupesView) return g.id == currentGroupeId
      return g.id == selectedGroupe
    })

    let uniqueIds = []
    return collabsList.filter((collab) => {
      if ((groupes && groupes[0] && groupes[0].id_team != collab.id_team) ||
        groupMemberIds.includes(collab.id) ||
        uniqueIds.includes(collab.id) ||
        !["Présent", "Actif"].includes(collab.statut))
        return false
      uniqueIds.push(collab.id)
      return true
    })
  }

  function getGroupeResources() {
    let uniqueIds = []
    if (!selectedGroupe) return collabs.list.filter(c => {
      if (c.id_team == selectedFormData.id_team && !uniqueIds.includes(c.id) && ["Présent", "Actif"].includes(c.statut)) {
        uniqueIds.push(c.id)
        return true
      }
      return false
    })
    return resources.filter(r => {
      if (r.groupeIds.includes(selectedFormData.id_groupe) && !uniqueIds.includes(r.id)) {
        uniqueIds.push(r.id)
        return true
      }
      return false
    })
  }

  useEffect(() => {
    if (confirmationTo === "CONFIRM_DELETE_SHIFT") {
      switch (selectedForm) {
        case "SHIFT":
        case "LEAVE":
          dispatch(deleteShiftLocally(selectedFormData.id))
          dispatch(deleteShifts({ ids: [selectedFormData.id] })).then(
            (response) => {
              if (response["payload"]["responseData"]) {
                dispatch(
                  deleteItemFromPlanningList(
                    response["payload"]["body"]["ids"][0]
                  )
                );
              }
            }
          )
          break
        case "SAMPLE_SHIFT":
          dispatch(deleteShiftSamples({ ids: [selectedFormData.id] })).then(
            (response) => {
              if (response["payload"]["responseData"]) {
                dispatch(getShiftSamples());
              }
            }
          )
          // dispatch(deleteSampleShift(selectedFormData.id))
          break
        default:
          break
      }
      dispatch(onPopupHandle())
    }

  }, [dispatch, confirmationTo])

  // handle on popup hide
  useEffect(() => {
    if (!props.show) {
      setSelectedCollabs([])
      setFormError(null)
    }
  }, [dispatch, props.show]);

  function getCollabShiftViewType() {
    if (selectedFormData.type == 's') return {
      value: 's',
      label: 'Journée standard'
    }
    if (selectedFormData.type == 'WE') return {
      value: 'WE',
      label: 'Forfait WE'
    }
    for (const key in settings.shiftTypes) {
      let option = settings.shiftTypes[key]
      if (option.value === selectedFormData.type)
        return option
    }
  }

  function getTeamLabelFromId(teamId) {
    let label = ""
    if (teams && teams.length) {
      let filteredTeams = teams.filter(t => t.id == teamId)
      if (filteredTeams.length) label = filteredTeams[0].label
    }
    return label
  }

  function getAssignedCollab() {
    let assignedCollabs = collabsList.filter((collab) => collab.id == selectedFormData.id_collab)
    if (assignedCollabs[0] && assignedCollabs[0].label)
      return assignedCollabs[0].label
    else return ""
  }

  function getGroupLabel(groupId) {
    let group = groupesList.filter((g) => g.id == groupId)[0]
    if (group && group.label) return group.label
    return ""
  }

  let collabShiftType = getCollabShiftViewType()

  const collabShiftBodyView = (
    <Modal.Body>
      {selectedFormData.from &&
        <Row xs="auto" className="d-flex justify-content-start align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="START_DATE" />
            </label>
          </Col>
          <Col md={3}>
            <div>{formatDate(new Date(selectedFormData.from), "DD MMM YYYY")}</div>
          </Col>
          <Col md={2} className="text-center">
            <span>
              <Langs str="TIME" />
            </span>
          </Col>
          {collabShiftType.value == 's' ?
            <Row xs="auto">
              <div><Langs str={getSelectedDayPeriodName(selectedFormData.from)} /></div>
            </Row>
            :
            <Row xs="auto">
              <div>{getTimeFromDate(selectedFormData.from) / 60} : {(getTimeFromDate(selectedFormData.from) % 60) == 0 ? '00' : getTimeFromDate(selectedFormData.from) % 60}</div>
            </Row>
          }
        </Row>}
      {selectedFormData.to &&
        <Row xs="auto" className="d-flex justify-content-start align-items-center p-1 pb-2 pb-4">
          <Col md={3}>
            <label>
              <Langs str="END_DATE" />
            </label>
          </Col>
          <Col md={3}>
            <div>{formatDate(new Date(selectedFormData.to), "DD MMM YYYY")}</div>
          </Col>
          <Col md={2} className="text-center">
            <span>
              <Langs str="TIME" />
            </span>
          </Col>
          {collabShiftType.value == 's' ?
            <Row xs="auto">
              <div><Langs str={getSelectedDayPeriodName(selectedFormData.to)} /></div>
            </Row>
            :
            <Row xs="auto">
              <div>{getTimeFromDate(selectedFormData.to) / 60} : {(getTimeFromDate(selectedFormData.to) % 60) == 0 ? '00' : getTimeFromDate(selectedFormData.to) % 60}</div>
            </Row>
          }
        </Row>}
      {collabShiftType && selectedForm === "SHIFT" ? (
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="TYPE" />
            </label>
          </Col>
          <Col md={9}>
            <div><Langs str={collabShiftType.label} /></div>
          </Col>
        </Row>
      ) : (
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="TYPE" />
            </label>
          </Col>
          <Col md={9} className={formError === "MISSING_TYPE" && "inputError"}>
            <TextInput
              value={selectedFormData.type}
              maxLength={50}
              onChange={handleLeaveTypeChange}
            />
          </Col>
        </Row>
      )}
      {selectedFormData.id_groupe &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="GROUPE" />
            </label>
          </Col>
          <Col md={9}>
            <div>{getGroupLabel(selectedFormData.id_groupe)}</div>
          </Col>
        </Row>}
      {selectedFormData.id_collab &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="ASSIGNED" />
            </label>
          </Col>
          <Col md={9}>
            <div>{getAssignedCollab()}</div>
          </Col>
        </Row>}
      {selectedFormData && selectedFormData.id_team_delegate &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="CLIENT" />
            </label>
          </Col>
          <Col md={9}>
            <div>{getTeamLabelFromId(selectedFormData.id_team_delegate)}</div>
          </Col>
        </Row>}
      {(selectedFormData && selectedFormData.description) &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="DESCRIPTION" />
            </label>
          </Col>
          <Col md={9}>
            <div>{selectedFormData.description}</div>
          </Col>
        </Row>}
      {selectedFormData && selectedFormData.createdBy &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="CREATED_BY" />
            </label>
          </Col>
          <Col md={9}>
            <div>{selectedFormData.createdBy}</div>
          </Col>
        </Row>}
      {selectedFormData && selectedFormData.updatedAt &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="LAST_UPDATED" />
            </label>
          </Col>
          <Col md={9}>
            <div>
              {getLastUpdatedFromDate(selectedFormData.updatedAt)}
              {selectedFormData && selectedFormData.updatedBy &&
                <span> <Langs str="by" /> {selectedFormData.updatedBy}</span>}
            </div>
          </Col>
        </Row>}
    </Modal.Body>
  )

  const shiftBodyView = (
    <Modal.Body>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="START_DATE" />
          </label>
        </Col>
        <Col md={5}>
          <TransparentDatePicker
            onChange={handleStartDateChange}
            value={selectedFormData.from}
          />
        </Col>
        <Col md={4}>
          {selectedFormData.type == 's' ?
            <SelectInput
              options={getFromDayPeriodOptions()}
              value={getSelectedStandardDayPeriod(selectedFormData.from)}
              onChange={(option) => onTimeFromChange(option.value*60)} />
            :
            <TransparentTimePicker
              value={getTimeFromDate(selectedFormData.from)}
              onChange={onTimeFromChange}
            />
          }
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2 pb-4">
        <Col md={3}>
          <label>
            <Langs str="END_DATE" />
          </label>
        </Col>
        <Col md={5}>
          <TransparentDatePicker
            onChange={handleEndDateChange}
            value={selectedFormData.to}
            disabled={!['AST'].includes(selectedFormData.type) && !dynamicLeaves[selectedFormData.type]}
          />
        </Col>
        <Col md={4}>
          {selectedFormData.type == 's' ?
            <SelectInput
              options={getToDayPeriodOptions()}
              value={getSelectedStandardDayPeriod(selectedFormData.to)}
              onChange={(option) => onTimeToChange(option.value*60)} />
            :
            <TransparentTimePicker
              value={getTimeFromDate(selectedFormData.to)}
              onChange={onTimeToChange}
            />
          }
        </Col>
      </Row>
      {/* {selectedForm === "SHIFT" && (
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="TASK" />
            </label>
          </Col>
          <Col md={9} className={formError === "MISSING_TASK" && "inputError"}>
            <TextInput
              value={selectedFormData.task}
              maxLength={50}
              onChange={handleTaskNameChange}
            />
          </Col>
        </Row>
      )} */}
      {selectedForm === "SHIFT" ? (
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="TYPE" />
            </label>
          </Col>
          <Col md={9}>
            <SelectInput
              value={selectedFormData.type}
              onChange={handleShiftTypeChange}
              options={settings.shiftTypes}
            />
          </Col>
        </Row>
      ) : (
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="TYPE" />
            </label>
          </Col>
          <Col md={9} className={formError === "MISSING_TYPE" && "inputError"}>
            <TextInput
              value={selectedFormData.type}
              maxLength={50}
              onChange={handleLeaveTypeChange}
            />
          </Col>
        </Row>
      )}
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="GROUPE" />
          </label>
        </Col>
        <Col md={9}>
          <SelectInput
            value={selectedFormData.id_groupe}
            onChange={handleGroupeChange}
            options={getCollabGroupes()}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="ASSIGNED" />
          </label>
        </Col>
        <Col md={9}>
          <SelectInput
            value={selectedFormData.id_collab}
            onChange={handleResourceChange}
            options={getGroupeResources()}
          />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
        <Col md={3}><label><Langs str='CLIENT' /></label></Col>
        <Col md={9}>
          <SelectInput
            value={selectedFormData.id_team_delegate || selectedFormData.id_team}
            onChange={handleClientChange}
            options={getClientsOptions()}
          />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
        <Col md={3}><label><Langs str='ABSENT' /></label></Col>
        <Col md={4}>
          <RadioButton
            title={"YES"}
            selected={selectedFormData.absent}
            setSelected={() => handleSetAbsent(true)} />
        </Col>
        <Col md={5}>
          <RadioButton
            title={"NO"}
            selected={!selectedFormData.absent}
            setSelected={() => handleSetAbsent(false)} />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="DESCRIPTION" />
          </label>
        </Col>
        <Col md={9}>
          <textarea
            name="description"
            maxLength={200}
            value={selectedFormData && selectedFormData.description}
            onChange={handleNotesChange}
          />
        </Col>
      </Row>
      {selectedFormData && selectedFormData.createdBy &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2" style={{color: "grey"}}>
          <Col md={3}>
            <label>
              <Langs str="CREATED_BY" />
            </label>
          </Col>
          <Col md={9}>
            <span>{selectedFormData.createdBy}</span>
          </Col>
        </Row>}
      {selectedFormData && selectedFormData.updatedAt &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2" style={{color: "grey"}}>
          <Col md={3}>
            <label>
              <Langs str="LAST_UPDATED" />
            </label>
          </Col>
          <Col md={9}>
            <div>
              {getLastUpdatedFromDate(selectedFormData.updatedAt)}
              {selectedFormData && selectedFormData.updatedBy &&
                <span> <Langs str="by" /> {selectedFormData.updatedBy}</span>}
            </div>
          </Col>
        </Row>}
      {formError && <Row className="d-flex justify-content-center align-items-center p-1">
        <Col md={12}>
          <span className="error">
            <Langs str={formError} />
          </span>
        </Col>
      </Row>}
    </Modal.Body>
  )

  const sampleShiftBodyView = (
    <Modal.Body>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <span>
            <Langs str="START_TIME" />
          </span>
        </Col>
        <Col md={9}>
          <TransparentTimePicker
            value={selectedFormData.timeFrom}
            onChange={onTimeFromChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2 pb-4">
        <Col md={3}>
          <span>
            <Langs str="END_TIME" />
          </span>
        </Col>
        <Col md={9}>
          <TransparentTimePicker
            value={selectedFormData.timeTo}
            onChange={onTimeToChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="TASK" />
          </label>
        </Col>
        <Col md={9} className={formError === "MISSING_TASK" && "inputError"}>
          <TextInput
            value={selectedFormData.task}
            maxLength={50}
            onChange={handleTaskNameChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="TYPE" />
          </label>
        </Col>
        <Col md={9}>
          <SelectInput
            value={selectedFormData.type}
            onChange={handleShiftTypeChange}
            options={settings.shiftTypes}
          />
        </Col>
      </Row>
      {!['AST'].includes(selectedFormData.type) && !dynamicLeaves[selectedFormData.type] &&
        <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
          <Col md={3}>
            <label>
              <Langs str="COLOR" />
            </label>
          </Col>
          <Col md={9}>
            <CustomColorPicker
              color={selectedFormData.color}
              colors={settings.shiftColors}
              onChange={handleColorChange}
            />
          </Col>
        </Row>}
    </Modal.Body>
  )

  const sampleLeaveBodyView = (
    <Modal.Body>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <span>
            <Langs str="START_TIME" />
          </span>
        </Col>
        <Col md={4}>
          <TransparentTimePicker
            value={selectedFormData.timeFrom}
            onChange={onTimeFromChange}
          />
        </Col>
        <Col md={5}>
          <TransparentSelect
            options={getFromDayPeriodOptions()}
            value={getSelectedDayPeriod(
              parseInt(selectedFormData.timeFrom / 60)
            )}
            onChange={onFromDayPeriodChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2 pb-4">
        <Col md={3}>
          <span>
            <Langs str="END_TIME" />
          </span>
        </Col>
        <Col md={4}>
          <TransparentTimePicker
            value={selectedFormData.timeTo}
            onChange={onTimeToChange}
          />
        </Col>
        <Col md={5}>
          <TransparentSelect
            options={getToDayPeriodOptions()}
            value={getSelectedDayPeriod(parseInt(selectedFormData.timeTo / 60))}
            onChange={onToDayPeriodChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="TYPE" />
          </label>
        </Col>
        <Col md={9} className={formError === "MISSING_TYPE" && "inputError"}>
          <TextInput
            value={selectedFormData.type}
            maxLength={50}
            onChange={handleLeaveTypeChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3}>
          <label>
            <Langs str="COLOR" />
          </label>
        </Col>
        <Col md={9}>
          <CustomColorPicker
            color={selectedFormData.color}
            colors={settings.leavesColors}
            onChange={handleColorChange}
          />
        </Col>
      </Row>
    </Modal.Body>
  )

  const addUserBodyView = (
    <Modal.Body>
      <Row className="d-flex justify-content-center align-items-center p-1 pb-2">
        <Col md={3} className={formError === 'MISSING_COLLABS' ? "error" : ""}>
          <label>
            <Langs str="COLLABS" />
          </label>
        </Col>
        <Col md={9}>
          <SelectInput
            error={formError === 'MISSING_COLLABS'}
            value={selectedCollabs}
            onChange={handleCollabsChange}
            options={getAvailableCollabs()}
            isMulti
          />
        </Col>
      </Row>
    </Modal.Body>
  )

  function isManager() {
    return checkPermission(config.scopes.shifts, config.permissionTypes.edit, selectedFormData.id_team)
    return isAdmin(userRoleId) && (!myTeam || myTeam.userRoleId == 2 || selectedFormData.id_team != myTeam.id)
  }

  function canEditPlanning() {
    if (isOwner(userRoleId)) {
      return true
    }
    if (!settings.blockageDay) {
      return false
    }
    let now = moment()
    let monthEnd = moment(selectedDate, 'x').endOf('month').add(settings.blockageDay, 'day')
    return isManager() && (now.isBefore(monthEnd))
  }

  function renderBodyView() {
    switch (selectedForm) {
      case "SHIFT":
      case "LEAVE":
        return canEditPlanning() ? shiftBodyView : collabShiftBodyView
      case "SAMPLE_SHIFT":
      case "ADD_SAMPLE_SHIFT":
        return sampleShiftBodyView
      case "ADD_USER":
        return addUserBodyView;
      case "ADD_SAMPLE_LEAVE":
        return sampleLeaveBodyView
      default:
        break
    }
  }

  function renderHeaderView() {
    let title = "", tag
    switch (selectedForm) {
      case "SHIFT":
      case "SAMPLE_SHIFT":
        title = selectedFormData && selectedFormData.task
        tag = selectedFormData && selectedFormData.status
        break
      case "LEAVE":
        title = selectedFormData && selectedFormData.type
        tag = selectedFormData && selectedFormData.status
        break
      default:
        title = <Langs str={selectedForm} />
        break
    }
    return (
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <label className="me-2">{title}</label>
        </Modal.Title>
        {tag && <Tag severity={getStatusTagClass(tag)}>
          <Langs str={tag} />
        </Tag>}
      </Modal.Header>
    )
  }

  function renderFooterView() {
    switch (selectedForm) {
      case "SHIFT":
      case "LEAVE":
      case "SAMPLE_SHIFT":
        return (
          canEditPlanning() ? <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
              <PrimaryButton
                title="CANCEL"
                active={true}
                onClick={() => hide()}
              />
            </Col>
            <Col md={3}>
              <DangerButton
                title="DELETE"
                active={true}
                onClick={() => handleDelete()}
              />
            </Col>
            <Col md={3}>
              <ThemeButton
                title="SAVE"
                active={true}
                onClick={() => handleSave()}
              />
            </Col>
          </Modal.Footer> : <div></div>
        )
      case "ADD_SAMPLE_SHIFT":
      case "ADD_SAMPLE_LEAVE":
        return (
          isManager() ? <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
              <PrimaryButton
                title="CANCEL"
                active={true}
                onClick={() => hide()}
              />
            </Col>
            <Col md={3}>
              <ThemeButton
                title="ADD"
                active={true}
                onClick={() => handleAdd()}
              />
            </Col>
          </Modal.Footer> : <div></div>
        );
      case "ADD_USER":
        return (
          <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
              <PrimaryButton
                title="CANCEL"
                active={true}
                onClick={() => hide()}
              />
            </Col>
            <Col md={3}>
              <ThemeButton
                title="ADD"
                active={true}
                onClick={() => handleAdd()}
              />
            </Col>
          </Modal.Footer>
        )
      default:
        break
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {renderHeaderView()}
      {renderBodyView()}
      {renderFooterView()}
    </Modal>
  )
}
