import React, { useEffect, useState } from "react";
import GridLayout from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import { setShowFormPopup } from "../../../containers/planning/planningSlice";
import { ShiftCell } from "../cells/shiftCell.js";
import { ShiftPlaceholder } from "../cells/ShiftPlaceholder";
import styles from "./Rows.module.css";
import { checkPermission, formatDate, isAdmin } from "../../../app/utils/helpers";
import moment from "moment";
import { getCollabReport } from "../../../containers/reports/reportsSlice";
import { config } from "../../../app/utils/config.js";
import {
  setEvaluationAddModal,
  setOnEvaluateTeamId,
  setOnEvaluateCollab,
  setOnEvaluateDate,
  setAllowedToEdit
} from "../../evaluations/evaluationsSlice.js";
import ImageButton from "../../buttons/ImageButton.js";
import warningIcon from "../../../assets/images/warningIcon.png"
import warningIconFocus from "../../../assets/images/warningIconFocus.png"
import Langs from "../../../app/lang/langs.js";

export function ResourceRow(props) {

  const dispatch = useDispatch()

  const selectedView = useSelector((state) => state.planning.selectedView)
  const draggingItem = useSelector((state) => state.planning.draggingItem)
  const postes = useSelector((state) => state.postes)
  const settings = useSelector((state) => state.settings)
  const selectedDate = useSelector((state) => state.planning.selectedDate)
  const userId = useSelector((state) => state.login.userId)
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const teams = useSelector((state) => state.teams)
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const evaluationReport = useSelector((state) => state.evaluations.report)

  let width, height
  switch (selectedView) {
    case "WEEKLY":
      width = props.nbCols * 200
      height = 64
      break
    default:
      width = props.nbCols * 120
      height = 64
      break
  }

  const onDrop = (layout, layoutItem, _event) => {
    // console.log(`Dropped element props:\n${JSON.stringify(layoutItem, ['x', 'y', 'w', 'h'], 2)}`);
    if (layoutItem)
      props.onDropShift(props.resource, layoutItem.x, props.originalTeamId)
  }

  const onLayoutChange = (layout) => {
    props.onLayoutChange(layout, props.resource);
  }

  function checkDayOff(data) {
    if (data.weekDay === 0 || data.weekDay === 6)
      return true
    let date = new Date(data.dateTime)
    let formattedDate = formatDate(date, "DD-MM-YYYY")
    if (settings.holidays[date.getFullYear()])
      for (let i = 0; i < settings.holidays[date.getFullYear()].length; i++) {
        const holiday = settings.holidays[date.getFullYear()][i]
        if (formattedDate === holiday.date)
          return true
      }
    return false
  }

  const onDragItem = (layout, item) => {
    props.onDragItem(layout, item, props.resource)

    //console.log(`Dropped element props:\n${JSON.stringify(item, ['x', 'y', 'w', 'h'], 2)}`)
  }

  const onResizeItem = (layout, item) => {
    props.onResizeItem(layout, item, props.resource)
  }

  const handleResourceProfileClick = (event) => {
    dispatch(
      setShowFormPopup({ type: "PROFILE_DETAILS", data: props.resource })
    )
    let reportData = {
      monthDate: moment(selectedDate, "x").format("MM-YYYY"),
      id_collab: props.resource.id,
      id_team: props.originalTeamId
    }

    if (isAdmin(userRoleId) || props.resource.id == userId) {
      dispatch(getCollabReport(reportData))
    }
  }

  function getPosteFromId(posteId) {
    let posteList = postes.filter((poste) => poste.value === posteId)
    if (posteList.length)
      return posteList[0]["label"]
    else return ""
  }

  let extraHours = 0
  let shiftsLayout = props.layout.map((item) => {
    extraHours += item.extraTime
    return (
      <div key={item["i"]}>
        {item.data.placeholder && !draggingItem && (
          <ShiftPlaceholder data={item.data} />
        )}
        <ShiftCell
          data={item.data}
          extraTime={item.extraTime}
          isInteractive={false}
          resource={props.resource}
          isAll={props.isAll}
          groupe={props.groupe}
          originalTeamId={props.originalTeamId}
        />
      </div>
    )
  })

  function isManager(teamId) {
    return checkPermission(config.scopes.shifts, config.permissionTypes.edit, teamId)
    return isAdmin(userRoleId) && (!teams.myTeam || teams.myTeam.userRoleId == 2 || teamId != teams.myTeam.id)
  }

  //evaluation warning modification
  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  useEffect(() => {
    const date = new Date(selectedDate);
    const newYear = date.getFullYear();
    const newMonth = date.getMonth() + 1;
    if (newYear != year) {
      setYear(newYear);
    }
    if (newMonth != month) {
      setMonth(newMonth);
    }
  }, [selectedDate])

  function renderEvaluateBtn(collab) {
    if (checkPermission(config.scopes.evaluations, config.permissionTypes.view, collab.id_team) &&
      collab.evaluated < collab.totalEvaluationFields) {

      const isActiveTeamMember = (!collab.sortie_date_team || moment(collab.sortie_date_team).isAfter(moment(selectedDate).startOf('month')))
        && (!collab.entree_date_team || moment(collab.entree_date_team).isBefore(moment(selectedDate).endOf('month')))
        
      if (isActiveTeamMember) {
        return (
          <ImageButton
            image={warningIcon}
            imageFocus={warningIconFocus}
            onClick={() => triggerModalEvaluate(collab.id)}
            active={checkPermission(config.scopes.evaluations, config.permissionTypes.edit, collab.id_team)}
            tooltip={<Langs str={checkPermission(config.scopes.evaluations, config.permissionTypes.edit, collab.id_team) ? "EVALUATE_CLICK" : "EVALUATE_NOCLICK"} />}
            width={28}
            style={{ padding: 0, margin: 0 }}
          />
        )
      }
    }
    return null
  }

  function triggerModalEvaluate(idCollab) {

    dispatch(setOnEvaluateTeamId(props.resource.id_team))
    dispatch(setAllowedToEdit(true))
    dispatch(setEvaluationAddModal(true))
    dispatch(setOnEvaluateCollab(idCollab))
    dispatch(setOnEvaluateDate(selectedDate))

  }

  return (
    <div
      style={{ width: width + 100 }}
      className={styles.resourceCell + " " + props.color}>
      <div className={props.color + " sideSticky " + styles.resourceNameCell}>
        <button
          style={{ color: props.resource.color || "black" }}
          className={styles.resourceLabel}
          onClick={handleResourceProfileClick}
          disabled={(!isManager(props.originalTeamId) && userId != props.resource.id)}
        >
          {props.resource.label}
        </button>
        {extraHours && (isManager(props.originalTeamId) || userId == props.resource.id) ? (
          <div>
            <div style={{ fontSize: 10, position: 'absolute', bottom: 7, left: 5, fontFamily: 'roboto-bold' }}>TOTAL: </div>
            <label className={styles.resourceExtraHours} style={{ backgroundColor: extraHours > 0 ? "green" : "red" }}>
              {extraHours}
            </label>
          </div>
        ) : null}
        <div className="d-flex justify-content-end pe-0 mb-2 me-0">
          {renderEvaluateBtn(props.resource)}
        </div>
      </div>
      <div className={props.color + " " + styles.resourceGrid}>
        <GridLayout
          className={styles.layout}
          layout={props.layout}
          width={width}
          rowHeight={height}
          cols={props.nbCols}
          preventCollision={true}
          onDrop={onDrop}
          isDroppable={props.isDroppable}
          onLayoutChange={onLayoutChange}
          isBounded={true}
          onDragStop={onDragItem}
          onResizeStop={onResizeItem}>
          {shiftsLayout}
        </GridLayout>
      </div>
    </div>
  )
}
