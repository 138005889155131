import React, { act, useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { setAddElementModalVisibility, addEditCategory, addEditAptitude, setSelectedAptitudeForQuestion, setAddQuestionModalVisibility, deleteQuestion, setCurrentQuestion, aptitudeMcqVisibility } from "./vcompSlice";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { setSelectedDate } from "../../containers/planning/planningSlice";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { isAdmin } from "../../app/utils/helpers";
import styles from './VcompCompnents.module.css'
import { DynamicButton } from "../buttons/DynamicButton";
import Langs, { Localize } from "../../app/lang/langs";
import AddQuestionModal from './AddQuestionModal';
import { OrderList } from 'primereact/orderlist';
import ImageButton from "../buttons/ImageButton";
import editIcon from '../../assets/images/edit.png';
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { PrimaryButton } from "../buttons/PrimaryButton";
import addIcon from '../../assets/images/addIconGray.png'
import addIconFocus from '../../assets/images/addIconOrange.png'
import deleteIcon from '../../assets/images/delete.png'
import deleteIconFocus from '../../assets/images/delete-orange.png'
import editIconFocus from '../../assets/images/penOrange.png'
import { InputSwitch } from "primereact/inputswitch";
import { showErrorToast } from "../../app/utils/helpers";
import { showSuccessToast } from "../../app/utils/helpers";
import { confirmDialog } from 'primereact/confirmdialog';
import { addEditQuestion, setQuestionsBank } from "./vcompSlice";

export default function AddElementModal() {

    const dispatch = useDispatch()

    const props = useSelector((state) => state.vcomp.addElementModalVisibility);
    const userRoleId = useSelector((state) => state.login.userRoleId);
    const onCreateProject = useSelector((state) => state.vcomp.onCreateProject);
    const isMobile = useSelector((state) => state.settings.isMobile)

    const [idElement, setIdElmement] = useState(null);
    const [visible, setVisible] = useState(false);
    const [isSubcat, setIsSubCat] = useState(false);
    const [allowedOptions, setAllowedOptions] = useState(['Aptitude'])
    const [selectedOption, setSelectedOption] = useState(allowedOptions[0])
    const [idParent, setIdParent] = useState(null)
    const [pos, setPos] = useState(null)
    const [activeState, setActiveState] = useState(true)
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const questionsBank = useSelector((state) => state.vcomp.questionsBank);



    const [nom, setNom] = useState('')
    const [coef, setCoef] = useState(1)
    const [nomFlag, setNomFlag] = useState(false)
    const [description, setDescription] = useState('')

    const visibilityOptions = [
        { label: "Private", value: 1 },
        { label: "Public", value: 2 }
    ]
    const typesOptions = [
        { label: "Auto", value: 1 },
        { label: "MCQ", value: 2 }
    ]
    const [elVisibility, setElVisibility] = useState(visibilityOptions[0].value)

    const aspectOptions = [
        { label: Localize("Theoratical"), value: "theoretical" },
        { label: Localize("Practical"), value: "practical" }
    ];

    const [selectedAspect, setSelectedAspect] = useState(aspectOptions[0].value)

    const [type, setType] = useState(typesOptions[0].value)

    const [questions, setQuestions] = useState([]);
    const [questionDialogVisible, setQuestionDialogVisible] = useState(false);

    const questionsFromStore = useSelector((state) => state.vcomp.selectedAptitudeForQuestion);
    const [showQuestions, setShowQuestions] = useState(false);

    const [activeMcq, setActiveMcq] = useState(null);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);

    useEffect(() => {
        if (questionsFromStore) {
            setQuestions(questionsFromStore.questions);
        } else {
            setQuestions([]);
        }
    }, [questionsFromStore]);

    const setMcqVisibilityForAptitude = (e) => {
        console.log(e)
        if (onCreateProject && onCreateProject.id) {
            if (e.value === true) {
                dispatch(aptitudeMcqVisibility({ id_project: onCreateProject.id, activate: [idElement] }))
                setActiveMcq(true)
            }
            if (e.value === false) {
                dispatch(aptitudeMcqVisibility({ id_project: onCreateProject.id, deactivate: [idElement] }))
                setActiveMcq(false)
            }
        }
    }

    useEffect(() => {
        if (props) {
            console.log(props)
            setVisible(props.visibility);
            setIsSubCat(props.subcategory);
            if (!props.isEdit) {
                if (props.element) {
                    setIdParent(props.element.id);
                    setPos(props.element.children ? props.element.children.length : 0)
                    if (!props.subcategory) {
                        setAllowedOptions(['Aptitude', 'Subcategory'])
                    } else {
                        setAllowedOptions(['Aptitude'])
                    }
                } else {
                    setPos(props.pos)
                    setAllowedOptions(['Category', 'Aptitude'])
                }
                if (props.isAdmin) {
                    setElVisibility(visibilityOptions[1].value)
                }
            } else {
                if (props.element.key.trim().toLowerCase().includes("cat")) {
                    setSelectedOption('Category')
                    setNom(props.element.label)
                    setIdElmement(props.element.id);
                    setElVisibility(props.element.visibility)
                    setCoef(props.element.coef);
                    setActiveState(props.element.active_state == 1 ? true : false) 
                }
                if (props.element.key.trim().toLowerCase().includes("apt")) {
                    setSelectedOption('Aptitude')
                    setNom(props.element.label)
                    setDescription(props.element.description)
                    setIdElmement(props.element.id);
                    setElVisibility(props.element.visibility)
                    setCoef(props.element.coef);
                    setQuestions(props.element.questions)
                    setActiveMcq(props.element.active_mcq == 1);
                    setSelectedAspect(props.element.aspect)
                    setActiveState(props.element.active_state == 1 ? true : false) 
                }
            }
        } else {
            setVisible(false);
            setIsSubCat(false);
            setIdParent(null);
            setPos(null);
            setNom('');
            setDescription('')
            setCoef(1)
            setAllowedOptions(['Aptitude']);
            setNomFlag(false);
            setSelectedOption('Aptitude');
            setIdElmement(null)
            setElVisibility(visibilityOptions[0].value)
            setActiveState(true)
        }

    }, [props])

    function handleClose() {
        dispatch(setAddElementModalVisibility(null))
        dispatch(setSelectedAptitudeForQuestion(null))
    }

    const selectedItemTemplate = (option, props) => {
        if (option) {
            return <span>{option.label}</span>;
        }
        return (
            <span className="p-placeholder">=====
                {props.placeholder}
            </span>
        );
    };

    const handleElementSave = () => {
        if (nom.trim() == '' || nom == null) {
            setNomFlag(true)
        } else {
            if (!props.isEdit) {
                if (selectedOption === 'Aptitude') {
                    dispatch(addEditAptitude({
                        id_project: onCreateProject ? onCreateProject.id : null,
                        nom: nom,
                        coef: coef,
                        pos: pos,
                        description: description,
                        type: type == 1 ? "AUTO" : "MCQ",
                        id_category_default: idParent,
                        visibility: elVisibility,
                        aspect: selectedAspect,
                        active_state: activeState ? 1 : 0
                    }))
                }
                if (selectedOption === 'Subcategory' || selectedOption === 'Category') {
                    dispatch(addEditCategory({
                        id_project: onCreateProject ? onCreateProject.id : null,
                        nom: nom,
                        coef: coef,
                        pos: pos,
                        id_parent_default: idParent,
                        visibility: elVisibility,
                        active_state: activeState ? 1 : 0
                    }))
                }
            } else {
                if (selectedOption === 'Aptitude') {
                    dispatch(addEditAptitude({
                        id_project: onCreateProject ? onCreateProject.id : null,
                        id: idElement,
                        nom: nom,
                        coef: coef,
                        pos: pos,
                        description: description,
                        type: type == 1 ? "AUTO" : "MCQ",
                        id_category_default: idParent,
                        visibility: elVisibility,
                        aspect: selectedAspect,
                        active_state: activeState ? 1 : 0
                    }))
                }
                if (selectedOption === 'Subcategory' || selectedOption === 'Category') {
                    dispatch(addEditCategory({
                        id_project: onCreateProject ? onCreateProject.id : null,
                        id: idElement,
                        nom: nom,
                        coef: coef,
                        pos: pos,
                        id_parent_default: idParent,
                        visibility: elVisibility,
                        active_state: activeState ? 1 : 0
                    }))
                }
            }
            handleClose()
        }
    }

    const itemTemplate = (item) => {
        return (
            <div className="p-2" style={{ width: '100%' }}>
                <Row className="align-items-center">
                    <Col className="flex-grow-1">
                        <span className="font-bold">{item.question}</span>
                    </Col>

                    <Col xs="auto">
                        <ImageButton
                            image={editIcon}
                            imageFocus={editIconFocus}
                            onClick={() => openQuestionDialog(item)}
                            active={true}
                            width={32}
                        />
                    </Col>

                    <Col xs="auto">
                        <ImageButton
                            image={deleteIcon}
                            imageFocus={deleteIconFocus}
                            onClick={() => removeQuestion(item.id)}
                            active={true}
                            width={32}
                        />
                    </Col>
                </Row>
            </div>
        );
    };

    const header = (
        <div className="p-2">
            <Row className="align-items-center">
                <Col>
                    <span>Questions</span>
                </Col>
                <Col xs="auto" className="ml-auto">
                    <ImageButton
                        image={addIcon}
                        imageFocus={addIconFocus}
                        onClick={() => openQuestionDialog()}
                        active={true}
                        width={32}
                    />
                </Col>
            </Row>
        </div>
    )

    const openQuestionDialog = (question) => {
        if (questions.length < 50) {//QUESTIONS PER APTITUDE
            dispatch(setCurrentQuestion(question));
            dispatch(setAddQuestionModalVisibility(true));
        } else {
            showErrorToast("Vous ne pouvez pas ajouter plus de 50 questions.");
        }
    };


    const handleRemoveFromAptitude = async (id) => {
        try {
            const question = questionsFromStore.questions.find((q) => q.id == id);

            if (!question) {
                showErrorToast("Question introuvable");
                return;
            }

            const updatedQuestion = {
                ...question,
                id_aptitude: null,
            };

            await dispatch(addEditQuestion(updatedQuestion)).unwrap();

            const updatedQuestions = questionsFromStore.questions.filter((q) => q.id !== id);
            const updatedAptitude = {
                ...questionsFromStore,
                questions: updatedQuestions,
            };

            dispatch(addEditAptitude(updatedAptitude));
            dispatch(setSelectedAptitudeForQuestion(updatedAptitude));

            // Ajouter la question à questionsBank localement
            dispatch(setQuestionsBank([...questionsBank, updatedQuestion]));
        } catch (error) {
            showErrorToast("Échec de la suppression de la question de l'aptitude");
            console.error(error);
        }
    };

    const handleDeleteFromProject = async (id) => {
        try {
            await dispatch(deleteQuestion({ id })).unwrap();

            const updatedQuestions = questionsFromStore.questions.filter((q) => q.id !== id);
            const updatedAptitude = {
                ...questionsFromStore,
                questions: updatedQuestions,
            };

            dispatch(addEditAptitude(updatedAptitude));
            dispatch(setSelectedAptitudeForQuestion(updatedAptitude));
        } catch (error) {
            showErrorToast("Échec de la suppression de la question du projet");
            console.error(error);
        }
    };

    const removeQuestion = (id) => {
        setSelectedQuestionId(id);
        setDialogVisible(true);
    };




    function body() {
        return (
            <div className="container">
                <Row className="align-items-center">
                    {
                        props && !props.isEdit &&
                        <Row>
                            <Col lg={3} className="d-flex align-items-center">
                                <Langs str="Element Type" />
                            </Col>
                            <Col lg={9} className="my-2 d-flex justify-content-center">
                                <Dropdown
                                    value={selectedOption}
                                    onChange={(e) => setSelectedOption(e.value)}
                                    options={allowedOptions}
                                    optionLabel="name"
                                    placeholder="Select element type"
                                    className=""
                                    style={{ width: "75%" }}
                                />
                            </Col>
                        </Row>
                    }
                    <Col lg={3} className="d-flex align-items-center">
                        {selectedOption} nom
                    </Col>
                    <Col lg={9} className="my-2 d-flex justify-content-center">
                        <InputText
                            invalid={nomFlag}
                            value={nom}
                            onChange={(e) => setNom(e.target.value)}
                            style={{ width: "75%" }}
                            disabled={(elVisibility === 2 && userRoleId !== 1)}
                        />
                    </Col>
                    {selectedOption === 'Aptitude' &&
                        <Row>
                            <Col lg={3} className="d-flex align-items-center">
                                Description
                            </Col>
                            <Col lg={9} className="my-2 d-flex justify-content-center">
                                <InputText
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    style={{ width: "75%" }}
                                    disabled={(elVisibility === 2 && userRoleId !== 1)}
                                />
                            </Col>
                            {/* <Col lg={3} className="d-flex align-items-center">
                                Aptitude Type
                            </Col>
                            <Col lg={9} className="my-2 d-flex justify-content-center">
                                <Dropdown
                                    value={type}
                                    valueTemplate={selectedItemTemplate}
                                    onChange={(e) => setType(e.value)}
                                    options={typesOptions}
                                    optionLabel="label"
                                    placeholder="Select element type"
                                    className=""
                                    style={{ width: "75%" }}
                                />
                            </Col> */}
                        </Row>
                    }

                    {selectedOption === 'Aptitude' &&
                        <Row>
                            <Col lg={3} className="d-flex align-items-center">
                                <Langs str="Aspect" />
                            </Col>
                            <Col lg={9} className="my-2 d-flex justify-content-center">
                                <Dropdown value={selectedAspect}
                                    onChange={(e) => setSelectedAspect(e.value)}
                                    options={aspectOptions}
                                    optionLabel="label"
                                    placeholder="Selectionnez la nature de l'élément"
                                    className=""
                                    style={{ width: "75%" }}
                                    disabled={(elVisibility === 2 && userRoleId !== 1)}
                                />
                            </Col>
                        </Row>
                    }

                    <Col lg={3} className="d-flex align-items-center">
                        Coefficient
                    </Col>
                    <Col lg={9} className="my-2 d-flex justify-content-center">
                        <InputNumber
                            inputId="minmax-buttons"
                            value={coef}
                            onValueChange={(e) => setCoef(e.value)}
                            allowEmpty={false} mode="decimal"
                            showButtons
                            min={1}
                            max={100}
                            style={{ width: "75%" }}
                            incrementButtonClassName={`${styles.incrementBtn} p-button-warning`}
                            decrementButtonClassName={`${styles.decrementBtn} p-button-warning`}
                            disabled={(elVisibility === 2 && userRoleId !== 1)}
                        />
                    </Col>
                    {/* {
                        (isAdmin(userRoleId) && props && props.isEdit != 0) &&
                        <Row>
                            <Col lg={3} className="">
                                Visibility
                            </Col>
                            <Col lg={9} className="my-2 d-flex justify-content-center">
                                <Dropdown
                                    value={elVisibility}
                                    valueTemplate={selectedItemTemplate}
                                    onChange={(e) => setElVisibility(e.value)}
                                    options={visibilityOptions}
                                    optionLabel="label"
                                    placeholder="Select element type"
                                    className=""
                                    style={{ width: "75%" }}
                                />
                            </Col>
                        </Row>
                    } */}
                    {props && onCreateProject && onCreateProject.id && (
                        <Row>
                            <div className="my-2 d-flex justify-content-start align-items-center">
                                <label htmlFor="toggleQuestions" className="me-2 mb-0">Actif</label>
                                <InputSwitch
                                    id="toggleQuestions"
                                    checked={activeState}
                                    onChange={(e) => setActiveState(e.value)}
                                />
                            </div>
                        </Row>
                    )}
                    {selectedOption === 'Aptitude' && props && props.isEdit === 1  && onCreateProject && onCreateProject.id && (
                        <Row>
                            <div className="my-2 d-flex justify-content-start align-items-center">
                                <label htmlFor="toggleQuestions" className="me-2 mb-0">Activer les Questions</label>
                                <InputSwitch
                                    id="toggleQuestions"
                                    checked={activeMcq}
                                    onChange={(e) => setMcqVisibilityForAptitude(e)}
                                />
                            </div>
                            <div className="mt-2 card xl:flex xl:justify-content-center">
                                <OrderList
                                    dataKey="id"
                                    value={questions}
                                    onChange={(e) => setQuestions(e.value)}
                                    itemTemplate={itemTemplate}
                                    header={header}
                                    filter
                                    filterBy="question"
                                    dragdrop
                                    moveUpButtonProps={{ style: { display: 'none' } }}
                                    moveTopButtonProps={{ style: { display: 'none' } }}
                                    moveDownButtonProps={{ style: { display: 'none' } }}
                                    moveBottomButtonProps={{ style: { display: 'none' } }} />
                            </div>
                            <AddQuestionModal
                            />
                        </Row>
                    )}
                    <Dialog
                        header="Confirmation de suppression"
                        visible={dialogVisible}
                        style={{ width: '50vw', zIndex: 10000 }}
                        modal
                        onHide={() => setDialogVisible(false)}
                    >
                        <p>Voulez-vous supprimer cette question de l'aptitude ou du projet entier ?</p>
                        <Row className="align-items-center d-flex justify-content-between">
                            <Col xs={3}>
                                <PrimaryButton
                                    title="Supprimer de l'aptitude"
                                    onClick={() => {
                                        handleRemoveFromAptitude(selectedQuestionId);
                                        setDialogVisible(false);
                                    }}
                                    active={true}
                                />
                            </Col>
                            <Col xs={3} className="text-end">
                                <PrimaryButton
                                    title="Supprimer du projet"
                                    onClick={() => {
                                        handleDeleteFromProject(selectedQuestionId);
                                        setDialogVisible(false);
                                    }}
                                    active={true}
                                />
                            </Col>
                        </Row>
                    </Dialog>
                </Row>
            </div>
        )
    }

    function footer() {
        return (
            <Row className="d-flex justify-content-center">
                <Col lg={3} xs={3}>
                    <DynamicButton
                        onClick={() => handleElementSave()}
                        className='me-auto'
                        title={<Langs str="SAVE" />}
                        active={true}
                        color="green"
                    />
                </Col>
            </Row>
        )
    }



    return (
        <Dialog header={(props && props.isEdit) ? <Langs str="EDIT_ELEMENT" /> : <Langs str="ADD_ELEMENT" />} footer={footer} visible={visible} style={isMobile === 2 ? { width: '100vw' } : { width: '65vw' }} draggable={false} onHide={() => { handleClose() }}>
            {body()}
        </Dialog>
    )
}