import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import styles from './Forms.module.css';
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Modal from 'react-bootstrap/Modal';
import Langs from "../../../app/lang/langs";
import { ThemeButton } from '../../buttons/ThemeButton'
import { RadioButton } from '../../buttons/RadioButton';
import { TransparentDatePicker } from '../../buttons/TransparentDatePicker';
import ExcelManager from '../../../app/managers/excelManager';
import { formatDate, generateMapByKey, showErrorToast } from '../../../app/utils/helpers';
import moment from 'moment';
import { deletePlanning, getPlanning } from '../../../containers/planning/planningSlice';

export function DeleteFormModal(props) {

    const dispatch = useDispatch()

    const collabs = useSelector((state) => state.collabs.list)
    const selectedView = useSelector((state) => state.planning.selectedView)
    const selectedDate = useSelector((state) => state.planning.selectedDate)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)

    function hide() {
        props.onHide()
    }

    function handleDeletePlanning() {
        let range = "month"
        if (selectedView == "WEEKLY") {
            range = "week"
        }
        let from = moment(selectedDate).startOf(range).format("x")
        let to = moment(selectedDate).endOf(range).format("x")

        if(from && to){
            let params = {
                teamIds: selectedTeamsIds,
                from: from,
                to: to
            }
            dispatch(deletePlanning(params)).then((response) => {
                if (response['payload']['responseData']['status'] == 1) {
                    loadPlanning()
                }
                if (response['payload']['responseData']['status'] == 4) {
                    showErrorToast(response['payload']['responseData']['message'])
                }
                if (response['payload']['responseError']) {
                    showErrorToast()
                }
            })
        }
        hide()
        
    }

    function loadPlanning(){
        if (selectedTeamsIds && selectedTeamsIds.length && collabs.length) {
          let selectedCollabsIds = collabs.reduce((array, current) =>{
            array.push(current.id)
            return array
          }, [])
          dispatch(getPlanning({selectedTeamsIds, selectedCollabsIds}))
        }
    }

    function renderBodyView() {
        return <Modal.Body>
            <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
            <Langs str='DELETE_PLANNING_VALIDATION_MSG' />
            </Row>
        </Modal.Body>
    }

    function renderHeaderView() {
        return <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <Langs str='Delete planning' />
            </Modal.Title>
        </Modal.Header>
    }

    function renderFooterView() {
        return <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
                <PrimaryButton
                    title="CANCEL"
                    active={true}
                    onClick={() => hide()} />
            </Col>
            <Col md={3}>
                <ThemeButton
                    title="Delete"
                    active={true}
                    onClick={() => handleDeletePlanning()} />
            </Col>
        </Modal.Footer>
    }
    return <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >

        {renderHeaderView()}
        {renderBodyView()}
        {renderFooterView()}

    </Modal>
}