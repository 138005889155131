import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NetworkManager from '../../../app/managers/networkManager';
import { config } from '../../../app/utils/config';
import { generateUniqueId } from "../../../app/utils/helpers";

const initialState = {
  list: [],
  timesheetSample: null
}

export function generateSampleShift(fromHours, toHours) {
  return {
    "id": generateUniqueId(),
    "type": "p",
    "task": "",
    "color": "",
    "timeFrom": parseInt(fromHours) * 60,
    "timeTo": parseInt(toHours) * 60
  }
}

export const sampleShiftsSlice = createSlice({
  name: 'sampleShifts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteShiftSamples.fulfilled, (state, action) => {

        console.log('Sample shift successfully deleted')
      })
    builder
      .addCase(addShiftSample.fulfilled, (state, action) => {

        console.log('Sample shift successfully added')
      })
    builder
      .addCase(getShiftSamples.fulfilled, (state, action) => {

        if (action.payload.responseData && action.payload.responseData["sampleShifts"]) {
          let sampleShiftsList = []
          for (const key in action.payload.responseData["sampleShifts"]) {
            let shift = action.payload.responseData["sampleShifts"][key]
            let data = {
              value: shift["id"],
              label: shift["task"],
              id: shift['id'],
              task: shift["task"],
              color: shift['color'],
              timeFrom: shift['time_from'],
              timeTo: shift['time_to'],
              type: shift['type'],
              visibility: shift['visibility'],
              createdAt: new Date(shift['created_at']).getTime(),
              updatedAt: new Date(shift['updated_at']).getTime()
            }
            if(data.id === 0){
              state.timesheetSample = data
            }
            sampleShiftsList.push(data)
          }
          state.list = sampleShiftsList

        }
      })
    builder
      .addCase(updateShiftSample.fulfilled, (state, action) => {

        console.log('Sample shift successfully updated')
      })
  },
});

export const addShiftSample = createAsyncThunk(
  "planning/addShiftSample",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addShiftSample,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getShiftSamples = createAsyncThunk(
  "planning/getShiftSamples",
  async () => {
    try {
      let response = await NetworkManager.getInstance().getRequest(
        config.apiPaths.planning.getShiftSamples
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteShiftSamples = createAsyncThunk(
  "planning/deleteShiftSamples",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.deleteShiftSamples, body
      );
      return response
    } catch (error) {
      return error
    }
  }
)

export const updateShiftSample = createAsyncThunk(
  "planning/updateShiftSample",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.updateShiftSample, body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const {

} = sampleShiftsSlice.actions

export default sampleShiftsSlice.reducer