import { store } from "../store/store";
import axios from "axios";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import moment from "moment";
import { isWorkingShift } from "../utils/helpers";

export default class ExcelManager {

    static getInstance() {
        if (!this.instance) {
            this.instance = new ExcelManager()
        }
        return this.instance;
    }

    /**
     * Function to generate and download report
     */
    async generateAndDownloadComptaReport() {
        const workbook = new ExcelJS.Workbook()
        workbook.creator = 'Groupe Vital Dev'
        workbook.created = new Date()

        let rowHeaderProps = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cccccc' }
        }

        let borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }
        let planningSheet = workbook.addWorksheet('Planning')
        let row = planningSheet.addRow([
            "Nom société",
            "Matricule",
            "Salariés",
            "H100",
            "H125",
            "H200",
            "Majoration Heures Nuit 50",
            "Total (Heures)",
            "Rapport (%)",
            "Astreint (Jours)"
        ])
        row.eachCell(cell => {
            cell.border = borderStyle
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
            cell.fill = rowHeaderProps;
        })

        const state = store.getState()
        state.collabs.list.map(collab => {
            let collabHours = this.getCollabHours(collab.id)
            let overall = this.calculateTotalHoursForCollab(collabHours)
            let astreint = this.getTotalAstreintForCollab(collab.id)
            row = planningSheet.addRow([
                collab.company || "",
                collab.matricule || "",
                `${collab.prenom} ${collab.nom}`,
                collabHours.h100.toFixed(2),
                collabHours.h125.toFixed(2),
                collabHours.h200.toFixed(2),
                collabHours.hmn.toFixed(2),
                overall.total.toFixed(2),
                overall.ratio.toFixed(2),
                astreint.toFixed(2)
            ])
            row.eachCell(cell => {
                cell.border = borderStyle
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
            })
        })

        planningSheet = this.autoWidth(planningSheet)

        const buffer = await workbook.xlsx.writeBuffer()
        var blob = new Blob([buffer])
        let date = new moment(state.planning.selectedDate, "x")
        saveAs(blob, `Export_${date.format("MMMM_YYYY")}.xlsx`)
    }

    /**
     * Get collab planning hours
     * @param {*} collabId 
     * @returns 
     */
    getCollabHours(collabId) {
        const state = store.getState()
        let heureDebutNuit = 21, heureFinNuit = 7
        state.settings.settingsList.map(item => {
            if (item.label === "Heure début nuit") heureDebutNuit = item.settingValue
            if (item.label === "Heure fin nuit") heureFinNuit = item.settingValue
        })

        let hoursObj = {
            h100: 0,
            h125: 0,
            h200: 0,
            hmn: 0
        }

        state.planning.planningList
            .filter(s => (s.id_collab == collabId && isWorkingShift(s)))
            .map(shift => {
                let startDate = new Date(shift.from)
                let endDate = new Date(shift.to)
                let nightStart = new Date(startDate.getTime())
                nightStart.setHours(heureDebutNuit)
                nightStart.setMinutes(0)
                let nightEnd = new Date(startDate.getTime())
                nightEnd.setDate(nightEnd.getDate() + 1)
                nightEnd.setHours(heureFinNuit)
                nightEnd.setMinutes(0)
                let shiftDuration = ((shift.to - shift.from) / (60 * 60 * 1000))

                // Calculate extra & night hours
                let heuresNuit = 0, heuresSupp = 0, heuresNormal = 0
                if (endDate.getTime() > nightStart.getTime()) {
                    heuresNuit += ((endDate.getTime() - nightStart.getTime()) / (60 * 60 * 1000))
                    if (endDate.getTime() > nightEnd.getTime()) {
                        heuresNuit -= ((endDate.getTime() - nightEnd.getTime()) / (60 * 60 * 1000))
                    }
                }
                if (shiftDuration > 8) {
                    heuresNormal += 8
                    heuresSupp += shiftDuration - 8
                } else {
                    heuresNormal += shiftDuration
                }
                hoursObj.hmn += heuresNuit

                // Attribute normal hours to day of the week
                if (startDate.getDay() == 0) {
                    hoursObj.h200 += heuresNormal + heuresSupp
                } else if (startDate.getDay() == 6) {
                    hoursObj.h125 += heuresNormal + heuresSupp
                } else {
                    hoursObj.h100 += heuresNormal
                    hoursObj.h125 += heuresSupp
                }
            })
        return hoursObj
    }

    /**
     * Get collab planning hours
     * @param {*} collabId 
     * @returns 
     */
    getTotalAstreintForCollab(collabId) {
        const state = store.getState()
        let total = 0
        state.planning.planningList
            .filter(s => (s.id_collab == collabId && !s.absent && ['AST'].includes(s.type)))
            .map(shift => {
                let shiftDuration = ((shift.to - shift.from) / (60 * 60 * 1000))
                total += shiftDuration
            })
        console.log(total)
        return total / 24
    }

    /**
     * 
     * @param {*} hoursObj 
     * @returns 
     */
    calculateTotalHoursForCollab(hoursObj) {
        const state = store.getState()
        let monthHours = 151.67
        state.settings.settingsList.map(item => {
            if (item.label === "Base horaire mensuelle") monthHours = item.settingValue
        })
        let h100 = hoursObj.h100,
            h125 = hoursObj.h125,
            h200 = hoursObj.h200,
            hmn = hoursObj.hmn
        let total = h100, ratio = 0
        // Adjust the normal hours from extra hours
        while (total < monthHours && (h125 > 0 || h200 > 0 || hmn > 0)) {
            let missingNormalHours = monthHours - total
            if (hmn > 0) {
                let remainingRealHours = this.getRemainingRealHours(hmn / 2, missingNormalHours)
                total += (hmn / 2) - remainingRealHours
                hmn = remainingRealHours * 2
            } else if (h125 > 0) {
                let remainingRealHours = this.getRemainingRealHours(h125, missingNormalHours)
                total += h125 - remainingRealHours
                h125 = remainingRealHours
            } else if (h200 > 0) {
                let remainingRealHours = this.getRemainingRealHours(h200, missingNormalHours)
                total += h200 - remainingRealHours
                h200 = remainingRealHours
            }
        }
        //Now add the remaining extra hours
        if (hmn > 0) {
            total += hmn / 2
        }
        if (h125 > 0) {
            total += h125 * 1.25
        }
        if (h200 > 0) {
            total += h200 * 2
        }
        ratio = (total / monthHours) * 100
        return { total, ratio }
    }

    /**
     * 
     * @param {*} realHours 
     * @param {*} missingNormalHours 
     * @returns 
     */
    getRemainingRealHours(realHours, missingNormalHours) {
        if (realHours <= missingNormalHours) {
            return 0
        } else {
            return realHours - missingNormalHours
        }
    }

    /**
     * Function to generate and download data in excel format
     * @param {Array} data 
     * @param {String} name 
     */
    async generateAndDownloadExcelFromData(data, name) {
        const workbook = new ExcelJS.Workbook()
        workbook.creator = 'Groupe Vital Dev'
        workbook.created = new Date()

        let rowHeaderProps = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cccccc' }
        }

        let borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }
        let sheet = workbook.addWorksheet('Export')
        if (data.length) {
            let row = sheet.addRow(Object.keys(data[0]))
            row.eachCell(cell => {
                cell.border = borderStyle
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                cell.fill = rowHeaderProps;
            })

            data.map(item => {
                row = sheet.addRow(Object.values(item))
                row.eachCell(cell => {
                    cell.border = borderStyle
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                })
            })

            sheet = this.autoWidth(sheet)
        }

        const buffer = await workbook.xlsx.writeBuffer()
        var blob = new Blob([buffer])
        saveAs(blob, `Export_${name}.xlsx`)
    }

    /**
     * Autofit columns by width
     * @param {*} worksheet 
     * @param {*} minimalWidth 
     * @returns 
     */
    autoWidth(worksheet, minimalWidth = 10) {
        worksheet.columns.forEach((column) => {
            let maxColumnLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                maxColumnLength = Math.max(
                    maxColumnLength,
                    minimalWidth,
                    cell.value ? cell.value.toString().length : 0
                );
            });
            column.width = maxColumnLength + 2;
        })
        return worksheet
    }

}