import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Langs, { Localize } from "../../app/lang/langs";
import {
    getLastUpdatedFromDate,
    getStatusTagClass,
    isAdmin,
    isOwner,
    showErrorToast,
    getDayPeriodFromDate,
    formatDate,
    checkPermission
} from "../../app/utils/helpers";
import addIcon from "../../assets/images/add.png";
import addIconFocus from "../../assets/images/addFocus.png";
import checkIcon from "../../assets/images/check.png";
import checkIconFocus from "../../assets/images/checkSuccess.png";
import deleteIconFocus from "../../assets/images/delete-orange.png";
import deleteIcon from "../../assets/images/delete.png";
import exportIcon from "../../assets/images/export.png";
import exportIconFocus from "../../assets/images/exportFocus.png";
import rejectIcon from "../../assets/images/reject.png";
import rejectIconFocus from "../../assets/images/rejectDanger.png";
import ImageButton from "../../components/buttons/ImageButton";
import { LeaveFormModal } from "./LeaveFormModal";
import styles from "./Leaves.module.css";
import {
    getLeaves,
    hideLeaveModal,
    requestDeleteLeave,
    setLeaveStatus,
    showLeaveModal,
    submitLeave
} from "./leavesSlice";
import { ConfirmDeleteModal } from '../../components/popups/ConfirmDeleteModal';
import { TeamSelector } from '../../components/teams/TeamSelector';
import { ConfirmModal } from '../../components/popups/ConfirmModal';
import { getCollabs } from '../../components/collabs/collabsSlice';
import ExcelManager from '../../app/managers/excelManager';
import { config } from '../../app/utils/config';

export function LeavesView() {

    const dispatch = useDispatch()

    const userId = useSelector((state) => state.login.userId)
    const leaves = useSelector((state) => state.leaves.formattedList)
    const leaves2 = useSelector((state) => state.leaves.list)
    const sampleLeaves = useSelector((state) => state.sampleLeaves.list)
    const leaveModalType = useSelector((state) => state.leaves.leaveModalType)
    const isMobile = useSelector((state) => state.settings.isMobile)
    const collabs = useSelector((state) => state.collabs.currentList)

    const [globalFilterValue, setGlobalFilterValue] = useState('')
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        prenom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        company: { value: null, matchMode: FilterMatchMode.EQUALS }
    })
    const [selectedRows, setSelectedRows] = useState(null)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const myTeam = useSelector((state) => state.teams.myTeam)
    const userRoleId = useSelector((state) => state.login.userRoleId)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)
    const [selectedLeave, setSelectedLeave] = useState({})

    const toast = useRef(null)
    const dt = useRef(null)
    const mounted = useRef(null)

    useEffect(() => {
        if (!mounted.current && !leaves.length) {
            mounted.current = true
        }
    })
    useEffect(() => {
        if (selectedTeamsIds && selectedTeamsIds.length) {
            dispatch(getCollabs({ selectedTeamsIds, isCurrent: true }))
        }
    }, [dispatch, selectedTeamsIds])

    useEffect(() => {
        loadLeaves()
    }, [dispatch, collabs])

    function loadLeaves(){
        if (selectedTeamsIds && selectedTeamsIds.length && collabs.length) {
          let selectedCollabsIds = collabs.reduce((array, current) =>{
            array.push(current.id)
            return array
          }, [])
          dispatch(getLeaves({selectedTeamsIds, selectedCollabsIds}))
        }
    }

    const exportExcel = () => {

        let sampleLeavesMap = sampleLeaves.reduce((obj, current) => {
            obj[current.type] = current.description
            return obj
        }, {})

        let exportData = leaves2.reduce((array, current) => {
            let leaveData = {
                "Matricule": current.id_silae || "",
                "Nom": current.nom || "",
                "Prénom": current.prenom || "",
                "Email": current.email || "",
                "Type": current.type && sampleLeavesMap[current.type] ? sampleLeavesMap[current.type] : "",
                "Créé par": current.createdBy || "",
                "Durée": current.duration || "",
                "Début": current.from || "",
                "Statut": Localize(current.status, "fr"),
                "Fin": current.to || "",
                "Créé": getLastUpdatedFromDate(current.createdAt) || "",
                "Validé par": current.validatedBy || "",
                "Validé à": getLastUpdatedFromDate(current.validatedAt) || ""
            }
            array.push(leaveData)
            return array
        }, [])
        ExcelManager.getInstance().generateAndDownloadExcelFromData(exportData, "congés")
    }

    const openNew = () => {
        dispatch(showLeaveModal({ type: "ADD_LEAVE" }))
    }

    const rightToolbarTemplate = () => {
        let canExport = false
        selectedTeamsIds.map(id => {
            if (isManager(id)) {
                canExport = true
            }
        })
        return (
            <div className={isMobile == 2 ? 'mb-0 pb-0' : ''}>
                <ImageButton
                    image={addIcon}
                    imageFocus={addIconFocus}
                    onClick={openNew}
                    active={true}
                    tooltip="Request Leave"
                    width={32} />

                {canExport && <ImageButton
                    image={exportIcon}
                    imageFocus={exportIconFocus}
                    onClick={exportExcel}
                    active={true}
                    tooltip="Export"
                    width={32} />}
            </div>
        )
    }

    const leftToolbarTemplate = () => {
        if (isMobile == 1) {
            return (
                <label className="headerTitle">
                    <span className="headerTitle">
                        <Langs str="Leaves" />
                    </span>
                    <TeamSelector includeMyTeam />
                </label>
            )
        }
        if (isMobile == 2) {
            return (
                <Row style={isMobile == 2 ? { height: "40px", paddingTop: "10px" } : null}>
                    <Col xs={12} className='d-flex justify-content-start ms-2'>
                        <span className="headerTitle">
                            <Langs str="Leaves" />
                        </span>
                    </Col>
                    <Col xs={12} className='d-flex justify-content-start ' >
                        <TeamSelector includeMyTeam />
                    </Col>
                </Row>
            )
        }
    }

    const statusBodyTemplate = (rowData) => {
        return <Tag value={<Langs str={rowData.status} />}
            severity={getStatusTagClass(rowData.status)}>
        </Tag>
    }

    function updateLeave(id, status) {
        let data = {
            id: id,
            status: status
        }

        dispatch(submitLeave(data)).then((response) => {
            if (response['payload']['responseData']) {
                dispatch(setLeaveStatus(data))
                if (["DELETED", "REJECTED"].includes(status)) {
                    dispatch(getCollabs({ selectedTeamsIds, isCurrent: true }))
                    loadLeaves()
                }
            }
            if (response['payload']['responseError']) {
                if (response['payload']['responseError']['data'] && response['payload']['responseError']['data']['message']) {
                    showErrorToast(response['payload']['responseError']['data']['message'])
                } else showErrorToast()
            }
        })
    }

    function handleDelete(rowData) {
        setSelectedLeave(rowData)
        setShowDeletePopup(true)
    }

    function handleRequestDelete(rowData) {
        setSelectedLeave(rowData)
        setShowConfirmPopup(true)
    }

    const handleRequestDeleteSelectedLeave = async () => {
        setShowConfirmPopup(false)
        dispatch(requestDeleteLeave({ id: selectedLeave.id })).then((response) => {
            if (response['payload']['responseData']) {
                dispatch(getCollabs({ selectedTeamsIds, isCurrent: true }))
                loadLeaves()
            }
            if (response['payload']['responseError']) {
                showErrorToast()
            }
        })
    }

    const handleDeleteSelectedLeave = async () => {
        setShowDeletePopup(false)
        updateLeave(selectedLeave.id, "DELETED")
    }

    function isManager(teamId) {
        return checkPermission(config.scopes.leaves, config.permissionTypes.edit, teamId)
        return isAdmin(userRoleId) && (!myTeam || myTeam.userRoleId == 2 || teamId != myTeam.id)
    }

    const actionBodyTemplate = (rowData) => {
        if (isOwner(userRoleId) || isManager(rowData.id_team) || checkPermission(config.scopes.ownLeaves, config.permissionTypes.edit, myTeam.id)) {
            return <div className="d-flex justify-content-end align-items-center">
                {['PENDING'].includes(rowData.status) && <ImageButton
                    image={checkIcon}
                    imageFocus={checkIconFocus}
                    onClick={
                        () => updateLeave(rowData.id, "VALIDATED")
                    }
                    active={true}
                    tooltip={"VALIDATE"}
                    width={28}
                />}
                {['PENDING'].includes(rowData.status) && <ImageButton
                    image={rejectIcon}
                    imageFocus={rejectIconFocus}
                    onClick={
                        () => updateLeave(rowData.id, "REJECTED")
                    }
                    active={true}
                    tooltip={"REJECT"}
                    width={18}
                />}
                {!['DELETED'].includes(rowData.status) && <ImageButton
                    image={deleteIcon}
                    tooltip={"Delete"}
                    onClick={
                        () => handleDelete(rowData)
                    }
                    imageFocus={deleteIconFocus}
                    // active={rowData.status !== 'VALIDATED'}
                    active={true}
                    width={24} />}
            </div>
        }
        else {
            return <div className="d-flex justify-content-end align-items-center">
                {(rowData["id_collab"] == userId && !['DELETED', 'DELETE_REQUESTED'].includes(rowData.status)) && <ImageButton
                    image={deleteIcon}
                    tooltip={"REQUEST_DELETE"}
                    imageFocus={deleteIconFocus}
                    onClick={() => handleRequestDelete(rowData)}
                    // active={rowData.status !== 'VALIDATED'}
                    active={true}
                    width={24} />}

            </div>
        }
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value
        let _filters = { ...filters }

        _filters['global'].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const validatedDateBodyTemplate = (rowData) => {
        return <span>
            {getLastUpdatedFromDate(rowData.validatedAt)}
        </span>
    }

    const createdDateBodyTemplate = (rowData) => {
        return <span>
            {getLastUpdatedFromDate(rowData.createdAt)}
        </span>
    }
    const startDateBodyTemplate = (rowData) => {
        const formatedFrom = formatDate(new Date(rowData.from), "DD/MM/YYYY") + " " + getDayPeriodFromDate(new Date(rowData.from));
        return (
            <span>{formatedFrom}</span>
        )
    }
    const endDateBodyTemplate = (rowData) => {
        const formatedTo = formatDate(new Date(rowData.to), "DD/MM/YYYY") + " " + getDayPeriodFromDate(new Date(rowData.to));
        return (
            <span>{formatedTo}</span>
        )
    }

    const header = isMobile == 3 ? null : (
        <Row>
            <Col md={2}>
                <InputText type="search"
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Search..." />
            </Col>
            <Col md={9}>
            </Col>
            <Col md={1} className="d-flex justify-content-end">
                {/*
                <ImageButton
                    image={deleteIcon}
                    imageFocus={deleteIconFocus}
                    tooltip={"Delete"}
                    active={selectedRows && selectedRows.length}
                    width={32} /> */}
            </Col>
        </Row>
    )

    const statusItemTemplate = (option) => {
        if (option)
            return <Tag value={<Langs str={option} />} severity={getStatusTagClass(option)} />
        return <div><Langs str={'Select One'} /></div>
    }

    let statuses = [], companies = []

    leaves.map((leave) => {
        if (!statuses.includes(leave.status))
            statuses.push(leave.status)
        if (!companies.includes(leave.company))
            companies.push(leave.company)
    })

    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value}
                options={statuses}
                onChange={(e) => options.filterApplyCallback(e.value)}
                itemTemplate={statusItemTemplate}
                valueTemplate={statusItemTemplate}
                placeholder={<Langs str={"Select One"} />}
                className="p-column-filter"
                showClear
                style={{ minWidth: '12rem' }}
            />
        )
    }

    const companiesRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value}
                options={companies}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder={<Langs str={"Select One"} />}
                className="p-column-filter"
                showClear
                style={{ minWidth: '12rem' }}
            />
        )
    }

    const toolBar = () => {
        let canExport = false
        selectedTeamsIds.map(id => {
            if (isManager(id)) {
                canExport = true
            }
        })
        if (isMobile == 1) {
            return (
                <Row>
                    <Col lg={6} xs={12} className='headerTitle'>
                        <span className="headerTitle">
                            <Langs str="Leaves" />
                        </span>
                        <TeamSelector includeMyTeam />
                    </Col>
                    <Col lg={6} xs={12} className='d-flex justify-content-end pe-4'>
                        <ImageButton
                            image={addIcon}
                            imageFocus={addIconFocus}
                            onClick={openNew}
                            active={true}
                            tooltip="Request Leave"
                            width={32} />

                        {canExport && <ImageButton
                            image={exportIcon}
                            imageFocus={exportIconFocus}
                            onClick={exportExcel}
                            active={true}
                            tooltip="Export"
                            width={32} />}
                    </Col>
                </Row>
            )
        }
        if (isMobile == 2) {
            return (
                <Row>
                    <Col xs={12} className='headerTitle'>
                        <span className="headerTitle">
                            <Langs str="Leaves" />
                        </span>
                    </Col>
                    <Row className=' mt-2 mx-0 px-0'>
                        <Col xs={9} className='px-1'>
                            <TeamSelector includeMyTeam />
                        </Col>
                        <Col xs={3} className='d-flex justify-content-end pe-0 me-0'>
                            <ImageButton
                                image={addIcon}
                                imageFocus={addIconFocus}
                                onClick={openNew}
                                active={true}
                                tooltip="Request Leave"
                                width={32} />

                            {canExport && <ImageButton
                                image={exportIcon}
                                imageFocus={exportIconFocus}
                                onClick={exportExcel}
                                active={true}
                                tooltip="Export"
                                width={32} />}
                        </Col>
                    </Row>
                </Row>
            )
        }
        if (isMobile == 3) {
            return (
                <Row className="align-items-center">
                    <Col md={2} className="d-flex align-items-center">
                        <span className="headerTitle">
                            <Langs str="Leaves" />
                        </span>
                    </Col>
                    <Col md={6} className="d-flex align-items-center">
                        <TeamSelector includeMyTeam />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end align-items-center pe-4">
                        <ImageButton
                            image={addIcon}
                            imageFocus={addIconFocus}
                            onClick={openNew}
                            active={true}
                            tooltip="Request Leave"
                            width={32}
                        />
                        {canExport && (
                            <ImageButton
                                image={exportIcon}
                                imageFocus={exportIconFocus}
                                onClick={exportExcel}
                                active={true}
                                tooltip="Export"
                                width={32}
                            />
                        )}
                    </Col>
                </Row>
            )
        }
    }

    return (
        <div>
            <div className="topMenu">
                {toolBar()}
                {/* <Row className='mb-0 pb-0' style={isMobile == 2 ? {height: "50px", paddingTop: "30px"} : null}>
                    <Col md={6} xs={8}>
                        <span className="headerTitle">
                            {leftToolbarTemplate()}
                        </span>
                    </Col>
                    <Col md={6} xs={4} className="d-flex justify-content-end">
                        {rightToolbarTemplate()}
                    </Col>
                </Row> */}
            </div>
            <div className={styles.leavesView}>
                <Toast ref={toast} />
                <div className={styles.card}>
                    <DataTable ref={dt} value={leaves} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        scrollable
                        scrollHeight={isMobile == 3 ? "calc(100vh - 180px)" : "calc(100vh - 315px)"}
                        paginatorTemplate={isMobile == 1 ? "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :
                            "PrevPageLink NextPageLink CurrentPageReport"}
                        currentPageReportTemplate={isMobile == 1 ? "Showing {first} to {last} of {totalRecords} leaves" : "{first} to {last} out of {totalRecords}"}
                        selectionMode="single"
                        header={header}
                        filters={filters}
                        globalFilterFields={["nom", "prenom"]}>
                        {/* <Column selectionMode="multiple" exportable={false}></Column> */}
                        <Column field="id_silae" header={<Langs str="Matricule" />} sortable style={{ minWidth: '10rem' }}></Column>
                        <Column field="company" header={<Langs str="Company" />} sortable showFilterMatchModes={false} filter filterElement={companiesRowFilterTemplate}></Column>
                        <Column field="nom" header={<Langs str="Family Name" />} sortable filter filterPlaceholder="Search" style={{ minWidth: '10rem' }}></Column>
                        <Column field="prenom" header={<Langs str="Name" />} sortable filter filterPlaceholder="Search" style={{ minWidth: '10rem' }}></Column>
                        <Column field="type" header={<Langs str="Type" />} sortable style={{ minWidth: '7rem' }}></Column>
                        <Column field="status" header={<Langs str="Status" />} body={statusBodyTemplate} sortable
                            style={{ minWidth: '8rem' }} showFilterMatchModes={false} filter filterElement={statusRowFilterTemplate}></Column>
                        <Column field="from" body={startDateBodyTemplate} header={<Langs str="Start" />} sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="to" body={endDateBodyTemplate} header={<Langs str="End" />} sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="duration" header={<Langs str="Duration" />} sortable style={{ minWidth: '8rem' }}></Column>
                        <Column field="createdAt" body={createdDateBodyTemplate} header={<Langs str="CREATED_AT" />} sortable style={{ minWidth: '11rem' }}></Column>
                        <Column field="validatedBy" header={<Langs str="Validated by" />} sortable style={{ minWidth: '10rem' }}></Column>
                        <Column field="validatedAt" body={validatedDateBodyTemplate} header={<Langs str="Validated at" />} sortable style={{ minWidth: '11rem' }}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '6rem', textAlign: "right" }}></Column>
                    </DataTable>
                </div>

                <LeaveFormModal show={leaveModalType} onHide={() => dispatch(hideLeaveModal())} />

                <ConfirmDeleteModal
                    show={showDeletePopup}
                    title={<Langs str='DELETE_LEAVE' />}
                    message={<Langs str='DELETE_LEAVE_MSG' />}
                    onDelete={handleDeleteSelectedLeave}
                    onHide={() => setShowDeletePopup(false)} />

                <ConfirmModal show={showConfirmPopup}
                    title={<Langs str='DELETE_LEAVE' />}
                    message={<Langs str={selectedLeave.status == 'PENDING' ? 'DELETE_LEAVE_MSG' : 'REQUEST_DELETE_LEAVE_MSG'} />}
                    onConfirm={handleRequestDeleteSelectedLeave}
                    onHide={() => setShowConfirmPopup(false)} />

            </div>
        </div>
    )
}