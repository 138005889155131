import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import notFoundImg from '../../assets/images/PageNotFound.png'
import { MenuBar } from "./MenuBar";
import Langs from '../../app/lang/langs';

export class NotFoundPage extends React.Component {
    render() {
        return <div>
            <MenuBar />
            <Row className="d-flex justify-content-center align-content-center text-center vh-100 m-0 p-0">
                <Col>
                    <img src={notFoundImg} width={90} height={90} />
                    <h4 style={{ color: '#9D9D9D', padding: '20px' }}>
                        <Langs str='PAGE_NOT_FOUND'/>
                    </h4>
                </Col>
            </Row>
        </div>;
    }
}