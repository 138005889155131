export const msalConfig = {
    auth: {
        clientId: "c6701e56-6f31-4a76-af87-92dd0c8848a0",
        authority: "https://login.microsoftonline.com/groupe-vital.com",
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const loginRequest = {
    scopes: ["api://c6701e56-6f31-4a76-af87-92dd0c8848a0/access_as_user"]
};