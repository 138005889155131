import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";

const initialState =
{
  list: [],
  selectedView: 0
}

export const groupesSlice = createSlice({
  name: "groupes",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedView: (state, action) => {
      state.selectedView = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupes.fulfilled, (state, action) => {
        if (action.payload.responseData && action.payload.responseData["groupes"]) {
          let groupesList = []
          for (const key in action.payload.responseData["groupes"]) {
            let groupe = action.payload.responseData["groupes"][key]
            groupesList.push({
              value: groupe["id"],
              label: groupe["name"],
              id: groupe["id"],
              color: groupe["color"],
              linked_groups: groupe["linked_groups"],
              id_team: groupe["id_team"]
            })
          }
          state.list = groupesList
        }
      })
  },
})

export const addGroupe = createAsyncThunk(
  "planning/addGroupe",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addGroupe,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const getGroupes = createAsyncThunk(
  "planning/getGroupes",
  async (teamsIds) => {
    try {
      let response = await NetworkManager.getInstance().getRequest(
        config.apiPaths.planning.getGroupes + `?teamsIds=${teamsIds}`
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const updateGroupe = createAsyncThunk(
  "planning/updateGroupe",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.updateGroupe, body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)


export const deleteGroupes = createAsyncThunk(
  "planning/deleteGroupes",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.deleteGroupes, body
      );
      return response;
    } catch (error) {
      return error
    }
  }
)


export const { setSelectedView } = groupesSlice.actions

export default groupesSlice.reducer
