import React, { useState, useEffect } from 'react';
import { setResetPasswordModal, resetPasswordCollab, setResetPasswordResponse } from "../../components/collabs/collabsSlice";
import { useDispatch, useSelector } from "react-redux";
import Langs from '../../app/lang/langs';
import { checkPermission } from '../../app/utils/helpers';
import { Dialog } from 'primereact/dialog';
import { DangerButton } from '../../components/buttons/DangerButton';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { Localize } from '../../app/lang/langs';
import { config } from '../../app/utils/config';
import { showSuccessToast, showErrorToast, showInfoToast } from '../../app/utils/helpers';


export default function ResetPasswordDialog() {

  const dispatch = useDispatch()
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const visible = useSelector((state) => state.collabs.resetPasswordModal)
  const resetPasswordResponse = useSelector((state) => state.collabs.resetPasswordResponse)
  const collabModalData = useSelector(state => state.collabs.collabModal);
  const [{ show, ...initialRest }] = [collabModalData];
  const [collabData, setCollabData] = useState(initialRest);
  const settings = useSelector((state) => state.settings)

  const collabFullName = `${collabData.prenom} ${collabData.nom}`;
  const [inputValue, setInputValue] = useState("");
  
  useEffect(() => {
    const { show, ...rest } = collabModalData;
    setCollabData(rest);
  }, [visible])
  function toastRes(){
    if(resetPasswordResponse!=null){
      if(resetPasswordResponse.trim().toLowerCase().includes("success")){
        showSuccessToast(resetPasswordResponse)
      }else{
        showErrorToast(resetPasswordResponse)
      }
      dispatch(setResetPasswordResponse())
    }
  }
  useEffect(()=>{
    toastRes()
  },[resetPasswordResponse])

  const handleChange = (e) => {
    setInputValue(e.target.value);
  }

  const resetCollabPasswordFunction = (collabId) => {
    if (checkPermission(config.scopes.collabs, config.permissionTypes.edit)) {
      if (inputValue == Localize("RESET_CAPS", settings.lang)) {
        dispatch(resetPasswordCollab(collabId));
        dispatch(setResetPasswordModal(false));
        setInputValue("")
      }
    }
  }

  function handleResetPassClose() {
    dispatch(setResetPasswordModal(false))
    setInputValue("")
  }

  const headerElement = (
    <div>
      <p style={{ color: 'grey' }}>{<Langs str="RESET_PASSWORD_FOR" />}</p>
      <i>{collabFullName}</i>
    </div>
  )
  
  const footerContent = (
    <div className='row'>
      <div className='col-12 row d-flex justify-content-between'>
        <div className='col-3'>
          <PrimaryButton 
              onClick={() => {handleResetPassClose()}}
              title={<Langs str="CLOSE" />}
              active={true}
            />
        </div>
        <div className='col-3'>
          <DangerButton
              onClick={() => resetCollabPasswordFunction(collabData.id)}
              title={<Langs str="reset_password" />}
              active={true}
            />
        </div>
      </div>
    </div>
  )

  return (
    <div className="card flex justify-content-center">
      <Dialog
        visible={visible}
        draggable={false}
        position='center'
        modal
        resizable={false}
        header={headerElement}
        footer={footerContent}
        style={{ width: '50rem' }}
        onHide={() => handleResetPassClose()}
      >
        <div className='row'>
          <h5>{<Langs str="reset_command" />}</h5>
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            onPaste={(event) => event.preventDefault()}
            className="form-control"
            placeholder='Tapez ici...'
          />
        </div>
      </Dialog>
    </div>
  )
}