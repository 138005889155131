import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MenuBar } from "../common/MenuBar";
import { Body } from "../common/Body";
import { useDispatch, useSelector } from "react-redux";
import { showPopup } from "../common/commonSlice";
import { PopupModalCentered } from "../../components/popups/PopupModalCentered";
import { getHolidays, getSettings } from "../settings/settingsSlice";
import { getTeams, setSelectedTeams } from "../../components/teams/teamsSlice";
import { getShiftSamples } from "../../components/scheduler/shifts/sampleShiftsSlice";
import { getLeaveSamples } from "../../components/scheduler/leave/leaveSampleSlice";
import { getUserSession } from "../login/loginSlice";
import { Footer } from "../common/Footer";

export function HomePage() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const urlParams = useParams()

  const settings = useSelector((state) => state.settings)
  const selectedDate = useSelector((state) => state.planning.selectedDate)
  const teams = useSelector((state) => state.teams.list)
  const myTeam = useSelector((state) => state.teams.myTeam)
  const popup = useSelector((state) => state.common.popup)
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  

  const mounted = useRef(false)

  useEffect(() => {
    let date = new Date(selectedDate)
    if (!settings.holidays[settings.selectedYear]) {
      dispatch(getHolidays(settings.selectedYear))
    }
    if (!settings.holidays[date.getFullYear()]) {
      dispatch(getHolidays(date.getFullYear()))
    }
    if (!settings.settingsList.length) {
      dispatch(getSettings())
    }
  }, [selectedDate])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      dispatch(getUserSession())
      dispatch(getShiftSamples())
      dispatch(getLeaveSamples())
      dispatch(getTeams())
      dispatch(getSettings())
    }
  })

  useEffect(() => {
    let selectedIds, useUrlParams
    if (myTeam && (myTeam.userRoleId == 3 || !urlParams || (urlParams.view && urlParams.view.toLowerCase() != "groupes"))) {
      selectedIds = [myTeam.id]
      if (urlParams.selectedTeamId == myTeam.id) {
        useUrlParams = true
      }
    }
    else if (teams && teams.length) {
      selectedIds = [teams[0].id]
      let availableTeams = teams.filter(team => {
        return team.id == urlParams.selectedTeamId
      })
      if (availableTeams.length) {
        useUrlParams = true
      }
    }
    if (selectedIds) {
      dispatch(setSelectedTeams(useUrlParams ? [parseInt(urlParams.selectedTeamId)] : selectedIds))
    }
  }, [teams, myTeam])

  useEffect(() => {
    if (selectedTeamsIds && selectedTeamsIds.length) {
      loadData()
    }
  }, [selectedTeamsIds])

  function loadData() {
    if (!selectedTeamsIds.length) return
    let view = urlParams.view || 'planning'
    let subView = urlParams.subView || 0
    let selectedTeamId = selectedTeamsIds[0]
    navigate(`/${view}/${selectedTeamId}/${subView}`)
  }

  function setModalShow(show, type) {
    dispatch(showPopup({
      show: show,
      type: type
    }))
  }

  return (
    <div>
      <Body view={urlParams.view} />
      <MenuBar />
      <PopupModalCentered
        show={popup.show && popup.type === "CENTERED"}
        onHide={() => setModalShow(false)}
      />
    </div>
  )
}
